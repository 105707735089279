import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { fetchContactImage } from '../utilities/OtherUtilities.js';

// import components
import MyChurchesTileMobile from '../tiles_m/MyChurchesTileMobile.js';

class ChurchesListMobile extends React.Component {
    setActualSection = this.props.setActualSection;

    constructor(props) {
        super(props);

        this.state = {
            churchesData: ([]),
            totalNumberOfContacts: 0,
            noRecordsFound: false,
            numOfLoadedContacts: 20,
            showLoadingRow: false,
            sortBy: "", // name
            sortTp: "asc", // asc, desc
            dataAreLoadingNow: false,
        }
    }

    componentDidMount(){
        this.loadData();
        this.setActualSection("churches");
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.allOrMy !== this.props.allOrMy) {
            this.loadData();
        }
    }

    loadMoreData = async () => {
        // this condition should prevent to run this method more times in the same moment
        // second part of this condition should prevent to run this method when all records are already loaded
        if(!this.state.dataAreLoadingNow && (this.state.totalNumberOfContacts > this.state.numOfLoadedContacts)){
            await this.setState({ dataAreLoadingNow: true });
            await this.setState({ numOfLoadedContacts: this.state.numOfLoadedContacts+20 });
            this.loadData();
        }
    }

    loadData = () => {
        this.setState({ noRecordsFound: false });

        var getOnlyMyContacts = this.props.allOrMy == "my" ? 1 : 0;
        var url = '/api/cont/get?tp=4&my='+getOnlyMyContacts+'&limit='+this.state.numOfLoadedContacts+'&offset=0';
        if(this.state.sortBy != ""){
            url = url + "&sort_by="+this.state.sortBy+"&sort_tp="+this.state.sortTp;
        }
        Axios.get(url).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.setState({ churchesData: response.data.result });
            this.setState({ totalNumberOfContacts: response.data.msg.cnt });
            if(response.data.result.length == 0){ this.setState({ noRecordsFound: true }); }

            this.state.churchesData.forEach((contact) => this.handleGetContactImage(contact));

            this.setState({ dataAreLoadingNow: false });
            if(response.data.msg.cnt > this.state.numOfLoadedContacts) this.setState({ showLoadingRow: true });
            else this.setState({ showLoadingRow: false });
        });
    }

    handleGetContactImage = async (contact) => {
        const base64Image = await fetchContactImage(contact,"avatar");
        this.setState((prevState) => ({ churchesData: prevState.churchesData.map((u) => (u.id === contact.id ? { ...u, image: base64Image } : u)), }));
    };

    changeSorting = async (attribute) => {
        if(this.state.sortBy == attribute && this.state.sortTp == "asc") {
            await this.setState({ sortTp: "desc" });
        }
        else if(this.state.sortBy == attribute && this.state.sortTp == "desc") {
            await this.setState({ sortBy: "" });
            await this.setState({ sortTp: "desc" });
        }
        else {
            await this.setState({ sortBy: attribute });
            await this.setState({ sortTp: "asc" });
        }
        this.loadData();
    }

    render() {
        return (
            <div className="verticalStack flex" style={{height: "calc(var(--doc-height) - 80px)"}}>
                <div className="horizontalStackCenter myPeopleTableListingBar">
                    <span className="fontPoppinsSemiBold11 clickable" style={{color: this.props.allOrMy == "my" ? "black" : "var(--jv-orange)"}} onClick={() => this.props.setAllOrMy("all")}>{"ALL CHURCHES"}</span>
                    <span className="fontPoppinsSemiBold11" style={{marginLeft: 5, marginRight: 5}}>{"/"}</span>
                    <span className="fontPoppinsSemiBold11 clickable" style={{color: this.props.allOrMy == "my" ? "var(--jv-orange)" : "black"}} onClick={() => this.props.setAllOrMy("my")}>{"MY CHURCHES"}</span>
                    <div className="flex"/>
                </div>
                <MyChurchesTileMobile churchesArray={this.state.churchesData} leftMenuOpened={this.props.leftMenuOpened} openAlertMessage={this.props.openAlertMessage} loadMoreData={this.loadMoreData} showLoadingRow={this.state.showLoadingRow} changeSorting={this.changeSorting} sortBy={this.state.sortBy} sortTp={this.state.sortTp} loadData={this.loadData} allOrMy={this.props.allOrMy} noRecordsFound={this.state.noRecordsFound} />
            </div>
        );
    }
}

export default ChurchesListMobile;
