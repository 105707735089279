import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import { Link, Navigate } from "react-router-dom";
import Axios from 'axios';
import Moment from 'react-moment';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { showSortIcon } from '../utilities/OtherUtilities.js';

// import assets
import people from '../assets/icon_people_gray.png';

// import components
import ProfileImageSmall from '../components/ProfileImageSmall.js';

class MyPeopleTileMobile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
        }
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
    }

    openPeopleProfilePage = (event, id, isPrivate, openTile) => {
        var openTileText = "";
        if(openTile != ""){
            openTileText = "?tile="+openTile;
        }
        if(isPrivate == 1){
            if(event.ctrlKey || event.metaKey){ window.open("/PeoplePrivateProfile/"+id+""+openTileText, '_blank', 'noreferrer'); }
            else { this.setState({ redirectTo: "/PeoplePrivateProfile/"+id+""+openTileText }); }
        }
        else {
            if(event.ctrlKey || event.metaKey){ window.open("/PeopleProfile/"+id+""+openTileText, '_blank', 'noreferrer'); }
            else { this.setState({ redirectTo: "/PeopleProfile/"+id+""+openTileText }); }
        }
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10;
        if(bottom){
            this.props.loadMoreData();
        }
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

        return (
            <div style={{height: "calc(100% - 50px)", width: "100%"}}>
                <div className="myPeopleTileMobile">
                    <div onScroll={this.handleScroll} style={{width: this.props.leftMenuOpened == "true" ? "calc(100% - 240px)" : "calc(100% - 60px)", height: "calc(100% - 130px)", position: "absolute", overflowX: "auto", overflowY: "auto"}}> {/* 240px including the padding */}
                        <table className="myPeopleTableMobile">
                            <tr>
                                <td className="myPeopleTableHeader myPeopleTableActionHeaderMobile">
                                    <div className="horizontalStackCenter centerAll">
                                        <img style={{width: 21}} src={people}/>
                                    </div>
                                </td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderRole" onClick={() => this.props.changeSorting("nm")}>Name {showSortIcon("nm",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("ins_at")}>Date created {showSortIcon("ins_at",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("role")}>Role {showSortIcon("role",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("church")}>Church {showSortIcon("church",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("em")}>Email {showSortIcon("em",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("addr")}>Address {showSortIcon("addr",this.props.sortBy,this.props.sortTp)}</td>
                                <td className="myPeopleTableHeader myPeopleTableHeaderOther" onClick={() => this.props.changeSorting("ph")}>Phone {showSortIcon("ph",this.props.sortBy,this.props.sortTp)}</td>
                            </tr>

                            {this.props.peopleArray.map(function (item, index) {
                                return (
                                    <tr key={index} className="myPeopleTableRow">

                                        <td className="myPeopleTableFirstFixedColumnMobile">
                                            <div className="horizontalStackCenter">
                                                <div className="flex"></div>
                                                <ProfileImageSmall
                                                    name={item.first_name+" "+item.last_name}
                                                    uploadedImageBase64={item.image ? item.image : ""}
                                                />
                                                <div className="flex"></div>
                                            </div>
                                        </td>

                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openPeopleProfilePage(event, item.id, item.is_private, "")}>{item.first_name} {item.last_name}</td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openPeopleProfilePage(event, item.id, item.is_private, "")}><Moment format="MMMM D, YYYY">{item.ins_at}</Moment></td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openPeopleProfilePage(event, item.id, item.is_private, "")}>{item.prim_role}</td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openPeopleProfilePage(event, item.id, item.is_private, "")}>{item.church}</td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openPeopleProfilePage(event, item.id, item.is_private, "")}>{item.email}</td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openPeopleProfilePage(event, item.id, item.is_private, "")}>{item.city}{item.city == null || item.addr_country == null ? "" : ", "}{item.addr_country}</td>
                                        <td className="myPeopleTableGeneralDiv" nowrap="nowrap" onClick={(event) => this.openPeopleProfilePage(event, item.id, item.is_private, "")}>{item.phone}</td>
                                    </tr>
                                )
                            }.bind(this))}

                            { this.props.showLoadingRow ? <tr>
                                <td className="myPeopleTableGeneralDiv" colspan={7}>
                                    <span>{"Loading more records..."}</span>
                                </td>
                            </tr> : null }

                            {/* if array empty then show row with text "Loading..." */}
                            {this.props.peopleArray.length == 0 && !this.props.noRecordsFound ? <tr className="myPeopleTableRow"> <td className="myPeopleTableGeneralDiv" nowrap="nowrap" colspan="8">{"Loading..."}</td> </tr> : null}

                            {/* if array empty then show row with text "No Records Found" */}
                            {this.props.peopleArray.length == 0 && this.props.noRecordsFound ? <tr className="myPeopleTableRow"> <td className="myPeopleTableGeneralDiv" nowrap="nowrap" colspan="8">{"No Records"}</td> </tr> : null}

                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default MyPeopleTileMobile;
