import React from 'react';
import { Link } from "react-router-dom";
import '../styles/modals.css';
//import Slider from '@material-ui/core/Slider';
import Axios from 'axios';
import Moment from 'react-moment';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// components
import Modal from '../modals/Modal';
import RoundButton from '../components/RoundButton.js';
import EditDeleteButton from '../components/EditDeleteButton.js';
import Slider from '../components/Slider.js';
import DiagnosticModalConfirmation from '../components/DiagnosticModalConfirmation.js';

// assets
import close_white from '../assets/close_white.png';
import gro_tool from '../assets/gro_tool.png';
import icon_action_down from '../assets/icon_action_down.png';
import icon_action_up from '../assets/icon_action_up.png';

const marks = [
  { value: 0, label: 'Low', },
  { value: 25, label: 'Medium Low', },
  { value: 50, label: 'Medium', },
  { value: 75, label: 'Medium High', },
  { value: 100, label: 'High', },
];

const marksObedience = [{ value: 0, label: 'Low', },{ value: 50, label: 'Obedience', },{ value: 100, label: 'High', }];
const marksLove = [{ value: 0, label: 'Low', },{ value: 50, label: 'Love', },{ value: 100, label: 'High', }];
const marksWorship = [{ value: 0, label: 'Low', },{ value: 50, label: 'Worship', },{ value: 100, label: 'High', }];
const marksWitness = [{ value: 0, label: 'Low', },{ value: 50, label: 'Witness', },{ value: 100, label: 'High', }];
const marksPrayer = [{ value: 0, label: 'Low', },{ value: 50, label: 'Prayer', },{ value: 100, label: 'High', }];
const marksWordOfGod = [{ value: 0, label: 'Low', },{ value: 50, label: 'Word of God', },{ value: 100, label: 'High', }];
const marksSpiritOfGod = [{ value: 0, label: 'Low', },{ value: 50, label: 'Spirit of God', },{ value: 100, label: 'High', }];
const marksFamilyOfGod = [{ value: 0, label: 'Low', },{ value: 50, label: 'Family of God', },{ value: 100, label: 'High', }];

class GroToolModal extends React.Component {

    constructor(props) {
        super(props);
        this.refObedience = React.createRef();
        this.refLove = React.createRef();
        this.refWorship = React.createRef();
        this.refWitness = React.createRef();
        this.refPrayer = React.createRef();
        this.refWordOfGod = React.createRef();
        this.refSpiritOfGod = React.createRef();
        this.refFamilyOfGod = React.createRef();

        this.state = {
            modalExpanded: false,
            mainContactId: -1,
            mainContactName: "Loading...",
            editingModeOfRecord: -1,
            sliderValue: 50, // 0, 25, 50, 75, 100
            levelObedience: 50,
            levelLove: 50,
            levelWorship: 50,
            levelWitness: 50,
            levelPrayer: 50,
            levelWordOfGod: 50,
            levelSpiritOfGod: 50,
            levelFamilyOfGod: 50,
            lastRecordDate: "",
            note_descr: "",
            arrayOfRecords: ([]),
            showSaveRecordConfirmation: false,
            noRecordsFound: false,
        };

        this.refModalBottom = React.createRef();
    }

    componentDidMount(){
        this.loadData();
    }

    loadData = async () => {
        this.setState({ noRecordsFound: false });

        const pathname = window.location.href;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }

        if(Number.isInteger(Number(id))){ // if there is number received from URL
            var lastRecordId = 0;
            await Axios.get('/api/lens/get?cont_id='+id+'&segm_id=2').then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

                if(response.data.result.length > 0){
                    response.data.result = response.data.result.reverse();
                    lastRecordId = response.data.result[0].id;
                    this.setState({ lastRecordDate: response.data.result[0].ins_at });
                    this.setState({ arrayOfRecords: response.data.result });
                }
                else {
                    this.setState({ sliderValue: 50 });
                    this.setState({ lastRecordDate: "" });
                    this.setState({ arrayOfRecords: [] });
                    this.setState({ noRecordsFound: true });
                }

            });

            if(lastRecordId != 0){
                await Axios.get('/api/lens/get/'+lastRecordId).then(response => {
                    if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                    response.data.result = response.data.result.reverse();
                    //var savedValue = response.data.result[0].seg_id;

                    var mainSliderValue = 0;
                    if(response.data.result[0].seg_id == 6){ mainSliderValue = 0; }
                    if(response.data.result[0].seg_id == 7){ mainSliderValue = 25; }
                    if(response.data.result[0].seg_id == 8){ mainSliderValue = 50; }
                    if(response.data.result[0].seg_id == 9){ mainSliderValue = 75; }
                    if(response.data.result[0].seg_id == 10){ mainSliderValue = 100; }
                    this.setState({ sliderValue: mainSliderValue });

                    var arrayOfLevels = response.data.resultDet;
                    for(let oneRow of arrayOfLevels){
                        if(oneRow.segm_id == 9){ this.setState({ levelObedience: oneRow.seg_val }); }
                        if(oneRow.segm_id == 10){ this.setState({ levelLove: oneRow.seg_val }); }
                        if(oneRow.segm_id == 11){ this.setState({ levelWorship: oneRow.seg_val }); }
                        if(oneRow.segm_id == 12){ this.setState({ levelWitness: oneRow.seg_val }); }
                        if(oneRow.segm_id == 13){ this.setState({ levelPrayer: oneRow.seg_val }); }
                        if(oneRow.segm_id == 14){ this.setState({ levelWordOfGod: oneRow.seg_val }); }
                        if(oneRow.segm_id == 15){ this.setState({ levelSpiritOfGod: oneRow.seg_val }); }
                        if(oneRow.segm_id == 16){ this.setState({ levelFamilyOfGod: oneRow.seg_val }); }
                    }

                    //this.setState({ note_descr: response.data.result[0].note_descr });

                    //alert(arrayOfLevels.length);
                    //this.setState({ lastRecordDate: response.data.resultDet[0].ins_at });
                    //var countedValue = (savedValue-1)*25; //ex.: from 3 do 50 (0-100)
                    //this.setState({ sliderValue: countedValue });
                });
            }
            this.setState({ mainContactId: id });
            Axios.get('/api/cont/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                var user_name = response.data.result[0].first_name + " " + response.data.result[0].last_name;
                this.setState({ mainContactName: user_name });
            });
        }
    }

    saveNewRecord = async () => {
        // update main slider value
        await this.countMainSlider();
        //alert("ContId: "+this.state.mainContactId+"\nSegmId: 1\nSegId: "+((this.state.sliderValue/25)+1)+"\nDescr: "+this.state.note_descr);
        //alert(this.state.editingModeOfRecord);
        //return;

        var url = "/api/lens/ins";

        if(this.state.editingModeOfRecord != -1){
            url = "/api/lens/upd/"+this.state.editingModeOfRecord;
        }

        var finalSliderValue = 0;
        if(this.state.sliderValue == 0){ finalSliderValue = 6; }
        if(this.state.sliderValue == 25){ finalSliderValue = 7; }
        if(this.state.sliderValue == 50){ finalSliderValue = 8; }
        if(this.state.sliderValue == 75){ finalSliderValue = 9; }
        if(this.state.sliderValue == 100){ finalSliderValue = 10; }

        Axios.post(url, {
            ContId: this.state.mainContactId, // id of person to whom I am setting the record
            SegmId: 2, // that means I add it to GRO lens
            SegId: finalSliderValue,
            //SegId: (this.state.sliderValue/25)+1, // this is the level I am saving
            //SegVal: 30,
            //SegDet: [{SegmId: 9, SegId: null, SegVal: 30}],
            SegDet: [[9, null, this.state.levelObedience],[10, null, this.state.levelLove],[11, null, this.state.levelWorship],[12, null, this.state.levelWitness],[13, null, this.state.levelPrayer],[14, null, this.state.levelWordOfGod],[15, null, this.state.levelSpiritOfGod],[16, null, this.state.levelFamilyOfGod]],
            NoteDescr: this.state.note_descr,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

            if(this.state.editingModeOfRecord == -1){
                //this.props.openAlertMessageTwoActions("The record was saved.","OK","",null);
                this.setState({ showSaveRecordConfirmation: true });

                setTimeout(() => {
                    this.props.closeModal("GroTool");
                }, 2000);
            }
            else {
                this.setState({ editingModeOfRecord: -1 });
                this.setState({ note_descr: "" });
                this.loadData();
            }
        });
    }

    onDeleteRecord = (id) => {
        Axios.delete('/api/lens/del/'+id, {
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.loadData();
        });
    }

    onEditRecord = (id) => {
        if(this.state.editingModeOfRecord == id){
            this.setState({ editingModeOfRecord: -1 });
            this.setState({ note_descr: "" });
            this.loadData();
        }
        else {

            this.setState({ editingModeOfRecord: id });

            Axios.get('/api/lens/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                response.data.result = response.data.result.reverse();
                //var savedValue = response.data.result[0].seg_id;

                var mainSliderValue = 0;
                if(response.data.result[0].seg_id == 6){ mainSliderValue = 0; }
                if(response.data.result[0].seg_id == 7){ mainSliderValue = 25; }
                if(response.data.result[0].seg_id == 8){ mainSliderValue = 50; }
                if(response.data.result[0].seg_id == 9){ mainSliderValue = 75; }
                if(response.data.result[0].seg_id == 10){ mainSliderValue = 100; }
                this.setState({ sliderValue: mainSliderValue });

                var arrayOfLevels = response.data.resultDet;
                for(let oneRow of arrayOfLevels){
                    if(oneRow.segm_id == 9){ this.setState({ levelObedience: oneRow.seg_val }); }
                    if(oneRow.segm_id == 10){ this.setState({ levelLove: oneRow.seg_val }); }
                    if(oneRow.segm_id == 11){ this.setState({ levelWorship: oneRow.seg_val }); }
                    if(oneRow.segm_id == 12){ this.setState({ levelWitness: oneRow.seg_val }); }
                    if(oneRow.segm_id == 13){ this.setState({ levelPrayer: oneRow.seg_val }); }
                    if(oneRow.segm_id == 14){ this.setState({ levelWordOfGod: oneRow.seg_val }); }
                    if(oneRow.segm_id == 15){ this.setState({ levelSpiritOfGod: oneRow.seg_val }); }
                    if(oneRow.segm_id == 16){ this.setState({ levelFamilyOfGod: oneRow.seg_val }); }
                }

                this.setState({ note_descr: response.data.result[0].note_descr });

                //alert(arrayOfLevels.length);
                //this.setState({ lastRecordDate: response.data.resultDet[0].ins_at });
                //var countedValue = (savedValue-1)*25; //ex.: from 3 do 50 (0-100)
                //this.setState({ sliderValue: countedValue });
            });

        }
    }

    toggleModalExpansion = async () => {
        if(this.state.modalExpanded){
            this.setState({ modalExpanded: false });
        }
        else {
            this.setState({ modalExpanded: true });
            await new Promise(resolve => setTimeout(resolve, 100));
            this.refModalBottom.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }

        this.countMainSlider();
    }

    countMainSlider = () => {
        // count main slider
        var result = (parseInt(this.state.levelObedience) + parseInt(this.state.levelLove) + parseInt(this.state.levelWorship) + parseInt(this.state.levelWitness) + parseInt(this.state.levelPrayer) + parseInt(this.state.levelWordOfGod) + parseInt(this.state.levelSpiritOfGod) + parseInt(this.state.levelFamilyOfGod) )/8;
        result = Math.floor(result);

        // change the 0-100 number to number 0, 25, 50, 75, 100
        var finalResult = 0;
        if(result <= 13)finalResult = 0;
        else if(result <= 37)finalResult = 25;
        else if(result <= 62)finalResult = 50;
        else if(result <= 87)finalResult = 75;
        else finalResult = 100;

        this.setState({ sliderValue: finalResult });
    }

    scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }

	render(){
		return(
            <React.Fragment>
                <Modal show={this.props.showModal} onClose={() => this.props.closeModal("GroTool")} isExpanded={this.state.modalExpanded} isExtraExpanded={true}>
                    <DiagnosticModalConfirmation show={this.state.showSaveRecordConfirmation} />
                    <div className="content">
                        <div className="horizontalStack flex">
                            <div className="verticalStackCenter flex">
                                <span className="fontPoppinsSemiBold15">GRO Tool<span style={{color: "var(--blue-button-link)"}}> - {this.state.mainContactName} {this.state.lastRecordDate ? <Moment format="M/D/YY">{this.state.lastRecordDate}</Moment> : null}</span></span>
                                <div>
                                    <span className="fontPoppinsRegular13Gray">{"This tool is designed to help you access the health of a discipler\'s growth in Christ. To learn more about our GRO study "}<span className="fontPoppinsSemiBold13 clickable" onClick={() => this.toggleModalExpansion()}>click here</span>.</span>
                                </div>
                                <div className="horizontalStackCenter flex">
                                    <img style={{width: "40%"}} src={gro_tool}/>
                                    <div className="horizontalStackCenter flex">
                                        <div style={{flex: 1}}></div>
                                        <div className="verticalStack" style={{width: "30%"}}>

                                            <div className="verticalStack">
                                                <progress className="groToolProgressBar" value={this.state.levelObedience} max="100"></progress>
                                                <span className="fontPoppinsSemiBold13DarkGray" style={{marginTop: 10}}>Obedience</span>
                                            </div>

                                            <div className="verticalStack" style={{marginTop: 30}}>
                                                <progress className="groToolProgressBar" value={this.state.levelWorship} max="100"></progress>
                                                <span className="fontPoppinsSemiBold13DarkGray" style={{marginTop: 10}}>Worship</span>
                                            </div>

                                            <div className="verticalStack" style={{marginTop: 30}}>
                                                <progress className="groToolProgressBar" value={this.state.levelPrayer} max="100"></progress>
                                                <span className="fontPoppinsSemiBold13DarkGray" style={{marginTop: 10}}>Prayer</span>
                                            </div>

                                            <div className="verticalStack" style={{marginTop: 30}}>
                                                <progress className="groToolProgressBar" value={this.state.levelSpiritOfGod} max="100"></progress>
                                                <span className="fontPoppinsSemiBold13DarkGray" style={{marginTop: 10}}>Spirit of God</span>
                                            </div>

                                        </div>
                                        <div style={{flex: 1}}></div>
                                        <div className="verticalStack" style={{width: "30%"}}>

                                            <div className="verticalStack">
                                                <progress className="groToolProgressBar"  value={this.state.levelLove} max="100"></progress>
                                                <span className="fontPoppinsSemiBold13DarkGray" style={{marginTop: 10}}>Love</span>
                                            </div>

                                            <div className="verticalStack" style={{marginTop: 30}}>
                                                <progress className="groToolProgressBar"  value={this.state.levelWitness} max="100"></progress>
                                                <span className="fontPoppinsSemiBold13DarkGray" style={{marginTop: 10}}>Witness</span>
                                            </div>

                                            <div className="verticalStack" style={{marginTop: 30}}>
                                                <progress className="groToolProgressBar"  value={this.state.levelWordOfGod} max="100"></progress>
                                                <span className="fontPoppinsSemiBold13DarkGray" style={{marginTop: 10}}>Word of God</span>
                                            </div>

                                            <div className="verticalStack" style={{marginTop: 30}}>
                                                <progress className="groToolProgressBar"  value={this.state.levelFamilyOfGod} max="100"></progress>
                                                <span className="fontPoppinsSemiBold13DarkGray" style={{marginTop: 10}}>Family of God</span>
                                            </div>

                                        </div>
                                        <div style={{flex: 1}}></div>
                                    </div>
                                </div>
                                <div style={{marginLeft: "50px", marginRight: "50px", marginTop: "30px"}}>
                                    <center><span className="fontPoppinsSemiBold15">Overall Gro Level:</span></center>

                                    <div style={{height: 20}}></div>

                                    {/*<Slider
                                        style={{color: "var(--crm-green)"}}
                                        aria-label="Always visible"
                                        value={this.state.sliderValue}
                                        step={1}
                                        disabled={true}
                                        onChange={(event, value) => this.setState({ sliderValue: value })}
                                        marks={marks}
                                        valueLabelDisplay="off"
                                    />*/}

                                    <div onClick={() => this.toggleModalExpansion()}>
                                        <Slider
                                            styles={"myCustomSlider myCustomSliderGreen"}
                                            value={this.state.sliderValue}
                                            style={{width: "100%"}}
                                            step={25}
                                        />
                                    </div>

                                    <div className="horizontalStack" style={{marginLeft: 5, marginRight: 5, marginTop: 20}}>
                                        <span className="fontPoppinsSemiBold13DarkGray">{"Low"}</span>
                                        <div className="flex"></div>
                                        <span className="fontPoppinsSemiBold13DarkGray">{"Medium Low"}</span>
                                        <div className="flex"></div>
                                        <span className="fontPoppinsSemiBold13DarkGray">{"Medium"}</span>
                                        <div className="flex"></div>
                                        <span className="fontPoppinsSemiBold13DarkGray">{"Medium High"}</span>
                                        <div className="flex"></div>
                                        <span className="fontPoppinsSemiBold13DarkGray">{"High"}</span>
                                    </div>

                                    <br/>

                                </div>
                                <div>
                                    <span className="fontPoppinsRegular13Gray">{"Open the GRO Diagnostic Tool by clicking on the button above to expand the sliders that you can use to assess the person’s current growth levels based on the instructions on the right. Take notes and once you click on the ‘Save’ button below a new record will be made."}</span>
                                </div>
                                <div className="horizontalStackCenter" style={{marginTop: "20px"}}>
                                    <div className="horizontalStackCenter" style={{display: this.state.modalExpanded ? "none" : "contents"}}>
                                        <RoundButton title={this.state.editingModeOfRecord != -1 ? "Edit Level and Note" : "Save Level and Note"} className={"roundButtonGreen"} style={{marginRight: "10px"}} whiteText={true} onClick={this.saveNewRecord} />
                                        <RoundButton title={"Cancel"} className={"roundButtonGray"} style={{marginRight: "10px"}} whiteText={true} onClick={() => this.props.closeModal("GroTool")} />
                                    </div>
                                    <div className="flex"></div>
                                    <div className="clickable" onClick={this.toggleModalExpansion}>

                                        { !this.state.modalExpanded ?
                                            <div className="modalExpandButton horizontalStackCenter">
                                                <span className="modalExpandButtonTextGreen fontPoppinsSemiBold13">Open Diagnostic Tool</span>
                                                <div className="modalExpandButtonIconContainer modalExpandButtonIconBgGreen verticalStackCenter">
                                                    <div className="horizontalStack">
                                                        <div className="flex"></div>
                                                        <img className="modalExpandButtonIcon modalExpandButtonGreen" src={icon_action_down}/>
                                                        <div className="flex"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        : null }

                                        { this.state.modalExpanded ?
                                            <div className="modalExpandButton horizontalStackCenter">
                                                <span className="modalExpandButtonTextGreen fontPoppinsSemiBold13">Close Diagnostic Tool</span>
                                                <div className="modalExpandButtonIconContainer modalExpandButtonIconBgGreen verticalStackCenter">
                                                    <div className="horizontalStack">
                                                        <div className="flex"></div>
                                                        <img className="modalExpandButtonIcon modalExpandButtonGreen" src={icon_action_up}/>
                                                        <div className="flex"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        : null }

                                    </div>
                                </div>
                            </div>

                            <div style={{width: 20}}></div>

                            <div className="verticalStack" style={{minWidth: "200px", maxWidth: "350px", flex: 0.55}}>
                                <div className="horizontalStack">
                                    <div className="flex"></div>
                                    <img className="modalCloseButton clickable" src={close_white} onClick={() => this.props.closeModal("GroTool")}/>
                                </div>
                                <div className="verticalStack" style={{marginTop: 20}}>
                                    <span className="fontPoppinsSemiBold15Gray">History:</span>

                                    <div className="modalHistoryContainer">
                                        <div className="modalHistoryDiv verticalStack">

                                        {this.state.arrayOfRecords.map(function (item) {
                                            return (
                                                <div className="verticalStack flex" style={{padding: 10, paddingTop: 0, backgroundColor: this.state.editingModeOfRecord == item.id ? "var(--lightest-crm-green)" : "var(--lightest-gray)"}}>
                                                    <div className="horizontalStackCenter">
                                                        <span className="fontPoppinsRegular13Blue modalHistoryDate"><Moment format="MMM. D, YYYY">{item.ins_at}</Moment></span>
                                                        <EditDeleteButton itemId={item.id} onEdit={this.onEditRecord} onDelete={this.onDeleteRecord} areYouSureText={"Are you sure want to delete this \"Disciple Lens\" record?"} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} />
                                                    </div>
                                                    <span className="fontPoppinsRegular13Blue" style={{marginTop: -9}}>{"By "}{item.cr_by}</span>
                                                    <span className="fontPoppinsRegular13"><span className="fontPoppinsBold13">{item.seg_label}{" -"} </span>{item.note_descr}</span>
                                                </div>
                                            )
                                        }.bind(this))}

                                        {/* if array empty but not because I received nothing from API then show row with text "Loading..." */}
                                        {this.state.arrayOfRecords.length == 0 && !this.state.noRecordsFound ? <div className="verticalStack" style={{padding: 10, paddingTop: 8, backgroundColor: "var(--lightest-gray)"}}> <span className="fontPoppinsItalic13Gray">{"Loading..."}</span> </div> : null}

                                        {/* if array empty because I did not receive any records, then show row with text like "No Records Yet" */}
                                        {this.state.arrayOfRecords.length == 0 && this.state.noRecordsFound ? <div className="verticalStack" style={{padding: 10, paddingTop: 8, backgroundColor: "var(--lightest-gray)"}}> <span className="fontPoppinsItalic13Gray">{"No Records Yet"}</span> </div> : null}

                                        <div className="flex"></div>

                                        </div>
                                    </div>

                                </div>
                                <div className="verticalStack flex" style={{marginTop: "30px"}}>
                                    <span className="fontPoppinsSemiBold15">Add a Note:</span>
                                    <textarea className="modalInputAddNote" placeholder={"Type your notes about this person's current discipleship level."} value={this.state.note_descr} onChange={e => this.setState({ note_descr: e.target.value })}></textarea>
                                </div>
                            </div>
                        </div>
                        <div style={{display: this.state.modalExpanded ? "inline" : "none"}}>
                            <div className="fakeEndOfModalWindow"></div>
                            <div className="horizontalStack" style={{paddingTop: 20}}>
                                <div className="flex">
                                    <div className="horizontalStackCenter">
                                        <span className="fontPoppinsSemiBold15">Branches</span>
                                        <div style={{flex: 1, height: 1, backgroundColor: "var(--border-light-gray)", marginLeft: 10}}></div>
                                    </div>
                                    <div style={{marginLeft: 20, marginRight: 20}}>
                                        <div style={{height: 20}}></div>

                                        <Slider
                                            styles={"myCustomSlider myCustomSliderGreen"}
                                            value={this.state.levelObedience}
                                            style={{width: "100%"}}
                                            step={1}
                                            onChange={(value) => {this.setState({ levelObedience: value }); this.scrollToRef(this.refObedience);}}
                                        />
                                        <div className="horizontalStack" style={{marginLeft: 5, marginRight: 5, marginTop: 15}}>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"Low"}</span>
                                            <div className="flex"></div>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"Obedience"}</span>
                                            <div className="flex"></div>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"High"}</span>
                                        </div>

                                        <div style={{height: 20}}></div>

                                        <Slider
                                            styles={"myCustomSlider myCustomSliderGreen"}
                                            value={this.state.levelLove}
                                            style={{width: "100%"}}
                                            step={1}
                                            onChange={(value) => {this.setState({ levelLove: value }); this.scrollToRef(this.refLove);}}
                                        />
                                        <div className="horizontalStack" style={{marginLeft: 5, marginRight: 5, marginTop: 15}}>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"Low"}</span>
                                            <div className="flex"></div>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"Love"}</span>
                                            <div className="flex"></div>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"High"}</span>
                                        </div>

                                        <div style={{height: 20}}></div>

                                        <Slider
                                            styles={"myCustomSlider myCustomSliderGreen"}
                                            value={this.state.levelWorship}
                                            style={{width: "100%"}}
                                            step={1}
                                            onChange={(value) => {this.setState({ levelWorship: value }); this.scrollToRef(this.refWorship);}}
                                        />
                                        <div className="horizontalStack" style={{marginLeft: 5, marginRight: 5, marginTop: 15}}>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"Low"}</span>
                                            <div className="flex"></div>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"Worship"}</span>
                                            <div className="flex"></div>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"High"}</span>
                                        </div>

                                        <div style={{height: 20}}></div>

                                        <Slider
                                            styles={"myCustomSlider myCustomSliderGreen"}
                                            value={this.state.levelWitness}
                                            style={{width: "100%"}}
                                            step={1}
                                            onChange={(value) => {this.setState({ levelWitness: value }); this.scrollToRef(this.refWitness);}}
                                        />
                                        <div className="horizontalStack" style={{marginLeft: 5, marginRight: 5, marginTop: 15}}>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"Low"}</span>
                                            <div className="flex"></div>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"Witness"}</span>
                                            <div className="flex"></div>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"High"}</span>
                                        </div>

                                    </div>
                                    <div className="horizontalStackCenter" style={{marginTop: 10}}>
                                        <span className="fontPoppinsSemiBold15">Roots</span>
                                        <div style={{flex: 1, height: 1, backgroundColor: "var(--border-light-gray)", marginLeft: 10}}></div>
                                    </div>
                                    <div style={{marginLeft: 20, marginRight: 20}}>

                                        <div style={{height: 20}}></div>

                                        <Slider
                                            styles={"myCustomSlider myCustomSliderGreen"}
                                            value={this.state.levelPrayer}
                                            style={{width: "100%"}}
                                            step={1}
                                            onChange={(value) => {this.setState({ levelPrayer: value }); this.scrollToRef(this.refPrayer);}}
                                        />
                                        <div className="horizontalStack" style={{marginLeft: 5, marginRight: 5, marginTop: 15}}>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"Low"}</span>
                                            <div className="flex"></div>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"Prayer"}</span>
                                            <div className="flex"></div>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"High"}</span>
                                        </div>

                                        <div style={{height: 20}}></div>

                                        <Slider
                                            styles={"myCustomSlider myCustomSliderGreen"}
                                            value={this.state.levelWordOfGod}
                                            style={{width: "100%"}}
                                            step={1}
                                            onChange={(value) => {this.setState({ levelWordOfGod: value }); this.scrollToRef(this.refWordOfGod);}}
                                        />
                                        <div className="horizontalStack" style={{marginLeft: 5, marginRight: 5, marginTop: 15}}>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"Low"}</span>
                                            <div className="flex"></div>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"Word of God"}</span>
                                            <div className="flex"></div>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"High"}</span>
                                        </div>

                                        <div style={{height: 20}}></div>

                                        <Slider
                                            styles={"myCustomSlider myCustomSliderGreen"}
                                            value={this.state.levelSpiritOfGod}
                                            style={{width: "100%"}}
                                            step={1}
                                            onChange={(value) => {this.setState({ levelSpiritOfGod: value }); this.scrollToRef(this.refSpiritOfGod);}}
                                        />
                                        <div className="horizontalStack" style={{marginLeft: 5, marginRight: 5, marginTop: 15}}>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"Low"}</span>
                                            <div className="flex"></div>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"Spirit of God"}</span>
                                            <div className="flex"></div>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"High"}</span>
                                        </div>

                                        <div style={{height: 20}}></div>

                                        <Slider
                                            styles={"myCustomSlider myCustomSliderGreen"}
                                            value={this.state.levelFamilyOfGod}
                                            style={{width: "100%"}}
                                            step={1}
                                            onChange={(value) => {this.setState({ levelFamilyOfGod: value }); this.scrollToRef(this.refFamilyOfGod);}}
                                        />
                                        <div className="horizontalStack" style={{marginLeft: 5, marginRight: 5, marginTop: 15}}>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"Low"}</span>
                                            <div className="flex"></div>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"Family of God"}</span>
                                            <div className="flex"></div>
                                            <span className="fontPoppinsSemiBold13DarkGray">{"High"}</span>
                                        </div>

                                    </div>

                                    <div className="horizontalStackCenter" style={{marginTop: 20}}>
                                        <RoundButton title={this.state.editingModeOfRecord != -1 ? "Edit Level and Note" : "Save Level and Note"} className={"roundButtonGreen"} style={{marginRight: "10px"}} whiteText={true} onClick={this.saveNewRecord} />
                                        <RoundButton title={"Cancel"} className={"roundButtonGray"} style={{marginRight: "10px"}} whiteText={true} onClick={() => this.props.closeModal("GroTool")} />
                                        <div className="flex"></div>
                                        <div ref={this.refModalBottom} className="modalExpandButton horizontalStackCenter clickable" onClick={this.toggleModalExpansion}>
                                            <span className="modalExpandButtonTextGreen fontPoppinsSemiBold13">Close Diagnostic Tool</span>
                                            <div className="modalExpandButtonIconContainer modalExpandButtonIconBgGreen verticalStackCenter">
                                                <div className="horizontalStack">
                                                    <div className="flex"></div>
                                                    <img className="modalExpandButtonIcon modalExpandButtonGreen" src={icon_action_up}/>
                                                    <div className="flex"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="verticalStack" style={{width: 300, marginLeft: 30, marginRight: 20}}>
                                    <span className="fontPoppinsSemiBold15">Diagnostic Instructions:</span>

                                    <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                                    <div className="modalInputAddNote modalInputAddNoteSide verticalStack">
                                        <span>{"The Gro Tree is a picture from the book of Colossians that can help you evaluate the habits of growth in the life of a believer.  Spiritual growth will most likely occur when the key conditions for growth are met.  Some of these spiritual habits have to do with “inputs” that nourish the life of Christ in you, represented by “roots”, and others are “outputs” that express the life of Christ through you, represented by “branches”.  These habits are not empty duties, but rather living channels that allow you to experience being “in Christ” (Col 2:9) and express what it means to have “Christ in you” (Col 1:27)"}</span>
                                        <span style={{marginTop: 20}}>{"“Therefore, as you received Christ Jesus the Lord, so walk in him, rooted and build up in him, and established in the faith, just as you were taught, abounding in thanksgiving. (Col 2:6-7)"}</span>
                                        <span style={{marginTop: 20}}>{"Use the following descriptions to evaluate eight core habits of growth in the life of a believer.  Your observations will not be an exact measurement, but rather like an intuitive evaluation of the health of a plant or tree. The greatest increase in growth will occur by concentrating on the roots or branches that are weak and strengthening them in a sustainable way."}</span>

                                        <span style={{marginTop: 20}}><span className="fontPoppinsSemiBold13">{"Roots"}</span>{" (to nourish the life of Christ in you)"}</span>
                                        <ol>
                                            <li>
                                                <div className="verticalStack">
                                                    <span ref={this.refPrayer} className="fontPoppinsSemiBold13">{"Prayer"}</span>
                                                    <span>{"We always thank God, the Father of our Lord Jesus Christ, when we pray for you, since we heard of your faith in Christ Jesus and of the love that you have for all the saints…  Col 1:3-4"}</span>
                                                    <span style={{marginTop: 20}}>{"And so, from the day we heard, we have not ceased to pray for you, asking that you may be filled with the knowledge of his will in all spiritual wisdom and understanding, so as to walk in a manner worthy of the Lord, fully pleasing to him: bearing fruit in every good work and increasing in the knowledge of God… Col 1:9-10"}</span>
                                                    <span style={{marginTop: 20}}>{"Continue steadfastly in prayer, being watchful in it with thanksgiving. Col 4:2"}</span>
                                                    <span style={{marginTop: 20}}>{"Do not be anxious about anything, but in everything by prayer and supplication with thanksgiving let your requests be made known to God. And the peace of God, which surpasses all understanding, will guard your hearts and your minds in Christ Jesus.  Phil 4:8-9"}</span>
                                                    <span style={{marginTop: 20}}>{"Rejoice always, pray without ceasing, give thanks in all circumstances; for this is the will of God in Christ Jesus for you. I Thess 5:16-18"}</span>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="verticalStack">
                                                    <span ref={this.refWordOfGod} className="fontPoppinsSemiBold13">{"Word of God"}</span>
                                                    <span>{"Of this you have heard before in the word of the truth, the gospel, which has come to you, as indeed in the whole world it is bearing fruit and increasing—as it also does among you, since the day you heard it and understood the grace of God in truth, just as you learned it from Epaphras our beloved fellow servant. Col 1:5-7"}</span>
                                                    <span style={{marginTop: 20}}>{"Let the word of Christ dwell in you richly, teaching and admonishing one another in all wisdom, singing psalms and hymns and spiritual songs, with thankfulness in your hearts to God. Col 3:16"}</span>
                                                    <span style={{marginTop: 20}}>{"All Scripture is breathed out by God and profitable for teaching, for reproof, for correction, and for training in righteousness, that the man of God may be complete, equipped for every good work. 2 Tim 3:16-17"}</span>
                                                    <span style={{marginTop: 20}}>{"Blessed is the man who walks not in the counsel of the wicked, nor stands in the way of sinners, nor sits in the seat of scoffers; but his delight is in the law of the Lord, and on his law he meditates day and night. He is like a tree planted by streams of water… Psalm 1:1-3"}</span>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="verticalStack">
                                                    <span ref={this.refSpiritOfGod} className="fontPoppinsSemiBold13">{"Spirit of God"}</span>
                                                    <span>{"He is a faithful minister of Christ on your behalf and has made known to us your love in the Spirit.  Col 1:7-8"}</span>
                                                    <span style={{marginTop: 20}}>{"And I will ask the Father, and he will give you another Helper,f to be with you forever, even the Spirit of truth, whom the world cannot receive, because it neither sees him nor knows him. You know him, for he dwells with you and will be in you. John 14:16-17"}</span>
                                                    <span style={{marginTop: 20}}>{"And do not get drunk with wine, for that is debauchery, but be filled with the Spirit, Eph 5:18"}</span>
                                                    <span style={{marginTop: 20}}>{"But I say, walk by the Spirit, and you will not gratify the desires of the flesh.  But the fruit of the Spirit is love, joy, peace, patience, kindness, goodness, faithfulness, gentleness, self-control; against such things there is no law. And those who belong to Christ Jesus have crucified the flesh with its passions and desires. If we live by the Spirit, let us also keep in step with the Spirit. Gal 5:16, 22-25"}</span>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="verticalStack">
                                                    <span ref={this.refFamilyOfGod} className="fontPoppinsSemiBold13">{"Family of God"}</span>
                                                    <span>{"and not holding fast to the Head, from whom the whole body, nourished and knit together through its joints and ligaments, grows with a growth that is from God. Col 2:19"}</span>
                                                    <span style={{marginTop: 20}}>{"Here there is not Greek and Jew, circumcised and uncircumcised, barbarian, Scythian, slave, free; but Christ is all, and in all. Col 3:11"}</span>
                                                    <span style={{marginTop: 20}}>{"So then you are no longer strangers and aliens, but you are fellow citizens with the saints and members of the household of God, built on the foundation of the apostles and prophets, Christ Jesus himself being the cornerstone. Eph 2:19-20"}</span>
                                                    <span style={{marginTop: 20}}>{"…not neglecting to meet together, as is the habit of some, but encouraging one another, and all the more as you see the Day drawing near. Heb 10:25"}</span>
                                                </div>
                                            </li>
                                        </ol>
                                        <span style={{marginTop: 20}}><span className="fontPoppinsSemiBold13">{"Branches"}</span>{" (to express the life of Christ through you)"}</span>
                                        <ol start="5">
                                            <li>
                                                <div className="verticalStack">
                                                    <span ref={this.refObedience} className="fontPoppinsSemiBold13">{"Obedience"}</span>
                                                    <span>{"If then you have been raised with Christ, seek the things that are above, where Christ is, seated at the right hand of God. Set your minds on things that are above, not on things that are on earth. Col 3:1-2"}</span>
                                                    <span style={{marginTop: 20}}>{"Put to death therefore what is earthly in you: sexual immorality, impurity, passion, evil desire, and covetousness, which is idolatry. On account of these the wrath of God is coming. In these you too once walked, when you were living in them. But now you must put them all away: anger, wrath, malice, slander, and obscene talk from your mouth. Do not lie to one another, seeing that you have put off the old self with its practices and have put on the new self, which is being renewed in knowledge after the image of its creator. Col 3:5-10"}</span>
                                                    <span style={{marginTop: 20}}>{"…for at one time you were darkness, but now you are light in the Lord. Walk as children of light (for the fruit of light is found in all that is good and right and true), and try to discern what is pleasing to the Lord. Eph 5:8-9"}</span>
                                                    <span style={{marginTop: 20}}>{"Whoever has my commandments and keeps them, he it is who loves me. And he who loves me will be loved by my Father, and I will love him and manifest myself to him.” John 14:21"}</span>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="verticalStack">
                                                    <span ref={this.refLove} className="fontPoppinsSemiBold13">{"Love"}</span>
                                                    <span>{"Put on then, as God’s chosen ones, holy and beloved, compassionate hearts, kindness, humility, meekness, and patience, bearing with one another and, if one has a complaint against another, forgiving each other; as the Lord has forgiven you, so you also must forgive. And above all these put on love, which binds everything together in perfect harmony. Col 3:12-14"}</span>
                                                    <span style={{marginTop: 20}}>{"…but through love serve one another. For the whole law is fulfilled in one word: “You shall love your neighbor as yourself. Gal 5:13-14"}</span>
                                                    <span style={{marginTop: 20}}>{"Owe no one anything, except to love each other, for the one who loves another has fulfilled the law. Romans 13:8"}</span>
                                                    <span style={{marginTop: 20}}>{"Above all, keep loving one another earnestly, since love covers a multitude of sins. I Pet 4:8"}</span>
                                                    <span style={{marginTop: 20}}>{"So now faith, hope, and love abide, these three; but the greatest of these is love. I Cor 13:13"}</span>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="verticalStack">
                                                    <span ref={this.refWorship} className="fontPoppinsSemiBold13">{"Worship"}</span>
                                                    <span>{"…singing psalms and hymns and spiritual songs, with thankfulness in your hearts to God. And whatever you do, in word or deed, do everything in the name of the Lord Jesus, giving thanks to God the Father through him. Col 3:16-17"}</span>
                                                    <span style={{marginTop: 20}}>{"… giving thanks to the Father, who has qualified you to share in the inheritance of the saints in light. Col 1:12"}</span>
                                                    <span style={{marginTop: 20}}>{"…addressing one another in psalms and hymns and spiritual songs, singing and making melody to the Lord with your heart, giving thanks always and for everything to God the Father in the name of our Lord Jesus Christ… Eph 5:19-20"}</span>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="verticalStack">
                                                    <span ref={this.refWitness} className="fontPoppinsSemiBold13">{"Witness"}</span>
                                                    <span>{"…pray also for us, that God may open to us a door for the word, to declare the mystery of Christ, on account of which I am in prison—that I may make it clear, which is how I ought to speak. Walk in wisdom toward outsiders, making the best use of the time. Let your speech always be gracious, seasoned with salt, so that you may know how you ought to answer each person. Col 4:4-6"}</span>
                                                    <span style={{marginTop: 20}}>{"… making supplication for all the saints, and also for me, that words may be given to me in opening my mouth boldly to proclaim the mystery of the gospel, for which I am an ambassador in chains, that I may declare it boldly, as I ought to speak. Eph 6:18-20"}</span>
                                                    <span style={{marginTop: 20}}>{"But you will receive power when the Holy Spirit has come upon you, and you will be my witnesses in Jerusalem and in all Judea and Samaria, and to the end of the earth.” Acts 1:8"}</span>
                                                    <span style={{marginTop: 20}}>{"For I am not ashamed of the gospel, for it is the power of God for salvation to everyone who believes… Rom 1:16"}</span>
                                                </div>
                                            </li>
                                        </ol>

                                    </div>
                                    </div>
                                    {/*
                                    <textarea className="modalInputAddNote flex" style={{width: "100%"}} disabled>{"Obedience\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n\nLove\nDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n\nWorship\nDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n\nWitness\nDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}</textarea>
                                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
		);
	}
}

export default GroToolModal;