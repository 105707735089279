import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import Axios from 'axios';
import CsvDownloadButton from 'react-json-to-csv';

// import utilities
import { moreRecordsOnPageUtilities, lessRecordsOnPageUtilities, goToPrevPageUtilities, goToNextPageUtilities, goToFirstPageUtilities, goToLastPageUtilities, checkIfOutOfPages } from '../utilities/PagingUtilities.js';
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { fetchContactImage } from '../utilities/OtherUtilities.js';

// import components
import MyPeopleTile from '../tiles/MyPeopleTile.js';

function storageEventHandler() {
    this.setState({ recordsOnOnePage: localStorage.getItem("recordsOnOnePagePeople") || 25 });
    this.setState({ actualPage: localStorage.getItem("actualPagePeople") || 0 });
    this.setState({ sortBy: localStorage.getItem("sortByPeople") || 0 });
    this.setState({ sortTp: localStorage.getItem("sortTpPeople") || 0 });
}

class PeopleList extends React.Component {
    setActualSection = this.props.setActualSection;

    constructor(props) {
        super(props);

        this.state = {
            peopleData: ([]),
            /*peopleData: ([{id: 3, first_name:"Jiří", last_name: "Němec", role: "Youth Leader", email: "jnemec@cbbrno.com", phone: "184 637 823", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add", avatar: require("../assets/person_avatar.jpeg")},
                            {id: 3, first_name:"Petr", last_name: "Říha", role: "Youth Leader", email: "jnemec@cbbrno.com", phone: "184 637 823", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add", avatar: require("../assets/person_avatar.jpeg")},
                            {id: 3, first_name:"Ester", last_name: "Napoleonová", role: "Youth Leader", email: "jnemec@cbbrno.com", phone: "184 637 823", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add", avatar: require("../assets/person_avatar.jpeg")},
                            {id: 3, first_name:"Zdeněk", last_name: "Čtvrtý", role: "Youth Leader", email: "jnemec@cbbrno.com", phone: "184 637 823", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add", avatar: require("../assets/person_avatar.jpeg")},
                            {id: 3, first_name:"Augustín", last_name: "Všestranný", role: "Youth Leader", email: "jnemec@cbbrno.com", phone: "184 637 823", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add", avatar: require("../assets/person_avatar.jpeg")},
                            {id: 3, first_name:"Lucie", last_name: "Křížová", role: "Youth Leader", email: "jnemec@cbbrno.com", phone: "184 637 823", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add", avatar: require("../assets/person_avatar.jpeg")},
                            {id: 3, first_name:"Zbyněk", last_name: "Štěteček", role: "Youth Leader", email: "jnemec@cbbrno.com", phone: "184 637 823", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add", avatar: require("../assets/person_avatar.jpeg")},
                            {id: 3, first_name:"Jiří", last_name: "Němec", role: "Youth Leader", email: "jnemec@cbbrno.com", phone: "184 637 823", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add", avatar: require("../assets/person_avatar.jpeg")}]),*/
            recordsOnOnePage: localStorage.getItem("recordsOnOnePagePeople") || 25, // number of people showing on one page, 25 is default
            totalNumberOfContacts: 0,
            noRecordsFound: false,
            actualPage: localStorage.getItem("actualPagePeople") || 0, // starts with pageNumber=0, then 1, 2, 3,...
            sortBy: localStorage.getItem("sortByPeople") || "", // name
            sortTp: localStorage.getItem("sortTpPeople") || "asc", // asc, desc
        }

    }

    componentDidMount(){
        this.loadData();
        window.addEventListener('storage', storageEventHandler, false);
        this.setActualSection("people");
    }

    componentDidUpdate(prevProps, prevState) {
        /*if(prevProps.searchText !== this.props.searchText) {
            this.setState({ actualPage: 0 });
            this.loadData();
        }*/
        if(prevProps.allOrMy !== this.props.allOrMy) {
            this.setState({ actualPage: 0 });
            localStorage.setItem("actualPagePeople", 0);
            this.loadData();
        }
    }

    loadData = () => {
        // if user lowers the rowsPerPage, goes next page and then highers the rowsPerPage it will take him to the last page
        if(checkIfOutOfPages(this.state.actualPage, this.state.recordsOnOnePage, this.state.totalNumberOfContacts)){
            this.goToLastPage();
            return;
        }

        this.setState({ noRecordsFound: false });
        this.setState({ peopleData: [] });
        this.setState({ totalNumberOfContacts: 0 });

        //var getOnlyMyContacts = this.props.searchText == "" ? 1 : 0; // If I am searching it would search in all, if not it gives me my contacts
        // now it works according to the MY/ALL switch, not if there is some searchText or not
        var getOnlyMyContacts = this.props.allOrMy == "my" ? 1 : 0;
        //Axios.get('/api/cont/get?tp=1&my='+getOnlyMyContacts+'&limit='+this.state.recordsOnOnePage+'&offset='+(this.state.actualPage*this.state.recordsOnOnePage)+'&search='+this.props.searchText).then(response => {
        var url = '/api/cont/get?tp=1&my='+getOnlyMyContacts+'&limit='+this.state.recordsOnOnePage+'&offset='+(this.state.actualPage*this.state.recordsOnOnePage);
        if(this.state.sortBy != ""){
            url = url + "&sort_by="+this.state.sortBy+"&sort_tp="+this.state.sortTp;
        }
        Axios.get(url).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.setState({ peopleData: response.data.result });
            this.setState({ totalNumberOfContacts: response.data.msg.cnt });
            if(response.data.result.length == 0){ this.setState({ noRecordsFound: true }); }

            this.state.peopleData.forEach((contact) => this.handleGetContactImage(contact));

            // save the ids array to memory
            var idsArray = response.data.result.map(a => a.id);
            localStorage.setItem('idsArray', JSON.stringify(idsArray));
        });
    }

    handleGetContactImage = async (contact) => {
        const base64Image = await fetchContactImage(contact,"avatar");
        this.setState((prevState) => ({ peopleData: prevState.peopleData.map((u) => (u.id === contact.id ? { ...u, image: base64Image } : u)), }));
    };

    goToFirstPage = async () => {
        await this.setState({ actualPage: goToFirstPageUtilities(this.state.actualPage, "actualPagePeople") });
        this.loadData();
    }

    goToPrevPage = async () => {
        await this.setState({ actualPage: goToPrevPageUtilities(this.state.actualPage, "actualPagePeople") });
        this.loadData();
    }

    goToNextPage = async () => {
        await this.setState({ actualPage: goToNextPageUtilities(this.state.actualPage, this.state.recordsOnOnePage, this.state.totalNumberOfContacts, "actualPagePeople") });
        this.loadData();
    }

    goToLastPage = async () => {
        await this.setState({ actualPage: goToLastPageUtilities(this.state.recordsOnOnePage, this.state.totalNumberOfContacts, "actualPagePeople") });
        this.loadData();
    }

    moreRecordsOnPage = async () => {
        await this.setState({ recordsOnOnePage: moreRecordsOnPageUtilities(this.state.recordsOnOnePage, "recordsOnOnePagePeople") });
        this.loadData();
    }

    lessRecordsOnPage = async () => {
        await this.setState({ recordsOnOnePage: lessRecordsOnPageUtilities(this.state.recordsOnOnePage, "recordsOnOnePagePeople") });
        this.loadData();
    }

    changeSorting = async (attribute) => {
        this.goToFirstPage();
        if(this.state.sortBy == attribute && this.state.sortTp == "asc") {
            localStorage.setItem("sortTpPeople", "desc");
            await this.setState({ sortTp: "desc" });
        }
        else if(this.state.sortBy == attribute && this.state.sortTp == "desc") {
            localStorage.setItem("sortByPeople", "");
            localStorage.setItem("sortTpPeople", "desc");
            await this.setState({ sortBy: "" });
            await this.setState({ sortTp: "desc" });
        }
        else {
            localStorage.setItem("sortByPeople", attribute);
            localStorage.setItem("sortTpPeople", "asc");
            await this.setState({ sortBy: attribute });
            await this.setState({ sortTp: "asc" });
        }
        this.loadData();
    }

    render() {
        return (
            <div className="verticalStack flex" style={{height: "calc(100vh - 80px)"}}>
                <div className="horizontalStackCenter myPeopleTableListingBar">
                    <span onClick={this.goToFirstPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange">{"<<"}</span>
                    <span onClick={this.goToPrevPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange" style={{paddingLeft: "20px"}}>{"<"}</span>
                    <span className="fontPoppinsRegular13Gray" style={{paddingLeft: "20px", paddingRight: "12px"}}>{this.props.allOrMy == "my" ? "My" : "All"}{" People List"}</span>
                    <span className="fontPoppinsSemiBold16">{(this.state.actualPage*this.state.recordsOnOnePage)+1}{"-"}{(this.state.actualPage+1)*this.state.recordsOnOnePage > this.state.totalNumberOfContacts ? this.state.totalNumberOfContacts : (this.state.actualPage+1)*this.state.recordsOnOnePage }</span>
                    <span className="fontPoppinsRegular13Gray" style={{paddingLeft: "6px", paddingRight: "6px"}}>{"of"}</span>
                    <span className="fontPoppinsSemiBold16">{this.state.totalNumberOfContacts}</span>
                    <span onClick={this.goToNextPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange" style={{paddingLeft: "20px", paddingRight: "20px"}}>{">"}</span>
                    <span onClick={this.goToLastPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange">{">>"}</span>
                    <div className="flex"/>
                    <CsvDownloadButton data={this.state.peopleData} filename={"export_people.csv"} className="fontPoppinsRegular13Gray clickable onHoverToOrange" style={{backgroundColor: "transparent", border: "none", paddingRight: "30px"}}>Export CSV</CsvDownloadButton>
                    <span className="fontPoppinsRegular13Gray">{"Rows Per page:"}</span>
                    <span className="fontPoppinsSemiBold16" style={{paddingLeft: "10px", paddingRight: "10px"}}>{this.state.recordsOnOnePage}</span>
                    <div className="verticalStackCenter">
                        <div onClick={this.moreRecordsOnPage} className="clickable buttonPagingUp"></div>
                        <div style={{height: "3px"}}></div>
                        <div onClick={this.lessRecordsOnPage} className="clickable buttonPagingDown"></div>
                    </div>
                </div>
                <MyPeopleTile peopleArray={this.state.peopleData} leftMenuOpened={this.props.leftMenuOpened} openAlertMessage={this.props.openAlertMessage} changeSorting={this.changeSorting} sortBy={this.state.sortBy} sortTp={this.state.sortTp} loadData={this.loadData} allOrMy={this.props.allOrMy} noRecordsFound={this.state.noRecordsFound} />
            </div>
        );
    }
}

export default PeopleList;
