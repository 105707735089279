import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import { PieChart } from 'react-minimal-pie-chart';
import { Link, Navigate } from "react-router-dom";
import Moment from 'react-moment';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import MapTile from '../tiles/MapTile.js';
import RoundButton from '../components/RoundButton.js';
import BarChart from '../components/BarChart.js';
import BarChartTwo from '../components/BarChartTwo.js';
import DropdownItem from '../components/DropdownItem.js';

// import assets
import icon_conversations_gray from '../assets/icon_conversations_gray.png';
import icon_expand from '../assets/icon_expand.png';
import icon_graph_youth_group from '../assets/icon_graph_youth_group.png';
import icon_graph_churches from '../assets/icon_graph_churches.png';
import icon_search from '../assets/icon_search.png';

class Dashboard extends React.Component {
    setActualSection = this.props.setActualSection;

    constructor(props) {
        super(props);

        this.state = {
            array_youth_groups: ([{ title: 'Churches without Youth group', value: 0, color: '#BCB5D0' },
                                  { title: 'Churches with Youth Group', value: 0, color: 'var(--jv-orange)' }]),
            count_youth_groups: 0,
            array_youth_group_by_type: ([{ title: '-', value: 0, color: '#BCB5D0' },
                                         { title: '-', value: 0, color: 'var(--jv-orange)' }]),
            count_youth_group_by_type: 0,
            array_churches_by_denomination: ([{ dnm_name: '-', value: 0, color: 'var(--blue-button-link)' },
                                              { dnm_name: '-', value: 0, color: 'var(--jv-orange)' }]),
            count_churches_by_denomination: 0,
            array_churches_by_jv_engagement: ([{ title: 'JVE 0', value: 0, color: 'var(--crm-pink)' },
                                               { title: 'JVE 0', value: 0, color: 'var(--jv-orange)' }]),
            count_churches_by_jv_engagement: 0,
            churchCategoriesArray: ([{"id":1,"name":"All Churches"},
                                           {"id":2,"name":"All Churches with Youth Groups"},
                                           {"id":3,"name":"All Churches without Youth Groups"},
                                           {"id":4,"name":"All Churches with Fusion Groups"},
                                           {"id":5,"name":"All Churches with a Camps Team"},
                                           {"id":6,"name":"All Churches with Interns"},
                                           {"id":7,"name":"My Churches"}]),
            startDate: "2022-12-16",
            endDate: "2022-12-30",
            conversations_by_type_labels: ["Relationship Building", "Evangelism", "Discipleship", "Leadership Dev.", "Coaching", "Counseling", "Administrative", "Uplink"],
            conversations_by_type_values: [7.5, 8, 9, 11, 13.5, 19, 24, 20.5],
            totalConversationsByType: 137,
            selectedChurchCategory: 1,
            showPopupChurchCategory: false,
            showPopupDatePicker: false,
            showPopupFilterByWho: false,
            foundContacts: ([]),
            filterConversationsContactId: 0,
            filterConversationsContactName: "Unselected",
            filterCountryId: 61,
            filterCountryName: "Czech Republic",
        }

        this.refPopupChurchCategory = React.createRef();
        this.refPopupDatePicker = React.createRef();
        this.refPopupFilterByWho = React.createRef();
        this.refInputFilterByWho = React.createRef();
    }

    saveCountry = (id, name) => {
        this.setState({ filterCountryId: id });
        this.setState({ filterCountryName: name });
    }

    getRandomColor = () => {
        var color = '#';
        let min = 75;
        let max = 200;
        var red = min + (Math.random() * (max - min));
        var green = min + (Math.random() * (max - min));
        var blue = min + (Math.random() * (max - min));

        return "rgb("+red+","+green+","+blue+")";

        for (var i = 0; i < 6; i++) {
            color += Math.floor(Math.random() * 10);
        }
        //return color;
    }

    getColor = (index, size) => {
        if(index != undefined && size != undefined){
            if(size <= 2){
                if(index == 0)return "rgb(217,211,232)";
                else if(index == 1)return "rgb(255,102,0)";
            }
            else if(size <= 5) {
                const shortList = ["rgb(255,102,0)", "rgb(234,213,49)", "rgb(33,213,147)", "rgb(85,110,230)", "rgb(250,80,179)"];
                return shortList[index];
            }
            else {
                const longList = ["rgb(255,102,0)", "rgb(255,174,119)", "rgb(234,213,49)", "rgb(240,228,137)", "rgb(33,213,147)", "rgb(34,230,158)", "rgb(85,110,230)", "rgb(142,159,234)", "rgb(250,80,179)", "rgb(255,173,221)"];
                return longList[index%10];
            }
        }
        else {
            var color = '#';
            let min = 75;
            let max = 200;
            var red = min + (Math.random() * (max - min));
            var green = min + (Math.random() * (max - min));
            var blue = min + (Math.random() * (max - min));

            return "rgb("+red+","+green+","+blue+")";
        }
    }

    componentDidMount(){
        this.setActualSection("dashboard");


        Axios.get('/api/perm_cntry/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var cntry_id = response.data.result[0].id;

            // get data for graph churches by denomination
            Axios.get('/api/rpt/ch_by_dnm/get?cntry='+cntry_id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                var workingList = ([]);
                var count_churches_by_denomination = 0;
                //for(let oneRow of response.data.result){ workingList.push({"dnm_name":oneRow.dnm_name, "value": oneRow.cnt, "color": ""+this.getColor()}); }
                response.data.result.forEach((element, index) => {
                    workingList.push({"dnm_name":element.dnm_name, "value": element.cnt, "color": ""+this.getColor(index, response.data.result.length)});
                    count_churches_by_denomination = count_churches_by_denomination+element.cnt;
                });
                this.setState({ array_churches_by_denomination: workingList });
                this.setState({ count_churches_by_denomination: count_churches_by_denomination });
            });

            // get data for graph churches by JV engagement
            Axios.get('/api/rpt/ch_by_jve/get?cntry='+cntry_id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                var workingList = ([]);
                var count_churches_by_jv_engagement = 0;
                //for(let oneRow of response.data.result){ workingList.push({"seg_label":oneRow.seg_label, "value": oneRow.cnt, "color": ""+this.getColor()}); }
                response.data.result.forEach((element, index) => {
                    workingList.push({"seg_label":element.seg_label, "value": element.cnt, "color": ""+this.getColor(index, response.data.result.length)});
                    count_churches_by_jv_engagement = count_churches_by_jv_engagement+element.cnt;
                });
                this.setState({ array_churches_by_jv_engagement: workingList });
                this.setState({ count_churches_by_jv_engagement: count_churches_by_jv_engagement });
            });

            // get data for graph churches with youth group
            Axios.get('/api/rpt/ch_by_ygrp/get?cntry='+cntry_id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                var workingList = ([]);
                var count_youth_groups = 0;
                /*for(let oneRow of response.data.result){
                    if(oneRow.ygrp_flag == 0){
                        workingList.push({"label": "Churches without Youth group", "value": oneRow.cnt, "color": ""+this.getColor()});
                    }
                    else if(oneRow.ygrp_flag == 1){
                        workingList.push({"label": "Churches with Youth Group", "value": oneRow.cnt, "color": ""+this.getColor()});
                    }
                }*/

                response.data.result.forEach((element, index) => {
                    if(element.ygrp_flag == 0){
                        //workingList.push({"label": "Churches without Youth group", "value": oneRow.cnt, "color": ""+this.getColor()});
                        workingList.push({"label":"Churches without Youth group", "value": element.cnt, "color": ""+this.getColor(index, response.data.result.length)});
                        count_youth_groups = count_youth_groups+element.cnt;
                    }
                    else if(element.ygrp_flag == 1){
                        //workingList.push({"label": "Churches with Youth Group", "value": oneRow.cnt, "color": ""+this.getColor()});
                        workingList.push({"label":"Churches with Youth Group", "value": element.cnt, "color": ""+this.getColor(index, response.data.result.length)});
                        count_youth_groups = count_youth_groups+element.cnt;
                    }
                });
                this.setState({ array_youth_groups: workingList });
                this.setState({ count_youth_groups: count_youth_groups});
            });

            // get data for graph youth group by type
            Axios.get('/api/rpt/ygrp_by_tp/get?cntry='+cntry_id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                var workingList = ([]);
                var count_youth_group_by_type = 0;
                //for(let oneRow of response.data.result){ workingList.push({"team": oneRow.team, "value": oneRow.cnt, "color": ""+this.getColor()}); }
                response.data.result.forEach((element, index) => {
                    workingList.push({"team":element.team, "value": element.cnt, "color": ""+this.getColor(index, response.data.result.length)});
                    count_youth_group_by_type = count_youth_group_by_type+element.cnt;
                });
                this.setState({ array_youth_group_by_type: workingList });
                this.setState({ count_youth_group_by_type: count_youth_group_by_type });
            });
        });

        this.loadConversationsByType(0,0,"All Conversations");

        window.addEventListener('click', this.onGlobalClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onGlobalClick);
    }

    loadConversationsByType = (cont_id, my, name) => {

        Axios.get('/api/perm_cntry/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var cntry_id = response.data.result[0].id;

            this.setState({ filterConversationsContactName: name });
            var url = "/api/rpt/conv_by_tp/get?cntry="+cntry_id+"&my="+my;
            if(cont_id > 0){
                url = '/api/rpt/conv_by_tp/get?cntry='+cntry_id+'&cont_id='+cont_id+'&my='+my;
            }

            // get data for graph conversations by type
            Axios.get(url).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                var labelsList = [];
                var valuesList = [];
                var totalCount = 0;
                for(let oneRow of response.data.result){
                    labelsList.push(oneRow.ctg_label);
                    valuesList.push(oneRow.cnt);
                    totalCount = totalCount + oneRow.cnt;
                }
                this.setState({ conversations_by_type_labels: labelsList });
                this.setState({ conversations_by_type_values: valuesList });
                this.setState({ totalConversationsByType: totalCount });
            });

            this.setState({ showPopupFilterByWho: false });
            this.setState({ findContactByName: "" });
            this.setState({ foundContacts: [] });

        });

    }

    /* This method is triggered on every click on screen and is checking if some popup is opened nad user clicked out of this popup so it would close that popup in that situation */
    onGlobalClick = (e) => {
        if ( this.refPopupChurchCategory.current && (!this.refPopupChurchCategory.current.contains(e.target)) && this.state.showPopupChurchCategory) {
            // user clicked out of the PopupChurchCategory and that popup is now opened
            this.setState({ showPopupChurchCategory: false })
        }
        if ( this.refPopupDatePicker.current && (!this.refPopupDatePicker.current.contains(e.target)) && this.state.showPopupDatePicker) {
            this.setState({ showPopupDatePicker: false })
        }
        if ( this.refPopupFilterByWho.current && (!this.refPopupFilterByWho.current.contains(e.target)) && this.state.showPopupFilterByWho) {
            this.setState({ showPopupFilterByWho: false })
        }
        if ( this.state.showPopupFilterByWho ) this.refInputFilterByWho.current.focus();
    }

    getSelectedChurchCategoryName = (id) => {
        for(let oneRow of this.state.churchCategoriesArray){
            if(oneRow.id == id){
                return oneRow.name;
            }
        }
        return "Error";
    }

    searchForContacts = async (searchText) => {
        if(searchText.length >= 3){
            Axios.get('/api/cont/get?tp=1&search='+searchText).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
                this.setState({ foundContacts: response.data.result });
            });
        }
        else {
            this.setState({ foundContacts: [] });
        }
    }

    render() {
        return(
                <div className="verticalStack flex" style={{height: "calc(100vh - 80px)", overflowY: "scroll"}}>
                    <div className="horizontalStackCenter myPeopleTableListingBar">
                        {/*<span className="fontPoppinsRegular13Gray">{"Dashboard"}{" / "}{this.state.filterCountryName}{" / "}{this.getSelectedChurchCategoryName(this.state.selectedChurchCategory)}{" / "}<Moment format="MMMM D, YYYY">{this.state.startDate}</Moment>{" - "}<Moment format="MMMM D, YYYY">{this.state.endDate}</Moment></span>*/}
                        <span className="fontPoppinsRegular13Gray">{"Dashboard"}{" / "}{this.state.filterCountryName}</span>
                        <div className="flex"/>
                        <span className="fontPoppinsRegular13Gray onHoverToOrange clickable">{"Download PDF"}</span>
                    </div>

                    <div className="horizontalStack" style={{marginBottom: 20}}>

                        <div className="verticalStack flex">

                            <MapTile setActualOpenedTile={this.setActualOpenedTile} update={this.update} countryName={this.state.filterCountryName} saveCountry={this.saveCountry} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} />

                            <div style={{height: 20}}></div>

                            <div className="verticalStack flex">

                                <div className="verticalStack flex">

                                    <div className="horizontalStack">

                                        <div className="universalTile flex verticalStack" style={{marginRight: 20, marginLeft: 20}}>
                                            <div className="responsiveThreeDotsContainer">
                                                <span className="fontPoppinsSemiBold15 responsiveThreeDots">{"Churches by Denomination"}</span>
                                            </div>

                                            <div style={{height: 10}}></div>

                                            <div style={{position: "relative"}}>
                                                <PieChart
                                                    style={{height: 150}}
                                                    lineWidth={35}
                                                    paddingAngle={1}
                                                    labelPosition={82}
                                                    label={({ dataEntry }) => dataEntry.value}
                                                    labelStyle={{fill: "white", fontFamily: "PoppinsMedium", fontSize: 6}}
                                                    data={this.state.array_churches_by_denomination}
                                                />

                                                <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)"}}>
                                                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                                        <img style={{width: "30px", height: "30px"}} src={icon_graph_churches}/>
                                                        <span className="fontPoppinsRegular27Orange">{this.state.count_churches_by_denomination}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{height: 20}}></div>

                                            <div className="horizontalStack">
                                                <div className="flex"></div>
                                                <div style={{width: "100%", display: "grid", gridTemplateColumns: "auto auto", gap: 10, columnGap: 20}}>

                                                    {this.state.array_churches_by_denomination.map(function (item) {
                                                        return (
                                                            <div className="horizontalStackCenter">
                                                                <div className="responsiveThreeDotsContainer flex">
                                                                    <div className="horizontalStackCenter">
                                                                        <div style={{minWidth: 15, width: 15, aspectRatio: "1/1", backgroundColor: item.color, borderRadius: 20}}></div>
                                                                        <span className="fontPoppinsMedium9 responsiveThreeDots" title={item.dnm_name} style={{marginLeft: 5}}>{item.dnm_name}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }.bind(this))}

                                                </div>
                                                <div className="flex"></div>
                                            </div>

                                        </div>

                                        <div className="universalTile flex verticalStack" style={{marginRight: 0}}>
                                            <div className="responsiveThreeDotsContainer">
                                                <span className="fontPoppinsSemiBold15 responsiveThreeDots">{"Churches by JV Engagement"}</span>
                                            </div>

                                            <div style={{height: 10}}></div>

                                            <div style={{position: "relative"}}>
                                                <PieChart
                                                    style={{height: 150}}
                                                    lineWidth={35}
                                                    paddingAngle={1}
                                                    labelPosition={82}
                                                    label={({ dataEntry }) => dataEntry.value}
                                                    labelStyle={{fill: "white", fontFamily: "PoppinsMedium", fontSize: 6}}
                                                    data={this.state.array_churches_by_jv_engagement}
                                                />

                                                <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)"}}>
                                                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                                        <img style={{width: "30px", height: "30px"}} src={icon_graph_churches}/>
                                                        <span className="fontPoppinsRegular27Orange">{this.state.count_churches_by_jv_engagement}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{height: 20}}></div>

                                            <div className="horizontalStack">
                                                <div className="flex"></div>
                                                <div style={{width: "100%", display: "grid", gridTemplateColumns: "auto auto", gap: 10, columnGap: 20}}>

                                                    {this.state.array_churches_by_jv_engagement.map(function (item) {
                                                        return (
                                                            <div className="horizontalStackCenter">
                                                                <div className="responsiveThreeDotsContainer flex">
                                                                    <div className="horizontalStackCenter">
                                                                        <div style={{minWidth: 15, width: 15, aspectRatio: "1/1", backgroundColor: item.color, borderRadius: 20}}></div>
                                                                        <span className="fontPoppinsMedium9 responsiveThreeDots" title={item.seg_label} style={{marginLeft: 5}}>{item.seg_label}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }.bind(this))}

                                                </div>
                                                <div className="flex"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>

                        <div style={{width: 20}}></div>

                        <div className="verticalStack flex">

                            <div className="verticalStack">

                                {/*<div className="universalTile" style={{marginRight: 20, position: "relative"}}>
                                    <div className="horizontalStack">

                                        <div className="verticalStack" style={{flex: 1}}>
                                            <div className="horizontalStack">
                                                <span className="fontPoppinsSemiBold15">{"Filter:"}</span>
                                                <span className="fontPoppinsSemiBold15Blue" style={{marginLeft: 5, maxWidth: "20ch", whiteSpace: "nowrap",textOverflow: "ellipsis", overflow: "hidden"}}>{this.getSelectedChurchCategoryName(this.state.selectedChurchCategory)}</span>
                                            </div>
                                            <div style={{height: "10px"}}></div>
                                            <div className="horizontalStack">
                                                <span className="fontPoppinsRegular13">{"Date: "}</span>
                                                <span className="fontPoppinsItalic13Blue" style={{marginLeft: 5}}><Moment format="MMM D, YYYY">{this.state.startDate}</Moment>{" - "}<Moment format="MMM D, YYYY">{this.state.endDate}</Moment></span>
                                            </div>
                                        </div>

                                        <div className="horizontalStackCenter">

                                            <div className="universalPopupSelectContainer" ref={this.refPopupChurchCategory}>

                                                <div className="universalPopupSelectVisible">
                                                    <RoundButton title={"Church Type"} onClick={() => this.setState({ showPopupChurchCategory: !this.state.showPopupChurchCategory })} />
                                                </div>

                                                <div className="universalPopupSelectHidden" style={{display: this.state.showPopupChurchCategory ? "" : "none", marginLeft: -158, marginTop: -43}}>
                                                    <div className="verticalStack" onClick={() => this.setState({ showPopupChurchCategory: false })}>
                                                        <span className="fontPoppinsItalic13Orange clickable">{"Select a Church Type"}</span>

                                                        <div style={{height: 1, backgroundColor: "var(--border-light-gray)", marginTop: 10, marginBottom: 10}}></div>

                                                        <div className="verticalStack">

                                                            {this.state.churchCategoriesArray.map(function (item) {
                                                                return (
                                                                    <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3}} onClick={() => this.setState({ selectedChurchCategory: item.id })}>
                                                                        <label for={item.id+""+item.name} className="fontPoppinsRegular13DarkGray clickable flex onHoverToOrange" style={{marginRight: 5}}>{item.name}</label>
                                                                        <input type="radio" className="clickable" checked={this.state.selectedChurchCategory == item.id ? true : false} />
                                                                    </div>
                                                                )
                                                            }.bind(this))}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{width: "20px"}}></div>

                                        <div className="horizontalStackCenter">

                                            <div className="universalPopupSelectContainer" ref={this.refPopupDatePicker}>
                                                <div className="universalPopupSelectVisible">
                                                    <RoundButton title={"Dates"} onClick={() => this.setState({ showPopupDatePicker: !this.state.showPopupDatePicker })} />
                                                </div>

                                                <div className="universalPopupSelectHidden" style={{display: this.state.showPopupDatePicker ? "" : "none", marginLeft: -61, marginTop: -43}}>
                                                    <div className="verticalStackCenter" style={{alignItems: "center"}}>
                                                        <span className="fontPoppinsItalic13Orange clickable" onClick={() => this.setState({ showPopupDatePicker: false })}>{"Filter by Dates"}</span>

                                                        <div style={{height: 1, backgroundColor: "var(--border-light-gray)", marginTop: 10, marginBottom: 10}}></div>

                                                        <span className="fontPoppinsMedium13Gray addNewButtonPeopleText">{"Select Start Date"}</span>
                                                        <input className="datePicker fontPoppinsMedium13Blue" type="date" min="1900-01-01" max="9999-01-01" defaultValue={this.state.startDate} onChange={(e) => this.setState({ startDate: e.target.value })} />
                                                        <div style={{height: 5}}></div>
                                                        <span className="fontPoppinsMedium13Gray addNewButtonPeopleText">{"- to -"}</span>
                                                        <div style={{height: 5}}></div>
                                                        <span className="fontPoppinsMedium13Gray addNewButtonPeopleText">{"Select End Date"}</span>
                                                        <input className="datePicker fontPoppinsMedium13Blue" type="date" min="1900-01-01" max="9999-01-01" defaultValue={this.state.endDate} onChange={(e) => this.setState({ endDate: e.target.value })} />

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <div style={{height: 20}}></div> */}

                                <div className="horizontalStack">

                                    <div className="universalTile flex verticalStack" style={{marginRight: 20}}>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsSemiBold15 responsiveThreeDots">{"Youth Groups"}</span>
                                        </div>

                                        <div style={{height: 10}}></div>

                                        <div style={{position: "relative"}}>
                                            <PieChart
                                                style={{height: 150}}
                                                lineWidth={35}
                                                paddingAngle={1}
                                                labelPosition={82}
                                                label={({ dataEntry }) => dataEntry.value}
                                                labelStyle={{fill: "white", fontFamily: "PoppinsMedium", fontSize: 6}}
                                                data={this.state.array_youth_groups}
                                            />

                                            <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)"}}>
                                                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                                    <img style={{width: "30px", height: "30px"}} src={icon_graph_youth_group}/>
                                                    <span className="fontPoppinsRegular27Orange">{this.state.count_youth_groups}</span>
                                                </div>
                                            </div>
                                        </div>

                                        {/*<div style={{height: 10}}></div>

                                        <div className="horizontalStack">
                                            <div className="flex"></div>
                                            <div className="verticalStackCenter" style={{flex: 3}}>
                                                {this.state.array_youth_groups.map(function (item) {
                                                    return (
                                                        <div className="horizontalStackCenter" style={{marginTop: 5}}>
                                                            */}{/* adding width: fit-content here to the responsiveThreeDotsContainer fixed that for Chrome but was not working for Safari */}
                                                            {/*<div className="responsiveThreeDotsContainer flex">
                                                                <div className="horizontalStackCenter" style={{alignItems: "center"}}>
                                                                    <div style={{minWidth: 15, width: 15, aspectRatio: "1/1", backgroundColor: item.color, borderRadius: 20}}></div>
                                                                    <span className="fontPoppinsMedium9 responsiveThreeDots" title={item.label} style={{marginLeft: 5}}>{item.label}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }.bind(this))}
                                            </div>
                                            <div className="flex"></div>
                                        </div>*/}

                                        <div style={{height: 10}}></div>

                                        <div className="horizontalStack">
                                            <div className="flex"></div>
                                            <div className="verticalStackCenter">
                                                {this.state.array_youth_groups.map(function (item) {
                                                    return (
                                                        <div className="horizontalStackCenter" style={{marginTop: 5}}>
                                                            <div className="horizontalStackCenter">
                                                                <div style={{minWidth: 15, width: 15, aspectRatio: "1/1", backgroundColor: item.color, borderRadius: 20}}></div>
                                                                <span className="fontPoppinsMedium9 " title={item.label} style={{marginLeft: 5}}>{item.label}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                }.bind(this))}
                                            </div>
                                            <div className="flex"></div>
                                        </div>
                                    </div>

                                    <div className="universalTile flex verticalStack" style={{marginRight: 20}}>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsSemiBold15 responsiveThreeDots">{"Youth Groups by Type"}</span>
                                        </div>

                                        <div style={{height: 10}}></div>

                                        <div style={{position: "relative"}}>
                                            <PieChart
                                                style={{height: 150}}
                                                lineWidth={35}
                                                paddingAngle={1}
                                                labelPosition={82}
                                                label={({ dataEntry }) => dataEntry.value}
                                                labelStyle={{fill: "white", fontFamily: "PoppinsMedium", fontSize: 6}}
                                                data={this.state.array_youth_group_by_type}
                                            />

                                            <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)"}}>
                                                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                                    <img style={{width: "30px", height: "30px"}} src={icon_graph_youth_group}/>
                                                    <span className="fontPoppinsRegular27Orange">{this.state.count_youth_group_by_type}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{height: 10}}></div>

                                        <div className="horizontalStack">
                                            <div className="flex"></div>
                                            <div className="verticalStackCenter">
                                                {this.state.array_youth_group_by_type.map(function (item) {
                                                    return (
                                                        <div className="horizontalStackCenter" style={{marginTop: 5}}>
                                                            <div className="horizontalStackCenter" style={{alignItems: "center"}}>
                                                                <div style={{minWidth: 15, width: 15, aspectRatio: "1/1", backgroundColor: item.color, borderRadius: 20}}></div>
                                                                <span className="fontPoppinsMedium9" title={item.team} style={{marginLeft: 5}}>{item.team}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                }.bind(this))}
                                            </div>
                                            <div className="flex"></div>
                                        </div>

                                    </div>

                                </div>

                                <div style={{height: 20}}></div>

                                <div className="horizontalStack">

                                    <div className="universalTile flex" style={{marginRight: 20, position: "relative"}}>
                                        <div className="verticalStack flex">
                                            <div className="horizontalStack" style={{alignItems: "start"}}>

                                                <div className="verticalStack">
                                                    <span className="fontPoppinsSemiBold15">{"Total Conversations by Type"}<span className="fontPoppinsSemiBold15Blue">{" - "}{this.state.filterConversationsContactName}</span></span>

                                                    <div className="horizontalStackCenter">
                                                        <img className="circleButton" style={{width: "27px", marginLeft: 20}} src={icon_conversations_gray}/>
                                                        <span className="fontPoppinsRegular27Orange" style={{marginLeft: 10}}>{this.state.totalConversationsByType}</span>
                                                    </div>
                                                </div>

                                                <div className="flex"></div>

                                                <div className="universalPopupSelectContainer" ref={this.refPopupFilterByWho}>

                                                    <div className="universalPopupSelectVisible ">
                                                        <RoundButton title={"Filter by Who"} onClick={() => this.setState({ showPopupFilterByWho: !this.state.showPopupFilterByWho })} />
                                                    </div>

                                                    <div className="universalPopupSelectHidden" style={{width: 300, display: this.state.showPopupFilterByWho ? "" : "none", marginLeft: -207, marginTop: -43}}>
                                                        <div className="verticalStack">
                                                            <div className="horizontalStackCenter">
                                                                <img style={{width: 16, height: 16, marginRight: 5}} src={icon_search}/>
                                                                <div className="horizontalStackCenter" style={{position: "relative"}}>
                                                                    <input className="fontPoppinsRegular13 searchBoxPlaceholder" type="text" required="required" ref={this.refInputFilterByWho} id="searchText" name="searchText" autoComplete="off" value={this.state.findContactByName} onChange={e => {this.setState({ findContactByName: e.target.value }); this.searchForContacts(e.target.value);}} style={{flex: 1, marginTop: 5, marginBottom: 5, paddingLeft: 0, border: "0px solid black"}}/>
                                                                    <label for={this.props.id} className="fontPoppinsRegular13Gray">Search<span className="fontPoppinsItalic13PurpleGray"> {"(Type 3+ Characters Here)"}</span></label>
                                                                </div>
                                                            </div>

                                                            {this.state.foundContacts.map(function (item, index) {
                                                                if (index > 10) { return; }
                                                                if (index == 10) { return ( <DropdownItem title={"And more..."} clickable={false} /> ) }
                                                                return (
                                                                    <DropdownItem title={item.first_name+" "+item.last_name+(item.email ? ", "+item.email : "")} onClick={() => {this.loadConversationsByType(item.id,0,item.first_name+" "+item.last_name);this.setState({ filterConversationsContactId: item.id });this.setState({ filterConversationsContactName: item.first_name+" "+item.last_name });} } clickable={true} />
                                                                )
                                                            }.bind(this))}

                                                            <div style={{height: 1, backgroundColor: "var(--border-light-gray)", marginTop: 5, marginBottom: 10, marginLeft: -20, marginRight: -20}}></div>

                                                            <span className="fontPoppinsRegular13DarkGray onHoverToOrange clickable" onClick={() => {this.loadConversationsByType(0,0,"All Conversations");}}>{"All Conversations"}</span>

                                                            <span className="fontPoppinsRegular13DarkGray onHoverToOrange clickable" onClick={() => {this.loadConversationsByType(0,1,"My Conversations");}}>{"My Conversations"}</span>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <BarChartTwo labels={this.state.conversations_by_type_labels} values={this.state.conversations_by_type_values} />

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
        );
    }
}

export default Dashboard;