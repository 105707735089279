import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { fetchContactImage } from '../utilities/OtherUtilities.js';

// import components
import QuickTableMobile from '../tiles_m/QuickTableMobile.js';

class QuickSearchMobile extends React.Component {
    setActualSection = this.props.setActualSection;

    constructor(props) {
        super(props);

        this.state = {
            resultData: ([]),
            totalNumberOfContacts: 0,
            noRecordsFound: false,
            numOfLoadedContacts: 20,
            showLoadingRow: false,
            search: "",
            sortBy: "", // name
            sortTp: "asc", // asc, desc
            dataAreLoadingNow: false,
        }
    }

    componentDidMount(){
        this.loadData();
        this.setActualSection("quickSearch");
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.searchText !== this.props.searchText) {
            this.setState({ actualPage: 0 });
            this.loadData();
        }
    }

    loadMoreData = async () => {
        // this condition should prevent to run this method more times in the same moment
        // second part of this condition should prevent to run this method when all records are already loaded
        if(!this.state.dataAreLoadingNow && (this.state.totalNumberOfContacts > this.state.numOfLoadedContacts)){
            await this.setState({ dataAreLoadingNow: true });
            await this.setState({ numOfLoadedContacts: this.state.numOfLoadedContacts+20 });
            this.loadData();
        }
    }

    loadData = () => {
        const { search } = Object.fromEntries(new URLSearchParams(window.location.search));
        if(search){
            this.setState({ search: search });
        }

        this.setState({ noRecordsFound: false });

        var url = '/api/cont/get?search='+search+'&limit='+this.state.numOfLoadedContacts+'&offset=0';
        //var url = '/api/cont/get?search='+search+'&limit='+this.state.recordsOnOnePage+'&offset='+(this.state.actualPage*this.state.recordsOnOnePage);
        if(this.state.sortBy != ""){
            url = url + "&sort_by="+this.state.sortBy+"&sort_tp="+this.state.sortTp;
        }

        Axios.get(url).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.setState({ resultData: response.data.result });
            this.setState({ totalNumberOfContacts: response.data.msg.cnt });
            if(response.data.result.length == 0){ this.setState({ noRecordsFound: true }); }

            this.state.resultData.forEach((contact) => this.handleGetContactImage(contact));

            this.setState({ dataAreLoadingNow: false });
            if(response.data.msg.cnt > this.state.numOfLoadedContacts) this.setState({ showLoadingRow: true });
            else this.setState({ showLoadingRow: false });
        });
    }

    handleGetContactImage = async (contact) => {
        const base64Image = await fetchContactImage(contact,"avatar");
        this.setState((prevState) => ({ resultData: prevState.resultData.map((u) => (u.id === contact.id ? { ...u, image: base64Image } : u)), }));
    };

    changeSorting = async (attribute) => {
        if(this.state.sortBy == attribute && this.state.sortTp == "asc") {
            await this.setState({ sortTp: "desc" });
        }
        else if(this.state.sortBy == attribute && this.state.sortTp == "desc") {
            await this.setState({ sortBy: "" });
            await this.setState({ sortTp: "desc" });
        }
        else {
            await this.setState({ sortBy: attribute });
            await this.setState({ sortTp: "asc" });
        }
        this.loadData();
    }

    render() {
        return (
            <div className="verticalStack flex" style={{height: "calc(var(--doc-height) - 80px)"}}>
                <QuickTableMobile resultData={this.state.resultData} leftMenuOpened={this.props.leftMenuOpened} openAlertMessage={this.props.openAlertMessage} loadMoreData={this.loadMoreData} showLoadingRow={this.state.showLoadingRow} changeSorting={this.changeSorting} sortBy={this.state.sortBy} sortTp={this.state.sortTp} loadData={this.loadData} allOrMy={"all"} noRecordsFound={this.state.noRecordsFound} />
            </div>
        );
    }
}

export default QuickSearchMobile;
