import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import { useLocation, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';

// import assets
import btn_back_gray from '../assets/btn_back_gray.png';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import MyConversationsTileMobile from '../tiles_m/MyConversationsTileMobile.js';

class MyConversationsMobile extends React.Component {
    setActualSection = this.props.setActualSection;

    constructor(props) {
        super(props);

        this.state = {
            conversationsData: ([]),
            totalNumberOfContacts: 0,
            noRecordsFound: false,
            numOfLoadedContacts: 20,
            showLoadingRow: false,
            contactId: -1,
            contactName: "Loading...",
            pathname: "",
            dataAreLoadingNow: false,
        }
    }

    componentDidMount(){
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.allOrMy !== this.props.allOrMy) {
            this.loadData();
        }
        if(this.state.pathname != this.props.location.pathname){
            this.setState({ contactId: -1 });
            this.setState({ contactName: "Loading..." });
            this.loadData();
        }
    }

    loadMoreData = async () => {
        // this condition should prevent to run this method more times in the same moment
        // second part of this condition should prevent to run this method when all records are already loaded
        if(!this.state.dataAreLoadingNow && (this.state.totalNumberOfContacts > this.state.numOfLoadedContacts)){
            await this.setState({ dataAreLoadingNow: true });
            await this.setState({ numOfLoadedContacts: this.state.numOfLoadedContacts+20 });
            this.loadData();
        }
    }

    loadData = () => {
        // I should use something like: my_conv=1
        this.setState({ noRecordsFound: false });

        // check if should be loading MY conversations or conversations of some contact
        const pathname = this.props.location.pathname;
        this.setState({ pathname: pathname });
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }

        var url = '/api/conv/get?my=1&limit='+this.state.numOfLoadedContacts+'&offset=0&search='+this.props.searchText;

        if(Number.isInteger(Number(id))){

            // check if loading church or person

            const { contTp } = Object.fromEntries(new URLSearchParams(window.location.search));
            var contTpLocal = 1;
            if(contTp){
                contTpLocal = contTp;
            }

            if(contTpLocal == 4){
                this.setActualSection("churches");
                url = '/api/conv/get?church_id='+id+'&limit='+this.state.numOfLoadedContacts+'&offset=0';
            }
            else if(contTpLocal == 7){
                this.setActualSection("schools");
                url = '/api/conv/get?cont_id='+id+'&limit='+this.state.numOfLoadedContacts+'&offset=0';
            }
            else {
                this.setActualSection("people");
                url = '/api/conv/get?cont_id='+id+'&limit='+this.state.numOfLoadedContacts+'&offset=0';
            }
            this.setState({ contactId: id });

            Axios.get('/api/cont/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }

                var contactName = "Loading...";
                if(response.data.result[0].first_name && response.data.result[0].last_name){
                    contactName = response.data.result[0].first_name+" "+response.data.result[0].last_name;
                }
                else if(response.data.result[0].short_name){
                    contactName = response.data.result[0].short_name;
                }

                contactName = contactName.toUpperCase();
                this.setState({ contactName: contactName });
            });
        }
        else {
            this.setActualSection("conversations");
        }

        Axios.get(url).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var improvedArray = [];
            for(let oneRow of response.data.result){
                var improvedObject = oneRow;
                oneRow.isOpen = false;
                improvedArray.push(improvedObject);
            }
            this.setState({ conversationsData: improvedArray });
            this.setState({ totalNumberOfContacts: response.data.msg.cnt });
            if(response.data.result.length == 0){ this.setState({ noRecordsFound: true }); }

            this.setState({ dataAreLoadingNow: false });
            if(response.data.msg.cnt > this.state.numOfLoadedContacts) this.setState({ showLoadingRow: true });
            else this.setState({ showLoadingRow: false });
        });
    }

    render() {
        return (
            <div className="verticalStack flex" style={{height: "calc(var(--doc-height) - 80px)"}}>
                <div className="horizontalStackCenter myPeopleTableListingBar">

                    {this.state.contactId == -1 ? <span className="fontPoppinsSemiBold13">{"MY CONVERSATIONS"}</span> : null }
                    {this.state.contactId == -1 ? <div className="flex"/> : null }

                    {this.state.contactId > 0 ?
                        <div className="horizontalStackCenter" style={{flex: 1}}>
                            <div className="horizontalStackCenter" onClick={() => navigate(-1)}>
                                <img src={btn_back_gray} style={{height: 14}} />
                                <span style={{marginLeft: 5, marginTop: 1}} className="fontPoppinsRegular13Gray">{"Return"}</span>
                            </div>
                            <div className="flex"></div>
                            <span className="fontPoppinsSemiBold13" style={{textAlign: "right"}}>{this.state.contactName}{"'S"}  {"CONVERSATIONS"}</span>
                        </div>
                    : null }
                </div>
                <MyConversationsTileMobile conversationsArray={this.state.conversationsData} leftMenuOpened={this.props.leftMenuOpened} openAlertMessage={this.props.openAlertMessage} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} getDataFromApi={this.loadData} loadMoreData={this.loadMoreData} showLoadingRow={this.state.showLoadingRow} noRecordsFound={this.state.noRecordsFound} />
            </div>
        );
    }
}

const withLocation = Component => props => {
    const location = useLocation();

    return <Component {...props} location={location} />;
};

export default withLocation(MyConversationsMobile);
