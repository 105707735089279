import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import Axios from 'axios';
import { navigate } from "@reach/router";

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { showNiceDateRange } from '../utilities/OtherUtilities.js';

// import assets
import icon_action_down from '../assets/icon_action_down.png';
import icon_expand from '../assets/icon_expand.png';
import icon_add_white from '../assets/icon_add_white.png';

// import components
import EditButtonDropdown from '../components/EditButtonDropdown.js';
import RoundButton from '../components/RoundButton.js';
import ExpandButton from '../components/ExpandButton.js';
import PopupButtonDescription from '../components/PopupButtonDescription.js';

class EventsTrainingsTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clickedEventTrainingId: -1,
            mainContactId: 0,
            mainContactName: "Error",
            /*eventsTrainingsList: ([{id: 1, evnt_nm: "Fusiondary 2022", area: "Evangelism", date: "June 8, 2020", location: "Vsetín, CZ", attendees_num: 192, email: "greg.strock@josiahventure.com", reg_email: "greg.strock@josiahventure.com", comment: "None", group: "Default", price: "+0.00 USD", birth_dt: "03.06.1988", gdpr: 1, isOpen: false},
                         {id: 2, evnt_nm: "Fusiondary 2023", area: "Evangelism", date: "June 8, 2020", location: "Frýdlant nad Ostravicí, CZ", attendees_num: 259, email: "jiri.nemec@josiahventure.com", reg_email: "greg.strock@josiahventure.com", comment: "None", group: "Default", price: "+0.00 USD", birth_dt: "03.06.1988", gdpr: 1, isOpen: false},
                         {id: 3, evnt_nm: "Fusiondary 2024", area: "Evangelism", date: "June 8, 2020", location: "Vsetín, CZ", attendees_num: 342, email: "mark.howland@josiahventure.com", reg_email: "greg.strock@josiahventure.com", comment: "None", group: "Default", price: "+0.00 USD", birth_dt: "03.06.1988", gdpr: 1, isOpen: false}]),*/
            eventsTrainingsList: ([]),
            expandAll: false,
            needUpdateVar: true, // I am saving here value from last updateVar I received and then in render if this variable changes from what I get from parent, then I update data
            showAll: false,
            noRecordsFound: false,
        }
    }

    componentDidMount(){
        this.loadData();
        this.setState({ needUpdateVar: this.props.updateVar });
    }

    loadData = () => {
        this.setState({ noRecordsFound: false });

        const pathname = window.location.href;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }

        if(Number.isInteger(Number(id))){ // if there is number received from URL

            // /api/evnt_part/get
            Axios.get('/api/evnt_part/get?cont_id='+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

                //alert(JSON.stringify(response.data.result[0]));

                var improvedArray = [];
                for(let oneRow of response.data.result){
                    var improvedObject = oneRow;

                    oneRow.isOpen = false;

                    improvedArray.push(improvedObject);
                }
                this.setState({ eventsTrainingsList: improvedArray });
                if(response.data.result.length == 0){ this.setState({ noRecordsFound: true }); }
            });

            this.setState({ mainContactId: id });

        }
    }

    openCloseEventTraining(idEventTraining){
        var newArray = this.state.eventsTrainingsList;
        for(let oneRow of newArray){
            if(oneRow.id == idEventTraining){
                oneRow.isOpen = !oneRow.isOpen;
            }
        }
        this.setState({ eventsTrainingsList: newArray})
    }

    openCloseTile = () => {
        if(this.props.isOpen){
            this.props.setActualOpenedTile("");
        }
        else {
            this.props.setActualOpenedTile("eventsTrainings");
        }
    }

    expandCollapseAll(){
        var newArray = this.state.eventsTrainingsList;
        for(let oneRow of newArray){
            if(this.state.expandAll){
                oneRow.isOpen = false;
            }
            else {
                oneRow.isOpen = true;
            }
        }
        this.setState({ eventsTrainingsList: newArray });
        this.setState({ expandAll: !this.state.expandAll });
    }

    openLink = (link) => {
        if(link && link.length > 0){
            link = link.match(/^https?:/) ? link : '//' + link;
            window.open(link, "_blank", "noreferrer");
        }
    }

    openEventProfile = (evnt_id) => {
        // check if this event exists in the CRM
        Axios.get('/api/evnt/get/'+evnt_id).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            if(response.data.result.length == 0){
                this.props.openAlertMessageTwoActions("This event is not imported in CRM or you don't have access to this event.","OK","",null);
            }
            else {
                //window.open("/EventProfile/"+evnt_id, '_blank', 'noreferrer');
                navigate("/EventProfile/"+evnt_id);
                window.location.reload();
            }
        });
    }

    render() {
        if(this.state.needUpdateVar != this.props.updateVar){
            this.setState({ needUpdateVar: this.props.updateVar });
            this.loadData();
        }
        return (
            <div className="conversationsTile">

                <div className="horizontalStack">

                    <ExpandButton isOpen={this.props.isOpen} style={{marginTop: 5}} onClick={this.openCloseTile} />

                    <div style={{width: "20px"}}></div>

                    <div className="verticalStack flex" style={{display: "table", tableLayout: "fixed", width: "100%"}}>
                        <span className="fontPoppinsSemiBold15">{"Events & Trainings"}</span>
                        <span className={this.props.isOpen ? "fontPoppinsRegular13Gray oneLineThreeDotsTextDeactivated" : "fontPoppinsRegular13Gray oneLineThreeDotsText"}>{"Use this tools for previewing the EMS data for event and training information that this person is either attending or helping lead."}</span>
                    </div>

                    <div style={{width: "20px"}}></div>

                    <div>
                        <div className="horizontalStackCenter universalPopupContainer" style={{marginTop: 5}} onClick={() => this.openLink("https://www.emsreg.eu/otm/events/new")}>
                            <div className="universalPopupVisible clickable">
                                <div className="circleButtonContainer circleButtonContainerOrange clickable">
                                    <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add_white}/>
                                </div>
                            </div>

                            <div className="universalPopupHidden clickable">
                                <PopupButtonDescription title={"Add in the EMS System"} from={"left"} marginLeft={"-209px"} blackArrow={true} />
                            </div>
                        </div>
                    </div>

                </div>

                <div className="verticalStackCenter" style={{display: this.props.isOpen ? "" : "none"}}>

                        {/* I am using here styles from Roles tile */}
                        <table className="rolesTable" style={{marginTop: 10}}>
                            <tr>
                                <td className="rolesTableHeader rolesTableHeaderOther clickable" nowrap="nowrap" onClick={() => this.expandCollapseAll()}>
                                    <img className={this.state.expandAll ? "expandButtonExpanded onHoverToOrange" : "expandButton onHoverToOrange"}  style={{width: "10px", height: "10px"}} src={icon_expand}/>
                                </td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Name</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Area</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">When</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Location</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Attendees</td>
                            </tr>

                            {this.state.eventsTrainingsList.map(function (item, index) {
                                    if (!this.state.showAll && index >= 5) { return; }
                                    return (
                                        <tbody>
                                        <tr className="clickable" onClick={() => this.openCloseEventTraining(item.id)} style={{backgroundColor: item.isOpen ? "var(--lightest-gray)" : ""}}>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther rolesTableRowExpandImage" nowrap="nowrap">
                                                <img className={item.isOpen ? "expandButtonExpanded" : "expandButton"}  style={{width: "10px", height: "10px"}} src={icon_expand}/>
                                            </td>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{item.evnt_nm}</td>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{item.area}</td>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{showNiceDateRange(item.from_dt, item.till_dt)}</td>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther responsiveTableDiv" nowrap="nowrap">{item.location}</td>
                                            <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap">{item.attn_cnt}</td>
                                        </tr>
                                        <div style={{display: item.isOpen ? "" : "none", height: 1}}></div>
                                        <tr style={{display: item.isOpen ? "" : "none", backgroundColor: "var(--lightest-gray)"}}>
                                            <td colSpan="6" style={{padding: 10}}>
                                                <div className="verticalStack">

                                                    <div className="verticalStack">
                                                        <span className="fontPoppinsRegular13Gray">{"Registered Attendee Names:"}</span>
                                                        <div className="horizontalStack">
                                                            <div className="responsiveThreeDotsContainer">
                                                                <span className="fontPoppinsRegular13 responsiveThreeDots">{item.attn_names}</span>
                                                            </div>
                                                            {/*<span className="fontPoppinsRegular13Orange" style={{whiteSpace: "nowrap", flex: 0}}>{"View All"}</span>*/}
                                                        </div>
                                                    </div>

                                                    <div style={{width: 20, height: 20}}></div>

                                                    <div className="horizontalStack">

                                                        <div className="verticalStack" style={{flex: 1.5}}>
                                                            <span className="fontPoppinsRegular13Gray">{"Churches Participating:"}</span>
                                                            <div className="horizontalStack">
                                                                <div className="responsiveThreeDotsContainer">
                                                                    { item.church_names ? <span className="fontPoppinsRegular13 responsiveThreeDots">{item.church_names}</span> : null }
                                                                    { !item.church_names ? <span className="fontPoppinsRegular13 responsiveThreeDots">{"No participating churches."}</span> : null }
                                                                </div>
                                                                {/*<span className="fontPoppinsRegular13Orange" style={{whiteSpace: "nowrap", flex: 0}}>{"View All"}</span>*/}
                                                            </div>
                                                        </div>

                                                        <div style={{width: 20, height: 20}}></div>

                                                        <div className="verticalStack" style={{flex: 1}}>
                                                            <span className="fontPoppinsRegular13Gray">{"Ministry:"}</span>
                                                            <div className="responsiveThreeDotsContainer">
                                                                <span className="fontPoppinsRegular13 responsiveThreeDots">{item.mnry}</span>
                                                            </div>
                                                        </div>

                                                        <div style={{width: 20, height: 20}}></div>

                                                        <div className="verticalStack" style={{flex: 1}}>
                                                            <div className="horizontalStack">
                                                                <span className="fontPoppinsRegular13Gray" style={{whiteSpace: "nowrap", marginRight: 5}}>{"Event Type:"}</span>
                                                                <div className="responsiveThreeDotsContainer">
                                                                    <span className="fontPoppinsRegular13 responsiveThreeDots">{item.evnt_tp}</span>
                                                                </div>
                                                            </div>
                                                            <div className="horizontalStack">
                                                                <span className="fontPoppinsRegular13Gray" style={{whiteSpace: "nowrap", marginRight: 5}}>{"Event Sub-Type:"}</span>
                                                                <div className="responsiveThreeDotsContainer">
                                                                    <span className="fontPoppinsRegular13 responsiveThreeDots">{item.evnt_subtp}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{width: 20, height: 20}}></div>

                                                    <div className="horizontalStack">

                                                        <div className="verticalStack" style={{flex: 1.5}}>
                                                            <div className="horizontalStack">
                                                                <span className="fontPoppinsRegular13Gray" style={{whiteSpace: "nowrap", marginRight: 5}}>{"Believers:"}</span>
                                                                <div className="responsiveThreeDotsContainer">
                                                                    <span className="fontPoppinsRegular13 responsiveThreeDots">{item.blv_cnt}</span>
                                                                </div>
                                                            </div>
                                                            <div className="horizontalStack">
                                                                <span className="fontPoppinsRegular13Gray" style={{whiteSpace: "nowrap", marginRight: 5}}>{"Unbelievers:"}</span>
                                                                <div className="responsiveThreeDotsContainer">
                                                                    <span className="fontPoppinsRegular13 responsiveThreeDots">{item.unb_cnt}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div style={{width: 20, height: 20}}></div>

                                                        <div className="verticalStack" style={{flex: 1}}>
                                                            <div className="horizontalStack">
                                                                <span className="fontPoppinsRegular13Gray" style={{whiteSpace: "nowrap", marginRight: 5}}>{"Professions of Faith:"}</span>
                                                                <div className="responsiveThreeDotsContainer">
                                                                    <span className="fontPoppinsRegular13 responsiveThreeDots">{item.pof_cnt}</span>
                                                                </div>
                                                            </div>
                                                            <div className="horizontalStack">
                                                                <span className="fontPoppinsRegular13Gray" style={{whiteSpace: "nowrap", marginRight: 5}}>{"Event ID Number:"}</span>
                                                                <div className="responsiveThreeDotsContainer">
                                                                    <span className="fontPoppinsRegular13 responsiveThreeDots">{item.evnt_id}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div style={{width: 20, height: 20}}></div>

                                                        <div className="horizontalStack" style={{flex: 1}}>
                                                            <div className="flex"></div>
                                                            { item.evnt_id ? <RoundButton title={"View Event Profile"} className={"roundButtonOrange"} style={{marginRight: "10px"}} whiteText={true} onClick={() => this.openEventProfile(item.evnt_id)} /> : null }
                                                        </div>

                                                    </div>


                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    )
                            }.bind(this))}

                            {/* if array empty but not because I received nothing from API then show row with text "Loading..." */}
                            {this.state.eventsTrainingsList.length == 0 && !this.state.noRecordsFound ? <tr> <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap" colspan="6" style={{textAlign: "center"}}>{"Loading..."}</td> </tr> : null}

                            {/* if array empty because I did not receive any records, then show row with text like "No Records Found" */}
                            {this.state.eventsTrainingsList.length == 0 && this.state.noRecordsFound ? <tr> <td className="rolesInTeamsTableRow rolesTableHeaderOther" nowrap="nowrap" colspan="6" style={{textAlign: "center"}}>{"No Events or Trainings Yet"}</td> </tr> : null}

                        </table>

                        <div className="horizontalStack">
                            <div className="flex"></div>
                            {this.state.eventsTrainingsList.length > 5 && !this.state.showAll ? <span className="fontPoppinsRegular13 clickable" style={{color: "var(--jv-orange)", whiteSpace: "nowrap", marginTop: 15}} onClick={() => this.setState({ showAll: !this.props.showAll })}>{"Show All"}</span> : null }
                            <div className="flex"></div>
                        </div>

                </div>

            </div>
        );
    }
}

export default EventsTrainingsTile;