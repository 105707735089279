import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import assets
import icon_expand from '../assets/icon_expand.png';
import icon_add_white from '../assets/icon_add_white.png';

// import components
import EditButtonDropdown from '../components/EditButtonDropdown.js';
import MinistryStatisticsModal from '../modals/MinistryStatisticsModal.js';
import ExpandButton from '../components/ExpandButton.js';

class MinistryStatisticsTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clickedStatisticsId: -1,
            mainContactId: 0,
            mainContactName: "Error",
            showModalAddStatistics: false,
            prepareToShowModalAddStatistics: false,
            showModalEditStatistics: false,
            prepareToShowModalEditStatistics: false,
            actualOpenedMinistry: "",
            /*statisticsList: ([{"id":1,"cont_id":30,"cont_nm":"SŽ Jihlava","team_cont_id":null,"team_cont_nm":null,"team_id":1,"team_nm":"Church","mnry_name":"Nedělní setkání","school_year_id":2,"school_year":"2022-2023","attn_cnt":25,"blv_cnt":8,"unb_cnt":20,"conv_cnt":0,"bapt_cnt":0,"is_crm":1},
                                {"id":1,"cont_id":30,"cont_nm":"SŽ Jihlava","team_cont_id":null,"team_cont_nm":null,"team_id":1,"team_nm":"Church","mnry_name":"Nedělní setkání","school_year_id":2,"school_year":"2022-2023","attn_cnt":25,"blv_cnt":8,"unb_cnt":20,"conv_cnt":0,"bapt_cnt":0,"is_crm":1},
                                {"id":1,"cont_id":30,"cont_nm":"SŽ Jihlava","team_cont_id":null,"team_cont_nm":null,"team_id":4,"team_nm":"Fusion","mnry_name":"Fusion Jihlava - Jody","school_year_id":2,"school_year":"2022-2023","attn_cnt":25,"blv_cnt":8,"unb_cnt":20,"conv_cnt":0,"bapt_cnt":0,"is_crm":1}]),*/
            statisticsList: ([]),
        }
    }

    componentDidMount(){
        this.getDataFromApi();
    }

    getDataFromApi = () => {
        const pathname = window.location.href;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }

        if(Number.isInteger(Number(id))){ // if there is number received from URL
            Axios.get('/api/mnry_stat/get?cont_id='+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ statisticsList: response.data.result })
            });
            Axios.get('/api/cont/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result[0].short_name){
                    this.setState({ mainContactName: response.data.result[0].short_name })
                }
            });
            this.setState({ mainContactId: id });
        }
        this.props.update();
    }

    openCloseMinistry(ministry){
        if(this.state.actualOpenedMinistry == ministry){ // if sending same ministry again it would close it
            this.setState({ actualOpenedMinistry: ""})
        }
        else {
            this.setState({ actualOpenedMinistry: ministry})
        }
    }

    onEdit = async (id) => {
        await this.setState({ clickedStatisticsId: id })
        this.openModal("EditStatistics");
    }

    onDelete = (id) => {
        Axios.delete('/api/mnry_stat/del/'+id, {
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.getDataFromApi();
        });
    }

    openModal = async (modalWindow) => {
        if(modalWindow == "AddStatistics"){
            await this.setState({ prepareToShowModalAddStatistics: true });
            this.setState({ showModalAddStatistics: true });
        }
        if(modalWindow == "EditStatistics"){
            await this.setState({ prepareToShowModalEditStatistics: true });
            this.setState({ showModalEditStatistics: true });
        }
    }

    closeModal = async (modalWindow, createAnotherNewAfter) => {
        // reload data
        this.getDataFromApi();

        // close the modal
        if(modalWindow == "AddStatistics"){
            this.setState({ showModalAddStatistics: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalAddStatistics: false });
        }
        if(modalWindow == "EditStatistics"){
            this.setState({ showModalEditStatistics: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalEditStatistics: false });
        }

        if(createAnotherNewAfter){
            await new Promise(resolve => setTimeout(resolve, 100));
            this.openModal("AddStatistics");
        }
    }

    onStatisticsClick = (id) => {
        //code
    }

    openCloseTile = () => {
        if(this.props.isOpen){
            this.props.setActualOpenedTile("");
        }
        else {
            this.props.setActualOpenedTile("statistics");
        }
    }

    render() {
        return (
            <div className="statisticsTile">

                <div className="horizontalStack">
                    <ExpandButton isOpen={this.props.isOpen} style={{marginTop: 5}} onClick={this.openCloseTile} />

                    <div style={{width: "20px"}}></div>

                    <div className="verticalStack flex" style={{display: "table", tableLayout: "fixed", width: "100%"}}>
                        <span className="fontPoppinsSemiBold15">{"Ministry Statistics"}</span>
                        <span className={this.props.isOpen ? "fontPoppinsRegular13Gray oneLineThreeDotsTextDeactivated" : "fontPoppinsRegular13Gray oneLineThreeDotsText"}>{"Use this tool for recording the sizes of the ministries and programs of the church. You can also record the estimated sizes of the church ministry as a whole. Please be sensetive in the way you ask for this."}</span>
                    </div>

                    <div style={{width: "20px"}}></div>

                    <div className="circleButtonContainer circleButtonContainerOrange clickable" style={{marginTop: 5}} onClick={() => this.openModal("AddStatistics")}>
                        <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add_white}/>
                    </div>

                </div>

                <div className="horizontalStackCenter" style={{tableLayout: "fixed", width: "100%", display: this.props.isOpen ? "table" : "none"}}>

                        <div style={{overflowX: "auto"}}>
                        <table className="statisticsTable" style={{marginTop: 10, width: "100%"}}>
                            <tr>
                                <td className="statisticsTableHeader statisticsTableHeaderOther statisticsTableFirstFixedColumnHeader" nowrap="nowrap"></td>
                                <td className="statisticsTableHeader statisticsTableHeaderOther statisticsTableSecondFixedColumnHeader" nowrap="nowrap">Ministry/Program</td>
                                <td className="statisticsTableHeader statisticsTableHeaderOther" nowrap="nowrap">School Year</td>
                                <td className="statisticsTableHeader statisticsTableHeaderOther" nowrap="nowrap">Attendance</td>
                                <td className="statisticsTableHeader statisticsTableHeaderOther" nowrap="nowrap">Believers</td>
                                <td className="statisticsTableHeader statisticsTableHeaderOther" nowrap="nowrap">Unbelievers</td>
                                <td className="statisticsTableHeader statisticsTableHeaderOther" nowrap="nowrap">Conversions</td>
                                <td className="statisticsTableHeader statisticsTableHeaderOther" nowrap="nowrap">Baptisms</td>
                                <td className="statisticsTableHeader statisticsTableHeaderOther" nowrap="nowrap">Edit</td>
                            </tr>

                            {/* CHURCH */}

                            <tr className="clickable" onClick={() => this.openCloseMinistry("church")}  style={{backgroundColor: this.state.actualOpenedMinistry == "church" ? "var(--lightest-gray)" : ""}}>
                                {this.state.statisticsList.filter(statistics => statistics.team_id == 1).length != 0 ? <td className="statisticsTableRow statisticsTableHeaderOther statisticsTableRowExpandImage statisticsTableFirstFixedColumn" nowrap="nowrap"> <img className={this.state.actualOpenedMinistry == "church" ? "expandButtonExpanded" : "expandButton"} style={{width: "10px", height: "10px"}} src={icon_expand}/> </td> : null }
                                {this.state.statisticsList.filter(statistics => statistics.team_id == 1).length == 0 ? <td className="statisticsTableRow statisticsTableHeaderOther statisticsTableRowExpandImage statisticsTableFirstFixedColumn" nowrap="nowrap"> </td> : null}

                                {this.state.statisticsList.filter(statistics => statistics.team_id == 1).length != 0 ? <td className="statisticsTableRow statisticsTableHeaderOther statisticsTableSecondFixedColumn" nowrap="nowrap">Church Size</td> : null}
                                {this.state.statisticsList.filter(statistics => statistics.team_id == 1).length == 0 ? <td className="statisticsTableRow statisticsTableHeaderOther statisticsTableSecondFixedColumn" nowrap="nowrap">Church Size (No Statistics Yet)</td> : null}
                                <td className="statisticsTableRow statisticsTableHeaderOther" nowrap="nowrap"></td>
                                <td className="statisticsTableRow statisticsTableHeaderOther" nowrap="nowrap"></td>
                                <td className="statisticsTableRow statisticsTableHeaderOther" nowrap="nowrap"></td>
                                <td className="statisticsTableRow statisticsTableHeaderOther" nowrap="nowrap"></td>
                                <td className="statisticsTableRow statisticsTableHeaderOther" nowrap="nowrap"></td>
                                <td className="statisticsTableRow statisticsTableHeaderOther" nowrap="nowrap"></td>
                                <td className="statisticsTableRow statisticsTableHeaderOther" nowrap="nowrap"></td>
                            </tr>

                            {this.state.statisticsList.filter(statistics => statistics.team_id == 1).map(function (item) {
                                return (
                                    <tr key={item.id} onClick={() => this.onStatisticsClick(item.id)} style={{display: this.state.actualOpenedMinistry == "church" ? "" : "none", backgroundColor: "var(--lightest-gray)"}}>
                                        <td className="statisticsTableGeneralDiv statisticsTableFirstFixedColumn" nowrap="nowrap"></td>
                                        <td className="statisticsTableGeneralDiv statisticsTableSecondFixedColumn" nowrap="nowrap" style={{maxWidth: 200, overflow: "hidden", textOverflow: "ellipsis"}}>{item.mnry_name}</td>
                                        <td className="statisticsTableGeneralDiv" nowrap="nowrap">{item.school_year}</td>
                                        <td className="statisticsTableGeneralDiv" nowrap="nowrap">{item.attn_cnt}</td>
                                        <td className="statisticsTableGeneralDiv" nowrap="nowrap">{item.blv_cnt}</td>
                                        <td className="statisticsTableGeneralDiv" nowrap="nowrap">{item.unb_cnt}</td>
                                        <td className="statisticsTableGeneralDiv" nowrap="nowrap">{item.conv_cnt}</td>
                                        <td className="statisticsTableGeneralDiv" nowrap="nowrap">{item.bapt_cnt}</td>
                                        <td className="statisticsTableGeneralDiv" nowrap="nowrap">
                                            {item.is_crm == 1 ? <EditButtonDropdown itemId={item.id} onEdit={this.onEdit} onDelete={this.onDelete} text={"Are you sure want to delete this statistics?"}/> : null }
                                        </td>
                                    </tr>
                                )
                            }.bind(this))}

                            {/* ENTIRE YOUTH MINISTRY */}

                            <tr className="clickable" onClick={() => this.openCloseMinistry("entire_youth_ministry")} style={{backgroundColor: this.state.actualOpenedMinistry == "entire_youth_ministry" ? "var(--lightest-gray)" : ""}}>
                                {this.state.statisticsList.filter(function(statistics){if(statistics.team_id == 2 || statistics.team_id == 3 || statistics.team_id == 4 || statistics.team_id == 5 || statistics.team_id == 7 || statistics.team_id == 8){return true;}else{return false;}}).length != 0 ? <td className="statisticsTableRow statisticsTableHeaderOther statisticsTableRowExpandImage statisticsTableFirstFixedColumn" nowrap="nowrap"> <img className={this.state.actualOpenedMinistry == "entire_youth_ministry" ? "expandButtonExpanded" : "expandButton"} style={{width: "10px", height: "10px"}} src={icon_expand}/> </td> : null }
                                {this.state.statisticsList.filter(function(statistics){if(statistics.team_id == 2 || statistics.team_id == 3 || statistics.team_id == 4 || statistics.team_id == 5 || statistics.team_id == 7 || statistics.team_id == 8){return true;}else{return false;}}).length == 0 ? <td className="statisticsTableRow statisticsTableHeaderOther statisticsTableRowExpandImage statisticsTableFirstFixedColumn" nowrap="nowrap"> </td> : null }

                                {this.state.statisticsList.filter(function(statistics){if(statistics.team_id == 2 || statistics.team_id == 3 || statistics.team_id == 4 || statistics.team_id == 5 || statistics.team_id == 7 || statistics.team_id == 8){return true;}else{return false;}}).length != 0 ? <td className="statisticsTableRow statisticsTableHeaderOther statisticsTableSecondFixedColumn" nowrap="nowrap">Youth Ministry Sizes</td> : null}
                                {this.state.statisticsList.filter(function(statistics){if(statistics.team_id == 2 || statistics.team_id == 3 || statistics.team_id == 4 || statistics.team_id == 5 || statistics.team_id == 7 || statistics.team_id == 8){return true;}else{return false;}}).length == 0 ? <td className="statisticsTableRow statisticsTableHeaderOther statisticsTableSecondFixedColumn" nowrap="nowrap">Youth Ministry Sizes (No Statistics Yet)</td> : null}

                                <td className="statisticsTableRow statisticsTableHeaderOther" nowrap="nowrap"></td>
                                <td className="statisticsTableRow statisticsTableHeaderOther" nowrap="nowrap"></td>
                                <td className="statisticsTableRow statisticsTableHeaderOther" nowrap="nowrap"></td>
                                <td className="statisticsTableRow statisticsTableHeaderOther" nowrap="nowrap"></td>
                                <td className="statisticsTableRow statisticsTableHeaderOther" nowrap="nowrap"></td>
                                <td className="statisticsTableRow statisticsTableHeaderOther" nowrap="nowrap"></td>
                                <td className="statisticsTableRow statisticsTableHeaderOther" nowrap="nowrap"></td>
                            </tr>

                            {this.state.statisticsList.filter(function(statistics){if(statistics.team_id == 2 || statistics.team_id == 3 || statistics.team_id == 4 || statistics.team_id == 5 || statistics.team_id == 7 || statistics.team_id == 8){return true;}else{return false;}}).map(function (item) {
                                return (
                                    <tr key={item.id} onClick={() => this.onStatisticsClick(item.id)} style={{display: this.state.actualOpenedMinistry == "entire_youth_ministry" ? "" : "none", backgroundColor: "var(--lightest-gray)"}}>
                                        <td className="statisticsTableGeneralDiv statisticsTableFirstFixedColumn" nowrap="nowrap"></td>
                                        <td className="statisticsTableGeneralDiv statisticsTableSecondFixedColumn" nowrap="nowrap" style={{maxWidth: 200, overflow: "hidden", textOverflow: "ellipsis"}}>{item.mnry_name}</td>
                                        <td className="statisticsTableGeneralDiv" nowrap="nowrap">{item.school_year}</td>
                                        <td className="statisticsTableGeneralDiv" nowrap="nowrap">{item.attn_cnt}</td>
                                        <td className="statisticsTableGeneralDiv" nowrap="nowrap">{item.blv_cnt}</td>
                                        <td className="statisticsTableGeneralDiv" nowrap="nowrap">{item.unb_cnt}</td>
                                        <td className="statisticsTableGeneralDiv" nowrap="nowrap">{item.conv_cnt}</td>
                                        <td className="statisticsTableGeneralDiv" nowrap="nowrap">{item.bapt_cnt}</td>
                                        <td className="statisticsTableGeneralDiv" nowrap="nowrap">
                                            {item.is_crm == 1 ? <EditButtonDropdown itemId={item.id} onEdit={this.onEdit} onDelete={this.onDelete} text={"Are you sure want to delete this statistics?"}/> : null }
                                        </td>
                                    </tr>
                                )
                            }.bind(this))}

                            {/* YOUTH GROUP MEETINGS */}

                            <tr className="clickable" onClick={() => this.openCloseMinistry("youth_group_meetings")} style={{backgroundColor: this.state.actualOpenedMinistry == "youth_group_meetings" ? "var(--lightest-gray)" : ""}}>
                                {this.state.statisticsList.filter(statistics => statistics.team_id == 6).length != 0 ? <td className="statisticsTableRow statisticsTableHeaderOther statisticsTableRowExpandImage statisticsTableFirstFixedColumn" nowrap="nowrap"> <img className={this.state.actualOpenedMinistry == "youth_group_meetings" ? "expandButtonExpanded" : "expandButton"} style={{width: "10px", height: "10px"}} src={icon_expand}/> </td> : null }
                                {this.state.statisticsList.filter(statistics => statistics.team_id == 6).length == 0 ? <td className="statisticsTableRow statisticsTableHeaderOther statisticsTableRowExpandImage statisticsTableFirstFixedColumn" nowrap="nowrap"> </td> : null}

                                {this.state.statisticsList.filter(statistics => statistics.team_id == 6).length != 0 ? <td className="statisticsTableRow statisticsTableHeaderOther statisticsTableSecondFixedColumn" nowrap="nowrap">Small Groups</td> : null}
                                {this.state.statisticsList.filter(statistics => statistics.team_id == 6).length == 0 ? <td className="statisticsTableRow statisticsTableHeaderOther statisticsTableSecondFixedColumn" nowrap="nowrap">Small Groups (No Statistics Yet)</td> : null}

                                <td className="statisticsTableRow statisticsTableHeaderOther" nowrap="nowrap"></td>
                                <td className="statisticsTableRow statisticsTableHeaderOther" nowrap="nowrap"></td>
                                <td className="statisticsTableRow statisticsTableHeaderOther" nowrap="nowrap"></td>
                                <td className="statisticsTableRow statisticsTableHeaderOther" nowrap="nowrap"></td>
                                <td className="statisticsTableRow statisticsTableHeaderOther" nowrap="nowrap"></td>
                                <td className="statisticsTableRow statisticsTableHeaderOther" nowrap="nowrap"></td>
                                <td className="statisticsTableRow statisticsTableHeaderOther" nowrap="nowrap"></td>
                            </tr>

                            {this.state.statisticsList.filter(statistics => statistics.team_id == 6).map(function (item) {
                                return (
                                    <tr key={item.id} onClick={() => this.onStatisticsClick(item.id)} style={{display: this.state.actualOpenedMinistry == "youth_group_meetings" ? "" : "none", backgroundColor: "var(--lightest-gray)"}}>
                                        <td className="statisticsTableGeneralDiv statisticsTableFirstFixedColumn" nowrap="nowrap"></td>
                                        <td className="statisticsTableGeneralDiv statisticsTableSecondFixedColumn" nowrap="nowrap" style={{maxWidth: 200, overflow: "hidden", textOverflow: "ellipsis"}}>{item.mnry_name}</td>
                                        <td className="statisticsTableGeneralDiv" nowrap="nowrap">{item.school_year}</td>
                                        <td className="statisticsTableGeneralDiv" nowrap="nowrap">{item.attn_cnt}</td>
                                        <td className="statisticsTableGeneralDiv" nowrap="nowrap">{item.blv_cnt}</td>
                                        <td className="statisticsTableGeneralDiv" nowrap="nowrap">{item.unb_cnt}</td>
                                        <td className="statisticsTableGeneralDiv" nowrap="nowrap">{item.conv_cnt}</td>
                                        <td className="statisticsTableGeneralDiv" nowrap="nowrap">{item.bapt_cnt}</td>
                                        <td className="statisticsTableGeneralDiv" nowrap="nowrap">
                                            {item.is_crm == 1 ? <EditButtonDropdown itemId={item.id} onEdit={this.onEdit} onDelete={this.onDelete} text={"Are you sure want to delete this statistics?"}/> : null }
                                        </td>
                                    </tr>
                                )
                            }.bind(this))}

                        </table>
                        </div>

                </div>

                {/* I am hiding and showing it by this IF because when I used just showModal variable it did not update on change */}
                {this.state.prepareToShowModalAddStatistics ? <MinistryStatisticsModal showModal={this.state.showModalAddStatistics} closeModal={this.closeModal} openModal={this.openModal} contactId={this.state.mainContactId} contactName={this.state.mainContactName} actionType={"AddStatistics"} statisticsId={-1} openAlertMessage={this.props.openAlertMessage} /> : null }
                {this.state.prepareToShowModalEditStatistics ? <MinistryStatisticsModal showModal={this.state.showModalEditStatistics} closeModal={this.closeModal} openModal={this.openModal} contactId={this.state.mainContactId} contactName={this.state.mainContactName} actionType={"EditStatistics"} statisticsId={this.state.clickedStatisticsId} openAlertMessage={this.props.openAlertMessage} /> : null }

            </div>
        );
    }
}

export default MinistryStatisticsTile;
