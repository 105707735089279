import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import Axios from 'axios';
import CsvDownloadButton from 'react-json-to-csv';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import utilities
import { moreRecordsOnPageUtilities, lessRecordsOnPageUtilities, goToPrevPageUtilities, goToNextPageUtilities, goToFirstPageUtilities, goToLastPageUtilities, checkIfOutOfPages } from '../utilities/PagingUtilities.js';
import { fetchContactImage } from '../utilities/OtherUtilities.js';

// import components
import MyPeopleTile from '../tiles/MyPeopleTile.js';
import MyChurchesTile from '../tiles/MyChurchesTile.js';
import MySchoolsTile from '../tiles/MySchoolsTile.js';
import AdvancedTable from '../tiles/AdvancedTable.js';

function storageEventHandler() {
    this.setState({ recordsOnOnePage: localStorage.getItem("recordsOnOnePageAdvSearch") || 25 });
    this.setState({ actualPage: localStorage.getItem("actualPageAdvSearch") || 0 });
    this.setState({ sortBy: localStorage.getItem("sortByAdvSearch") || 0 });
    this.setState({ sortTp: localStorage.getItem("sortTpAdvSearch") || 0 });
}

class AdvancedSearch extends React.Component {
    setActualSection = this.props.setActualSection;

    constructor(props) {
        super(props);

        this.state = {
            resultData: ([]),
            /*resultData: ([{id: 3, first_name:"Jiří", last_name: "Němec", role: "Youth Leader", email: "jnemec@cbbrno.com", phone: "184 637 823", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add", avatar: require("../assets/person_avatar.jpeg")},
                            {id: 3, first_name:"Petr", last_name: "Říha", role: "Youth Leader", email: "jnemec@cbbrno.com", phone: "184 637 823", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add", avatar: require("../assets/person_avatar.jpeg")},
                            {id: 3, first_name:"Jiří", last_name: "Němec", role: "Youth Leader", email: "jnemec@cbbrno.com", phone: "184 637 823", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add", avatar: require("../assets/person_avatar.jpeg")}]),*/
            recordsOnOnePage: localStorage.getItem("recordsOnOnePageAdvSearch") || 25, // number of records showing on one page, 25 is default
            totalNumberOfContacts: 0,
            noRecordsFound: false,
            adv_tp: 0,
            actualPage: localStorage.getItem("actualPageAdvSearch") || 0, // starts with pageNumber=0, then 1, 2, 3,...
            sortBy: localStorage.getItem("sortByAdvSearch") || "", // name
            sortTp: localStorage.getItem("sortTpAdvSearch") || "asc", // asc, desc
        }
    }

    componentDidMount(){
        this.loadData();
        window.addEventListener('storage', storageEventHandler, false);
        this.setActualSection("advancedSearch");
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.searchText !== this.props.searchText) {
            //this.setState({ actualPage: 0 });
            //localStorage.setItem("actualPageAdvSearch", 0);
            //this.loadData();
        }
    }

    loadData = () => {
        // if user lowers the rowsPerPage, goes next page and then highers the rowsPerPage it will take him to the last page
        if(checkIfOutOfPages(this.state.actualPage, this.state.recordsOnOnePage, this.state.totalNumberOfContacts)){
            this.goToLastPage();
            return;
        }

        this.setState({ noRecordsFound: false });
        this.setState({ resultData: [] });
        this.setState({ totalNumberOfContacts: 0 });

        /*var getOnlyMyContacts = this.props.searchText == "" ? 1 : 0; // If I am searching it would search in all, if not it gives me my contacts
        Axios.get('/api/cont/get?tp=1&my='+getOnlyMyContacts+'&limit='+this.state.recordsOnOnePage+'&offset='+(this.state.actualPage*this.state.recordsOnOnePage)+'&search='+this.props.searchText).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.setState({ resultData: response.data.result })
        });*/

        const { adv_op, adv_nm, adv_tp, adv_deno, adv_dsc, adv_ldr, adv_mph, adv_jve, adv_city, adv_cntry, adv_reg, adv_role, adv_team, adv_rel_a, adv_rel_b, way, adv_evnt_nm, adv_area, adv_nt_org, adv_mnry, adv_evnt_bef, adv_evnt_aft, adv_evnt_tp, adv_evnt_subtp } = Object.fromEntries(new URLSearchParams(window.location.search));
        if(adv_tp){
            this.setState({ adv_tp: adv_tp });
        }

        var fixable_adv_op = adv_op;
        if(adv_op != "and" && adv_op != "or"){ fixable_adv_op = "and"; }

        var url = '/api/cont/get?adv_op='+fixable_adv_op+'&adv_nm='+adv_nm+'&way='+way+'&adv_city='+adv_city+'&adv_tp='+adv_tp+'&adv_deno='+adv_deno+'&adv_dsc='+adv_dsc+'&adv_ldr='+adv_ldr+'&adv_mph='+adv_mph+'&adv_jve='+adv_jve+'&adv_cntry='+adv_cntry+'&adv_reg='+adv_reg+'&adv_role='+adv_role+'&adv_team='+adv_team+'&adv_rel_a='+adv_rel_a+'&adv_rel_b='+adv_rel_b+'&adv_evnt_nm='+adv_evnt_nm+'&adv_area='+adv_area+'&adv_nt_org='+adv_nt_org+'&adv_mnry='+adv_mnry+'&adv_evnt_bef='+adv_evnt_bef+'&adv_evnt_aft='+adv_evnt_aft+'&adv_evnt_tp='+adv_evnt_tp+'&adv_evnt_subtp='+adv_evnt_subtp+'&limit='+this.state.recordsOnOnePage+'&offset='+(this.state.actualPage*this.state.recordsOnOnePage);
        if(this.state.sortBy != ""){
            url = url + "&sort_by="+this.state.sortBy+"&sort_tp="+this.state.sortTp;
        }

        Axios.get(url).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            this.setState({ resultData: response.data.result });
            this.setState({ totalNumberOfContacts: response.data.msg.cnt });
            if(response.data.result.length == 0){ this.setState({ noRecordsFound: true }); }

            this.state.resultData.forEach((contact) => this.handleGetContactImage(contact));

            // save the ids array to memory
            var idsArray = response.data.result.map(a => a.id);
            localStorage.setItem('idsArray', JSON.stringify(idsArray));
        });

    }

    handleGetContactImage = async (contact) => {
        const base64Image = await fetchContactImage(contact,"avatar");
        this.setState((prevState) => ({ resultData: prevState.resultData.map((u) => (u.id === contact.id ? { ...u, image: base64Image } : u)), }));
    };

    goToFirstPage = async () => {
        await this.setState({ actualPage: goToFirstPageUtilities(this.state.actualPage, "actualPageAdvSearch") });
        this.loadData();
    }

    goToPrevPage = async () => {
        await this.setState({ actualPage: goToPrevPageUtilities(this.state.actualPage, "actualPageAdvSearch") });
        this.loadData();
    }

    goToNextPage = async () => {
        await this.setState({ actualPage: goToNextPageUtilities(this.state.actualPage, this.state.recordsOnOnePage, this.state.totalNumberOfContacts, "actualPageAdvSearch") });
        this.loadData();
    }

    goToLastPage = async () => {
        await this.setState({ actualPage: goToLastPageUtilities(this.state.recordsOnOnePage, this.state.totalNumberOfContacts, "actualPageAdvSearch") });
        this.loadData();
    }

    moreRecordsOnPage = async () => {
        await this.setState({ recordsOnOnePage: moreRecordsOnPageUtilities(this.state.recordsOnOnePage, "recordsOnOnePageAdvSearch") });
        this.loadData();
    }

    lessRecordsOnPage = async () => {
        await this.setState({ recordsOnOnePage: lessRecordsOnPageUtilities(this.state.recordsOnOnePage, "recordsOnOnePageAdvSearch") });
        this.loadData();
    }

    changeSorting = async (attribute) => {
        this.goToFirstPage();
        if(this.state.sortBy == attribute && this.state.sortTp == "asc") {
            localStorage.setItem("sortTpAdvSearch", "desc");
            await this.setState({ sortTp: "desc" });
        }
        else if(this.state.sortBy == attribute && this.state.sortTp == "desc") {
            localStorage.setItem("sortByAdvSearch", "");
            localStorage.setItem("sortTpAdvSearch", "desc");
            await this.setState({ sortBy: "" });
            await this.setState({ sortTp: "desc" });
        }
        else {
            localStorage.setItem("sortByAdvSearch", attribute);
            localStorage.setItem("sortTpAdvSearch", "asc");
            await this.setState({ sortBy: attribute });
            await this.setState({ sortTp: "asc" });
        }
        this.loadData();
    }

    render() {
        return (
            <div className="verticalStack flex" style={{height: "calc(100vh - 80px)"}}>
                <div className="horizontalStackCenter myPeopleTableListingBar">
                    <span onClick={this.goToFirstPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange">{"<<"}</span>
                    <span onClick={this.goToPrevPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange" style={{paddingLeft: "20px"}}>{"<"}</span>
                    <span className="fontPoppinsRegular13Gray" style={{paddingLeft: "20px", paddingRight: "12px"}}>{"Advanced Search List"}</span>
                    <span className="fontPoppinsSemiBold16">{(this.state.actualPage*this.state.recordsOnOnePage)+1}{"-"}{(this.state.actualPage+1)*this.state.recordsOnOnePage > this.state.totalNumberOfContacts ? this.state.totalNumberOfContacts : (this.state.actualPage+1)*this.state.recordsOnOnePage }</span>
                    <span className="fontPoppinsRegular13Gray" style={{paddingLeft: "6px", paddingRight: "6px"}}>{"of"}</span>
                    <span className="fontPoppinsSemiBold16">{this.state.totalNumberOfContacts}</span>
                    <span onClick={this.goToNextPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange" style={{paddingLeft: "20px", paddingRight: "20px"}}>{">"}</span>
                    <span onClick={this.goToLastPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange">{">>"}</span>
                    <div className="flex"/>
                    <CsvDownloadButton data={this.state.resultData} filename={"export_advanced_search.csv"} className="fontPoppinsRegular13Gray clickable onHoverToOrange" style={{backgroundColor: "transparent", border: "none", paddingRight: "30px"}}>Export CSV</CsvDownloadButton>
                    <span className="fontPoppinsRegular13Gray">{"Rows Per page:"}</span>
                    <span className="fontPoppinsSemiBold16" style={{paddingLeft: "10px", paddingRight: "10px"}}>{this.state.recordsOnOnePage}</span>
                    <div className="verticalStackCenter">
                        <div onClick={this.moreRecordsOnPage} className="clickable buttonPagingUp"></div>
                        <div style={{height: "3px"}}></div>
                        <div onClick={this.lessRecordsOnPage} className="clickable buttonPagingDown"></div>
                    </div>
                </div>
                { this.state.adv_tp == 1 ? <MyPeopleTile peopleArray={this.state.resultData} leftMenuOpened={this.props.leftMenuOpened} openAlertMessage={this.props.openAlertMessage} changeSorting={this.changeSorting} sortBy={this.state.sortBy} sortTp={this.state.sortTp} loadData={this.loadData} allOrMy={"all"} /> : null }
                { this.state.adv_tp == 4 ? <MyChurchesTile churchesArray={this.state.resultData} leftMenuOpened={this.props.leftMenuOpened} openAlertMessage={this.props.openAlertMessage} changeSorting={this.changeSorting} sortBy={this.state.sortBy} sortTp={this.state.sortTp} loadData={this.loadData} allOrMy={"all"} /> : null }
                { this.state.adv_tp == 7 ? <MySchoolsTile schoolsArray={this.state.resultData} leftMenuOpened={this.props.leftMenuOpened} openAlertMessage={this.props.openAlertMessage} changeSorting={this.changeSorting} sortBy={this.state.sortBy} sortTp={this.state.sortTp} loadData={this.loadData} allOrMy={"all"} /> : null }
                { this.state.adv_tp != 1 && this.state.adv_tp != 4 && this.state.adv_tp != 7 ? <AdvancedTable resultData={this.state.resultData} leftMenuOpened={this.props.leftMenuOpened} openAlertMessage={this.props.openAlertMessage} changeSorting={this.changeSorting} sortBy={this.state.sortBy} sortTp={this.state.sortTp} loadData={this.loadData} allOrMy={"all"} noRecordsFound={this.state.noRecordsFound} /> : null }
            </div>
        );
    }
}

export default AdvancedSearch;
