import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import assets
import icon_action_down from '../assets/icon_action_down.png';
import icon_add_white from '../assets/icon_add_white.png';

// import components
import EditButtonDropdown from '../components/EditButtonDropdown.js';
import RolesModal from '../modals/RolesModal.js';
import ExpandButton from '../components/ExpandButton.js';

class RolesTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clickedRoleId: -1,
            mainContactId: 0,
            mainContactName: "Error",
            showModalAddRole: false,
            prepareToShowModalAddRole: false,
            showModalEditRole: false,
            prepareToShowModalEditRole: false,
            /*rolesList: ([{id: 1, role:"Cared for by", team: "Youth Group Meetings", church: "CB Brno", staff: true, yrs: 7, descr: "Lorem Ipsum...", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add"},
                                {id: 2, role:"Disciplee", team: "Youth Group Meetings", church: "CB Brno", staff: false, yrs: 10, descr: "Lorem Ipsum...", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add"},
                                {id: 6, role:"Coache", team: "Youth Group Meetings", church: "CB Brno", staff: true, yrs: 4, descr: "Lorem Ipsum...", church: "CB Brno", address: "Brno, Czech Republic", action: "select/add"}]),*/
            rolesList: ([]),
            needUpdateVar: true, // I am saving here value from last updateVar I received and then in render if this variable changes from what I get from parent, then I update data
            noRecordsFound: false,
        }
    }

    componentDidMount(){
        this.loadData();
        this.setState({ needUpdateVar: this.props.updateVar });
    }

    loadData = () => {
        this.setState({ noRecordsFound: false });

        const pathname = window.location.href;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }

        if(Number.isInteger(Number(id))){ // if there is number received from URL
            Axios.get('/api/rel/get?cont_id='+id+'&a_tp=1&b_tp=4').then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ rolesList: response.data.result })
                if(response.data.result.length == 0){ this.setState({ noRecordsFound: true }); }
            });
            Axios.get('/api/cont/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result[0].first_name && response.data.result[0].last_name){
                    this.setState({ mainContactName: response.data.result[0].first_name+" "+response.data.result[0].last_name })
                }
            });
            this.setState({ mainContactId: id });
        }
    }

    onEdit = async (id) => {
        await this.setState({ clickedRoleId: id })
        this.openModal("EditRole");
    }

    onDelete = (id) => {
        Axios.delete('/api/rel/del/'+id, {
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.loadData();
            this.props.updateChurchInfoTile();
            this.props.updateDiagnosticTile();
            this.props.updateProfileTile();
        });
    }

    openModal = async (modalWindow) => {
        if(modalWindow == "AddRole"){
            await this.setState({ prepareToShowModalAddRole: true });
            this.setState({ showModalAddRole: true });
        }
        if(modalWindow == "EditRole"){
            await this.setState({ prepareToShowModalEditRole: true });
            this.setState({ showModalEditRole: true });
        }
    }

    closeModal = async (modalWindow, createAnotherNewAfter) => {
        // reload data
        this.loadData();
        this.props.updateChurchInfoTile();
        this.props.updateDiagnosticTile();
        this.props.updateProfileTile();

        // close the modal
        if(modalWindow == "AddRole"){
            this.setState({ showModalAddRole: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalAddRole: false });
        }
        if(modalWindow == "EditRole"){
            this.setState({ showModalEditRole: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setState({ prepareToShowModalEditRole: false });
        }

        if(createAnotherNewAfter){
            await new Promise(resolve => setTimeout(resolve, 100));
            this.openModal("AddRole");
        }
    }

    onRoleClick = (id) => {
        //code
    }

    openCloseTile = () => {
        if(this.props.isOpen){
            this.props.setActualOpenedTile("");
        }
        else {
            this.props.setActualOpenedTile("roles");
        }
    }

    selectNewPrimary = (id) => {
        Axios.post('/api/rel/prim/'+id, {
            A_ContId: this.state.mainContactId,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.loadData();
        }).then((response) => {
            this.props.updateChurchInfoTile();
            this.props.updateDiagnosticTile();
            this.props.updateProfileTile();
        });
    }

    render() {
        if(this.state.needUpdateVar != this.props.updateVar){
            this.setState({ needUpdateVar: this.props.updateVar });
            this.loadData();
        }
        return (
            <div className="rolesTile">

                <div className="horizontalStack">

                    <ExpandButton isOpen={this.props.isOpen} style={{marginTop: 5}} onClick={this.openCloseTile} />

                    <div style={{width: "20px"}}></div>

                    <div className="verticalStack flex" style={{display: "table", tableLayout: "fixed", width: "100%"}}>
                        <span className="fontPoppinsSemiBold15">{"Roles"}</span>
                        <span className={this.props.isOpen ? "fontPoppinsRegular13Gray oneLineThreeDotsTextDeactivated" : "fontPoppinsRegular13Gray oneLineThreeDotsText"}>{"Use this tool for remembering different people's roles in their ministry and how long they have been serving in them. The italicized text indicates a past role that they are no longer serving in."}</span>
                    </div>

                    <div style={{width: "20px"}}></div>

                    <div className="circleButtonContainer circleButtonContainerOrange clickable" style={{marginTop: 5}} onClick={() => this.openModal("AddRole")}>
                        <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add_white}/>
                    </div>

                </div>

                <div className="horizontalStackCenter" style={{display: this.props.isOpen ? "" : "none"}}>

                        <table className="rolesTable" style={{marginTop: 10}}>
                            <tr>
                                {/* I dont understand why is here the first column, so I commented that cause the Relationship tile had too big width to fit there */}
                                {/*<td className="rolesTableHeader rolesTableAction">
                                    <div className="onHoverToOrange">
                                        <img style={{width: "10px", height: "6.3px"}} src={icon_action_down}/>
                                    </div>
                                </td>*/}
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Role</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Where</td>
                                <td className="rolesTableHeader rolesTableIsPrimary" nowrap="nowrap">Primary</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Approx. Duration</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Description</td>
                                <td className="rolesTableHeader rolesTableHeaderOther" nowrap="nowrap">Edit</td>
                            </tr>

                            {this.state.rolesList.map(function (item) {
                                return (
                                    <tr key={item.id} onClick={() => this.onRoleClick(item.id)}>

                                        {/*<td className="rolesTableAction"></td>*/}

                                        <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.role}</td>
                                        <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.team} - {item.church}</td>
                                        <td>
                                            <div className="horizontalStack">
                                                <div className="flex"/>
                                                <input type="radio" checked={item.is_primary == 1 ? "checked" : ""} onClick={() => this.selectNewPrimary(item.id)}/>
                                                <div className="flex"/>
                                            </div>
                                        </td>
                                        <td className="rolesTableGeneralDiv" nowrap="nowrap">{item.yrs} {"years..."}</td>
                                        <td className="rolesTableGeneralDiv responsiveTableDiv" nowrap="nowrap">{item.descr}</td>
                                        <td className="rolesTableGeneralDiv" nowrap="nowrap">
                                            <EditButtonDropdown itemId={item.id} onEdit={this.onEdit} onDelete={this.onDelete} text={"Are you sure want to delete this role?"}/>
                                        </td>
                                    </tr>
                                )
                            }.bind(this))}

                            {/* if array empty but not because I received nothing from API then show row with text "Loading..." */}
                            {this.state.rolesList.length == 0 && !this.state.noRecordsFound ? <tr> <td className="rolesTableGeneralDiv" nowrap="nowrap" colspan="6" style={{textAlign: "center", padding: 10}}>{"Loading..."}</td> </tr> : null}

                            {/* if array empty because I did not receive any records, then show row with text like "No Records Found" */}
                            {this.state.rolesList.length == 0 && this.state.noRecordsFound ? <tr> <td className="rolesTableGeneralDiv" nowrap="nowrap" colspan="6" style={{textAlign: "center", padding: 10}}>{"No Roles Yet"}</td> </tr> : null}

                        </table>

                </div>

                {this.state.prepareToShowModalAddRole ? <RolesModal showModal={this.state.showModalAddRole} closeModal={this.closeModal} openModal={this.openModal} contactId={this.state.mainContactId} contactName={this.state.mainContactName} actionType={"AddRole"} roleId={-1} openAlertMessage={this.props.openAlertMessage} /> : null }
                {this.state.prepareToShowModalEditRole ? <RolesModal showModal={this.state.showModalEditRole} closeModal={this.closeModal} openModal={this.openModal} contactId={this.state.mainContactId} contactName={this.state.mainContactName} actionType={"EditRole"} roleId={this.state.clickedRoleId} openAlertMessage={this.props.openAlertMessage} /> : null }

            </div>
        );
    }
}

export default RolesTile;
