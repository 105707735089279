import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/menus.css';
import Axios from 'axios';
import { Link, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";

// import components
import RoundButton from '../components/RoundButton.js';
import SearchBox from '../components/SearchBox.js';
import GrayTextButton from '../components/GrayTextButton.js';
import ConversationsModal from '../modals/ConversationsModal.js';
import RolesModal from '../modals/RolesModal.js';
import RelationshipsModal from '../modals/RelationshipsModal.js';
import ObservationsModal from '../modals/ObservationsModal.js';
import RolesInTeamsModal from '../modals/RolesInTeamsModal.js';
import ChangePasswordModal from '../modals/ChangePasswordModal.js';
import MinistryStatisticsModal from '../modals/MinistryStatisticsModal.js';
import ProfileImageSmall from '../components/ProfileImageSmall.js';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import assets
import person_avatar from '../assets/person_avatar.png';
import icon_add from '../assets/icon_add.png';
import icon_expand from '../assets/icon_expand.png';
import icon_close_gray from '../assets/close_gray.png';

class TopMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user_id: 0,
            first_name: "",
            last_name: "",
            adv_op: "and",
            adv_nm: "", // name or email
            adv_city: "", // city
            adv_tp: "", // contact type
            adv_deno: "", // denomination
            adv_cntry: "", // country
            adv_reg: "", // region
            adv_role: "", // role
            adv_team: "", // team
            adv_dsc: "", // Five Challenges
            adv_ldr: "", // Leadership Type
            adv_mph: "", // Ministry Phases
            adv_jve: "", // JV Engagement
            adv_rel_a: 0, // relationship type a_b
            adv_rel_b: 0, // relationship type b_a
            contactTypeArray: ([{id: 0, name: ""}]),
            denominationArray: ([{id: 0, name: ""}]),
            countryArray: ([{id: 0, name: ""}]),
            permCountryArray: ([{id: 0, name: ""}]),
            roleTypeArray: ([{id: 0, name: ""}]),
            teamTypeArray: ([{id: 0, name: ""}]),
            regionArray: ([{id: 0, name: ""}]),
            // diagnostic lens tools section
            relationshipTypeArray: ([{id: 0, name: ""}]),
            fiveChallengesArray: ([{id: 0, name: ""}]),
            leadershipTypeArray: ([{id: 0, name: ""}]),
            ministryPhasesArray: ([{id: 0, name: ""}]),
            jvEngagementArray: ([{id: 0, name: ""}]),
            // event section
            adv_evnt_nm: "", // event name
            adv_area: "", // event area
            adv_nt_org: "", // event national organization
            adv_mnry: "", // event ministry type
            adv_evnt_bef: "", // event Before Date YYYY-MM-DD
            adv_evnt_aft: "", // event After Date YYYY-MM-DD
            adv_evnt_tp: "", // event type
            adv_evnt_subtp: "", // event sub-type
            areaTypeArray: ([{id: 0, name: ""}]),
            nationalOrganizationsArray: ([{id: 0, name: ""}]),
            ministryTypeArray: ([{id: 0, name: ""}]),
            eventTypeArray: ([{id: 0, name: ""}]),
            eventSubTypeArray: ([{id: 0, name: ""}]),
            // other variables
            showModalAddConversation: false,
            prepareToShowModalAddConversation: false,
            showModalAddRole: false,
            prepareToShowModalAddRole: false,
            showModalAddRelationship: false,
            prepareToShowModalAddRelationship: false,
            showModalAddObservation: false,
            prepareToShowModalAddObservation: false,
            showModalAddRoleInTeam: false,
            prepareToShowModalAddRoleInTeam: false,
            showModalAddStatistics: false,
            prepareToShowModalAddStatistics: false,
            userIsAdmin: 0,
            addNewKeepShow: false,
            way: 2,
            linkUrl: "", // ./NewContactPerson
            uploadedImageBase64: "",
            diagnosticToolsIsOpen: false,
            eventsIsOpen: false,
            showChangeCountryPopup: false,
        };

        this.refLink = React.createRef();

    }

    logoutUser = () => {
        localStorage.removeItem("leftMenuOpened");
        localStorage.setItem('token', null);
        window.location.reload();
    }

    saveWay = (value) => {
        localStorage.setItem('adv_search_way', value);
        this.setState({ way: value });
    }

    componentDidMount(){
        var token_object = JSON.parse(localStorage.getItem("token"));
        //var saved_way = localStorage.getItem("adv_search_way");
        //this.setState({ way: saved_way || 1 });
        this.setState({ user_id: token_object.id || 0 });
        this.setState({ first_name: token_object.first_name || "" });
        this.setState({ last_name: token_object.last_name || "" });
        this.loadData();
    }

    loadData = async () => {
        await this.loadRoleAndRelationshipsTypes();
        await this.loadTeamTypes();
        await this.loadContactTypes();
        await this.loadCountries();
        await this.loadPermCountries();
        await this.loadDenominations();
        await this.loadRegionsForCountry(0);
        await this.loadFiveChallengesTypes();
        await this.loadLeadershipTypes();
        await this.loadMinistryPhasesTypes();
        await this.loadJVEngagementTypes();
        await this.loadAreaTypes();
        await this.loadNationalOrganizationsArray();
        await this.loadMinistryTypes();
        await this.loadEventTypes();
        await this.loadEventSubTypes();

        // load data
        const { adv_op, adv_nm, adv_tp, adv_deno, adv_dsc, adv_ldr, adv_mph, adv_jve, adv_city, adv_cntry, adv_reg, adv_role, adv_team, adv_rel_a, adv_rel_b, diagnosticToolsIsOpen, eventsIsOpen, adv_evnt_nm, adv_area, adv_nt_org, adv_mnry, adv_evnt_bef, adv_evnt_aft, adv_evnt_tp, adv_evnt_subtp } = Object.fromEntries(new URLSearchParams(window.location.search));

        var fixable_adv_op = adv_op;
        if(adv_op != "and" && adv_op != "or"){ fixable_adv_op = "and"; }
        this.setState({ adv_op: fixable_adv_op });
        this.setState({ adv_nm: adv_nm });
        this.setState({ adv_city: adv_city });
        this.setState({ adv_tp: adv_tp });
        this.setState({ adv_deno: adv_deno });
        this.setState({ adv_dsc: adv_dsc });
        this.setState({ adv_ldr: adv_ldr });
        this.setState({ adv_mph: adv_mph });
        this.setState({ adv_jve: adv_jve });
        this.setState({ adv_cntry: adv_cntry });
        this.setState({ adv_reg: adv_reg });
        this.setState({ adv_role: adv_role });
        this.setState({ adv_team: adv_team });
        this.setState({ adv_rel_a: adv_rel_a });
        this.setState({ adv_rel_b: adv_rel_b });
        this.setState({ diagnosticToolsIsOpen: diagnosticToolsIsOpen == "1" ? true : false });
        this.setState({ eventsIsOpen: eventsIsOpen == "1" ? true : false });
        this.setState({ adv_evnt_nm: adv_evnt_nm });
        this.setState({ adv_area: adv_area });
        this.setState({ adv_nt_org: adv_nt_org });
        this.setState({ adv_mnry: adv_mnry });
        this.setState({ adv_evnt_bef: adv_evnt_bef });
        this.setState({ adv_evnt_aft: adv_evnt_aft });
        this.setState({ adv_evnt_tp: adv_evnt_tp });
        this.setState({ adv_evnt_subtp: adv_evnt_subtp });


        try {
            var token_object = JSON.parse(localStorage.getItem("token"));
            this.setState({ userIsAdmin: token_object.is_admin });
        }
        catch(error){
            this.setState({ userIsAdmin: 0 });
        }

        try {
            var cont_id = token_object.cont_id;
            if(Number.isInteger(Number(cont_id))){
                // get avatar image of contact
                Axios.get('/api/file/load?file_prefix=avatar&cont_id='+cont_id).then(response => {
                    if(response.data.stat && response.data.stat == "ERR"){
                        // avatar image was not loaded
                    }
                    else this.setState({ uploadedImageBase64: response.data });
                });
            }
        }
        catch(error){
            // error
        }
    }

    loadContactTypes = () => {
        Axios.get('/api/ctp/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var contactTypesArray = [];
            for(let oneRow of response.data.result){ contactTypesArray.push({"id":oneRow.id, "name": oneRow.label}); }
            this.setState({ contactTypeArray: contactTypesArray });
        });
    }

    loadCountries = () => {
        Axios.get('/api/cntry/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var countriesArray = [];
            for(let oneRow of response.data.result){ countriesArray.push({"id":oneRow.id, "name": oneRow.label}); }
            this.setState({ countryArray: countriesArray });
        });
    }

    loadPermCountries = () => {
        Axios.get('/api/perm_cntry/get?is_jv=1').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var permCountriesArray = [];
            permCountriesArray.push({"id": 1, "name": "Default"});
            for(let oneRow of response.data.result){ permCountriesArray.push({"id":oneRow.id, "name": oneRow.label}); }
            this.setState({ permCountryArray: permCountriesArray });
        });
    }

    loadRegionsForCountry = (id) => {
       // var countryId = this.state.adv_cntry;
        var countryId = id;
        var url = "/api/region/get/";
        if(countryId != 0 && countryId > 0){url = "/api/region/get?cntry_id="+countryId;}

        Axios.get(url).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var regionsArray = [];
            for(let oneRow of response.data.result){ regionsArray.push({"id":oneRow.id, "name": oneRow.descr}); }
            this.setState({ regionArray: regionsArray });
        });
    }

    loadDenominations = () => {
        Axios.get('/api/cont/get?tp=5').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var denominationsArray = [];
            for(let oneRow of response.data.result){ denominationsArray.push({"id":oneRow.id, "name": oneRow.org_name}); }
            this.setState({ denominationArray: denominationsArray });
        });
    }

    loadTeamTypes = () => {
        Axios.get('/api/team/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var teamsArray = [];
            for(let oneRow of response.data.result){ teamsArray.push({"id":oneRow.id, "name": oneRow.label}); }
            this.setState({ teamTypeArray: teamsArray });
        });
    }

    loadFiveChallengesTypes = () => {
        Axios.get('/api/seg/get?segm_id=1').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var workArray = [];
            for(let oneRow of response.data.result){ workArray.push({"id":oneRow.id, "name": oneRow.short_label + " - " + oneRow.label}); }
            this.setState({ fiveChallengesArray: workArray });
        });
    }

    loadLeadershipTypes = () => {
        Axios.get('/api/seg/get?segm_id=3').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var workArray = [];
            for(let oneRow of response.data.result){ workArray.push({"id":oneRow.id, "name": oneRow.short_label + " - " + oneRow.label}); }
            this.setState({ leadershipTypeArray: workArray });
        });
    }

    loadMinistryPhasesTypes = () => {
        Axios.get('/api/seg/get?segm_id=5').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var workArray = [];
            for(let oneRow of response.data.result){ workArray.push({"id":oneRow.id, "name": oneRow.short_label + " - " + oneRow.descr}); }
            this.setState({ ministryPhasesArray: workArray });
        });
    }

    loadJVEngagementTypes = () => {
        Axios.get('/api/seg/get?segm_id=8').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var workArray = [];
            for(let oneRow of response.data.result){ workArray.push({"id":oneRow.id, "name": oneRow.short_label + " - " + oneRow.label}); }
            this.setState({ jvEngagementArray: workArray });
        });
    }

    loadRoleAndRelationshipsTypes = () => {
        Axios.get('/api/rtp/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var results = response.data.result;

            var relationshipArray = [];
            var rolesArray = [];

            for(let oneRow of results){
                if(oneRow.a_cont_tp_id == 1 && oneRow.b_cont_tp_id == 1){
                    if(oneRow.a_b_name == oneRow.b_a_name){
                        relationshipArray.push({"id":oneRow.id, "name": oneRow.a_b_name, "type": "both"});
                    }
                    else {
                        relationshipArray.push({"id":oneRow.id, "name": oneRow.a_b_name, "type": "a_b"});
                        relationshipArray.push({"id":oneRow.id, "name": oneRow.b_a_name, "type": "b_a"});
                    }
                }
                if(oneRow.a_cont_tp_id == 1 && oneRow.b_cont_tp_id == 4){
                    rolesArray.push({"id":oneRow.id, "name": oneRow.a_b_name});
                }
            }

            this.setState({ roleTypeArray: rolesArray });
            this.setState({ relationshipTypeArray: relationshipArray });
        });
    }

    loadAreaTypes = () => {
        Axios.get('/api/area/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var workArray = [];
            for(let oneRow of response.data.result){ workArray.push({"id":oneRow.id, "name": oneRow.label}); }
            this.setState({ areaTypeArray: workArray });
        });
    }

    loadNationalOrganizationsArray = () => {
        Axios.get('/api/nt_org/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var workArray = [];
            for(let oneRow of response.data.result){ workArray.push({"id":oneRow.id, "name": oneRow.label}); }
            this.setState({ nationalOrganizationsArray: workArray });
        });
    }

    loadMinistryTypes = () => {
        Axios.get('/api/mnry/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var workArray = [];
            for(let oneRow of response.data.result){ workArray.push({"id":oneRow.id, "name": oneRow.label}); }
            this.setState({ ministryTypeArray: workArray });
        });
    }

    loadEventTypes = () => {
        Axios.get('/api/evnt_tp/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var workArray = [];
            for(let oneRow of response.data.result){ workArray.push({"id":oneRow.id, "name": oneRow.label}); }
            this.setState({ eventTypeArray: workArray });
        });
    }

    loadEventSubTypes = () => {
        Axios.get('/api/evnt_subtp/get').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            var workArray = [];
            for(let oneRow of response.data.result){ workArray.push({"id":oneRow.id, "name": oneRow.label}); }
            this.setState({ eventSubTypeArray: workArray });
        });
    }

    actionQuickSearch = async () => {
        await this.props.setTopMenuExpanded(false);
        await this.props.setActualSection("quickSearch");
        var searchText = this.props.searchText;
        if(searchText == undefined || searchText == "")return;
        // set actualPage=0 and delete saved sorting from past
        localStorage.setItem("actualPageQuickSearch", 0);
        localStorage.setItem("sortByQuickSearch", "");
        localStorage.setItem("sortTpQuickSearch", "asc");
        navigate("/QuickSearch?search="+searchText);
        window.location.reload();
    }

    actionSearch = async () => {
        localStorage.setItem("actualPageAdvSearch", 0);
        localStorage.setItem("sortByAdvSearch", "");
        localStorage.setItem("sortTpAdvSearch", "asc");
        await this.props.setTopMenuExpanded(false);
        //await this.props.setSearchAdvanced({searchingOption: this.state.adv_op, nameOrEmail: this.state.adv_nm, typeOfContact_id: this.state.adv_tp, denomination_id: this.state.adv_deno, city: this.state.adv_city, country_id: this.state.adv_cntry, jvRegion_id: this.state.adv_reg, role_id: this.state.adv_role, team_id: this.state.adv_team, relationshipType_id: this.state.adv_rel});
        await this.props.setActualSection("advancedSearch");
        window.location.reload();
    }

    actionClearAll = () => {
        this.setState({ adv_nm: "" });
        this.setState({ adv_city: "" });
        this.setState({ adv_tp: "" });
        this.setState({ adv_deno: "" });
        this.setState({ adv_dsc: "" });
        this.setState({ adv_ldr: "" });
        this.setState({ adv_mph: "" });
        this.setState({ adv_jve: "" });
        this.setState({ adv_cntry: "" });
        this.setState({ adv_reg: "" });
        this.setState({ adv_role: "" });
        this.setState({ adv_team: "" });
        this.setState({ adv_rel_a: 0 });
        this.setState({ adv_rel_b: 0 });
        this.setState({ adv_op: "and" });
        this.setState({ adv_evnt_nm: "" });
        this.setState({ adv_area: "" });
        this.setState({ adv_nt_org: "" });
        this.setState({ adv_mnry: "" });
        this.setState({ adv_evnt_bef: "" });
        this.setState({ adv_evnt_aft: "" });
        this.setState({ adv_evnt_tp: "" });
        this.setState({ adv_evnt_subtp: "" });
    }

    actionCancel = () => {
        this.props.setTopMenuExpanded(false);
    }

    openModal = async (modalWindow) => {
        if(modalWindow == "AddConversation"){
            await this.setState({ prepareToShowModalAddConversation: true });
            this.setState({ showModalAddConversation: true });
        }
        else if(modalWindow == "AddRole"){
            await this.setState({ prepareToShowModalAddRole: true });
            this.setState({ showModalAddRole: true });
        }
        else if(modalWindow == "AddRelationship"){
            await this.setState({ prepareToShowModalAddRelationship: true });
            this.setState({ showModalAddRelationship: true });
        }
        else if(modalWindow == "AddObservation"){
            await this.setState({ prepareToShowModalAddObservation: true });
            this.setState({ showModalAddObservation: true });
        }
        else if(modalWindow == "AddRoleInTeam"){
            await this.setState({ prepareToShowModalAddRoleInTeam: true });
            this.setState({ showModalAddRoleInTeam: true });
        }
        else if(modalWindow == "ChangePassword"){
            await this.setState({ prepareToShowModalChangePassword: true });
            this.setState({ showModalChangePassword: true });
        }
        else if(modalWindow == "AddStatistics"){
            await this.setState({ prepareToShowModalAddStatistics: true });
            this.setState({ showModalAddStatistics: true });
        }
    }

    closeModal = async (modalWindow) => {
        if(modalWindow == "AddConversation"){
            this.setState({ showModalAddConversation: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalAddConversation: false });
        }
        else if(modalWindow == "AddRole"){
            this.setState({ showModalAddRole: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalAddRole: false });
        }
        else if(modalWindow == "AddRelationship"){
            this.setState({ showModalAddRelationship: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalAddRelationship: false });
        }
        else if(modalWindow == "AddObservation"){
            this.setState({ showModalAddObservation: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalAddObservation: false });
        }
        else if(modalWindow == "AddRoleInTeam"){
            this.setState({ showModalAddRoleInTeam: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalAddRoleInTeam: false });
        }
        else if(modalWindow == "ChangePassword"){
            this.setState({ showModalChangePassword: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalChangePassword: false });
        }
        else if(modalWindow == "AddStatistics"){
            this.setState({ showModalAddStatistics: false });
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.setState({ prepareToShowModalAddStatistics: false });
        }
    }

    saveRelationshipSelection = (itemType) => {
        if(itemType == ""){
            this.setState({ adv_rel_a: 0 });
            this.setState({ adv_rel_b: 0 });
            return;
        }
        for(let oneRow of this.state.relationshipTypeArray){
            if(itemType == (oneRow.id+"."+oneRow.type)){
                if(oneRow.type == "a_b"){
                    this.setState({ adv_rel_a: oneRow.id });
                    this.setState({ adv_rel_b: 0 });
                }
                else if(oneRow.type == "b_a"){
                    this.setState({ adv_rel_a: 0 });
                    this.setState({ adv_rel_b: oneRow.id });
                }
                else {
                    this.setState({ adv_rel_a: oneRow.id });
                    this.setState({ adv_rel_b: oneRow.id });
                }
            }
        }
    }

    isRelationshipSelected = (id, type) => {
        if(type == "a_b" && id == this.state.adv_rel_a){
            return true;
        }
        else if(type == "b_a" && id == this.state.adv_rel_b){
            return true;
        }
        else if(type == "both" && id == this.state.adv_rel_a){
            return true;
        }
        else {
            return false;
        }
    }

    onEnterSearch = (e) => {
        if(e.key === 'Enter'){
            this.actionSearch();
            var url = "/AdvancedSearch?adv_op="+this.state.adv_op+"&adv_nm="+(this.state.adv_nm == undefined ? "" : this.state.adv_nm)+"&adv_city="+(this.state.adv_city == undefined ? "" : this.state.adv_city)+"&adv_tp="+this.state.adv_tp+"&adv_deno="+this.state.adv_deno+"&adv_cntry="+this.state.adv_cntry+"&adv_dsc="+this.state.adv_dsc+"&adv_ldr="+this.state.adv_ldr+"&adv_mph="+this.state.adv_mph+"&adv_jve="+this.state.adv_jve+"&adv_reg="+this.state.adv_reg+"&adv_role="+this.state.adv_role+"&adv_team="+this.state.adv_team+"&adv_rel_a="+this.state.adv_rel_a+"&adv_rel_b="+this.state.adv_rel_b+"&way=1&diagnosticToolsIsOpen="+(this.state.diagnosticToolsIsOpen ? "1" : "0")+"&eventsIsOpen="+(this.state.eventsIsOpen ? "1" : "0")+"&adv_evnt_nm="+(this.state.adv_evnt_nm == undefined ? "" : this.state.adv_evnt_nm)+"&adv_area="+this.state.adv_area+"&adv_nt_org="+this.state.adv_nt_org+"&adv_mnry="+this.state.adv_mnry+"&adv_evnt_tp="+this.state.adv_evnt_tp+"&adv_evnt_subtp="+this.state.adv_evnt_subtp+"&adv_evnt_bef="+(this.state.adv_evnt_bef == undefined ? "" : this.state.adv_evnt_bef)+"&adv_evnt_aft="+(this.state.adv_evnt_aft == undefined ? "" : this.state.adv_evnt_aft);
            navigate(url);
        }
    }

    viewEditProfile = async () => {
        if(this.state.user_id == 0){
            this.props.openAlertMessageTwoActions("Some error occurred.","OK","",null);
            return;
        }

        // get cont_id from user_id
        Axios.get('/api/usr/get/'+this.state.user_id).then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }

            if(response.data.result[0].cont_id != undefined){
                this.clickLink("./PeopleProfile/"+response.data.result[0].cont_id);
            }
            else {
                this.props.openAlertMessageTwoActions("Some error occurred.","OK","",null);
            }
        });
    }

    clickLink = async (url) => {
        await this.setState({ linkUrl: url });
        this.refLink.current.click();
    }

    changeCrntCntry = (item) => {
        localStorage.setItem('crnt_cntry', item.id);
        window.location.reload(true);
    }

    render(){
        return(
            <div className="verticalStack">
                <Link ref={this.refLink} to={this.state.linkUrl}></Link>
                <div className="mainTopMenu horizontalStackCenter">
                        <SearchBox searchText={this.props.searchText} setSearchText={this.props.setSearchText} onEnter={this.actionQuickSearch} setActualSection={this.props.setActualSection} way={this.state.way} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} />

                        {/*<span className="fontPoppinsRegular13Gray clickable" style={{marginLeft: "18px", whiteSpace: "nowrap", color: this.state.way == 1 ? "var(--jv-orange)" : ""}} onClick={() => this.saveWay(1)}>{"Global"}</span>
                        <span className="fontPoppinsRegular13Gray" style={{whiteSpace: "nowrap"}} onClick={() => this.props.setTopMenuExpanded(!this.props.topMenuExpanded)}>{"/"}</span>
                        <span className="fontPoppinsRegular13Gray clickable" style={{whiteSpace: "nowrap", color: this.state.way != 1 ? "var(--jv-orange)" : ""}} onClick={() => this.saveWay(0)}>{"Quick"}</span>*/}

                        <GrayTextButton title={"Advanced Search"} style={{marginLeft: "18px", marginRight: "9px", whiteSpace: "nowrap"}} onClick={() => this.props.setTopMenuExpanded(!this.props.topMenuExpanded)} />
                        <img className="clickable onHoverToOrange" style={{width: 20, display: this.props.topMenuExpanded ? "" : "none"}} onClick={() => this.props.setTopMenuExpanded(!this.props.topMenuExpanded)} src={icon_close_gray}/>

                        <div className="flex"/>

                        <div className="horizontalStackCenter addNewButtonDropdownContainer" style={{marginRight: "23px"}}>
                            <div className="addNewButtonDropdownVisible" style={{zIndex: 13}}> {/* I use addNewButtonDropdownVisible on another places but here I need to be even higher in zLevel */}
                                <RoundButton title={"Add New"} icon={icon_add} onClick={() => this.setState({ addNewKeepShow: !this.state.addNewKeepShow })} />
                            </div>

                            <div className="verticalStackCenter addNewButtonDropdownHidden" style={{display: this.state.addNewKeepShow ? "block" : "", zIndex: 12}}> {/* I use addNewButtonDropdownHidden on another places but here I need to be even higher in zLevel */}

                                <div className="verticalStackCenter" style={{padding: 16}}>

                                    <div className="addNewButtonPeopleContainer">
                                        <div className="horizontalStackCenter">
                                            {/*<img className="addNewButtonPeopleExpandIcon" style={{marginLeft: 0, width: "8px", height: "8px", marginRight: 7, transform: this.state.addNewPeopleOpened ? "rotate(90deg)" : ""}} src={icon_expand}/>*/}
                                            <span className="fontPoppinsSemiBold15 addNewButtonPeopleText">{"People"}</span>
                                        </div>
                                        <div className="addNewButtonPeopleList">
                                            <div className="verticalStack">
                                                <Link className="link" to={"./NewContactPerson"} style={{marginTop: 5}} onClick={() => this.setState({ addNewKeepShow: false })}>
                                                    <span className="fontPoppinsRegular13Orange clickable onHoverToBlue">Person</span>
                                                </Link>
                                                <span className="fontPoppinsRegular13Orange clickable onHoverToBlue" style={{marginTop: 5}} onClick={() => {this.setState({ addNewKeepShow: false });this.openModal("AddConversation");}}>Conversation</span>
                                                <span className="fontPoppinsRegular13Orange clickable onHoverToBlue" style={{marginTop: 5}} onClick={() => {this.setState({ addNewKeepShow: false });this.openModal("AddRole");}}>Role</span>
                                                <span className="fontPoppinsRegular13Orange clickable onHoverToBlue" style={{marginTop: 5}} onClick={() => {this.setState({ addNewKeepShow: false });this.openModal("AddRelationship");}}>Relationship</span>
                                                {/*<span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange">Event</span>*/}
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{height: 5}}></div>

                                    <div className="addNewButtonChurchesContainer">
                                        <div className="horizontalStackCenter">
                                            {/*<img className="addNewButtonChurchesExpandIcon" style={{marginLeft: 0, width: "8px", height: "8px", marginRight: 7, transform: this.state.addNewChurchesOpened ? "rotate(90deg)" : ""}} src={icon_expand}/>*/}
                                            <span className="fontPoppinsSemiBold15 addNewButtonChurchesText">{"Churches"}</span>
                                        </div>
                                        <div className="addNewButtonChurchesList">
                                            <div className="verticalStack">
                                                <Link className="link" to={"./NewContactChurch"} style={{marginTop: 5}} onClick={() => this.setState({ addNewKeepShow: false })}>
                                                    <span className="fontPoppinsRegular13Orange clickable onHoverToBlue">Church</span>
                                                </Link>
                                                <span className="fontPoppinsRegular13Orange clickable onHoverToBlue" style={{marginTop: 5}} onClick={() => {this.setState({ addNewKeepShow: false });this.openModal("AddObservation");}}>Observation</span>
                                                <span className="fontPoppinsRegular13Orange clickable onHoverToBlue oneLineText" style={{marginTop: 5}} onClick={() => {this.setState({ addNewKeepShow: false });this.openModal("AddRoleInTeam");}}>Roles in Teams</span>
                                                <span className="fontPoppinsRegular13Orange clickable onHoverToBlue" style={{marginTop: 5}} onClick={() => {this.setState({ addNewKeepShow: false });this.openModal("AddStatistics");}}>Ministry Stats</span>
                                                {/*<span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange">Relationship</span>*/}
                                                {/*<span className="fontPoppinsRegular13DarkGray clickable onHoverToOrange">Event</span>*/}
                                            </div>
                                        </div>
                                    </div>

                                    {/*<div style={{height: 5}}></div>

                                    <div className="addNewButtonChurchesContainer">
                                        <div className="horizontalStackCenter">
                                            <span className="fontPoppinsSemiBold15 addNewButtonChurchesText">{"Schools"}</span>
                                        </div>
                                        <div className="addNewButtonChurchesList">
                                            <div className="verticalStack">
                                                <Link className="link" to={"./NewContactSchool"} style={{marginTop: 5}} onClick={() => this.setState({ addNewKeepShow: false })}>
                                                    <span className="fontPoppinsRegular13Orange clickable onHoverToBlue">School</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>*/}

                                </div>

                            </div>
                        </div>

                        <div style={{width: "2px", height: "100%", backgroundColor: "var(--lightest-gray)"}}/>

                        <div className="topMenuUserButtonDropdownContainer">

                            <div className="topMenuUserButtonDropdownVisible">
                                <div className="horizontalStackCenter topMenuUserButton clickable" style={{paddingRight: 20}}>
                                    <span className="fontPoppinsRegular13" style={{paddingRight: "12.5px", whiteSpace: "nowrap", paddingLeft: "24.5px"}}>{this.state.first_name} {this.state.last_name}</span>

                                    <ProfileImageSmall
                                        name={this.state.first_name+" "+this.state.last_name}
                                        uploadedImageBase64={this.state.uploadedImageBase64}
                                    />

                                </div>
                            </div>

                            <div className="topMenuUserButtonDropdownHidden">

                                <div className="flex horizontalStackCenter topMenuUserButtonDropdownRow" onClick={() => this.viewEditProfile()}>
                                    <span className="fontPoppinsRegular13DarkGray">{"View / Edit Profile"}</span>
                                    <div className="flex"></div>
                                </div>

                                <div className="topMenuUserButtonDropdownRow" onClick={() => this.openModal("ChangePassword")}>
                                    <span className="fontPoppinsRegular13DarkGray">{"Change Password"}</span>
                                    <div className="flex"></div>
                                </div>

                                <div className="topMenuUserButtonDropdownRow" style={{display: this.state.userIsAdmin == 1 ? "" : "none"}}>
                                    <Link className="link" to={"/Rights"} >
                                        <span className="fontPoppinsRegular13DarkGray">{"Admin section"}</span>
                                    </Link>
                                    <div className="flex"></div>
                                </div>

                                <div className="topMenuUserButtonDropdownRow">

                                    <div className="universalPopupSelectContainer" ref={this.refPopupChurchCategory}>

                                        <div className="universalPopupSelectVisible">
                                            {!this.state.showChangeCountryPopup ? <span className="fontPoppinsRegular13DarkGray" onClick={() => this.setState({ showChangeCountryPopup: !this.state.showChangeCountryPopup })}>{"> Change Country"}</span> : null }
                                            {this.state.showChangeCountryPopup ? <span className="fontPoppinsRegular13DarkGray" onClick={() => this.setState({ showChangeCountryPopup: !this.state.showChangeCountryPopup })}>{"< Change Country"}</span> : null }
                                            <div className="flex"></div>
                                        </div>

                                        <div className="universalPopupSelectHidden" style={{display: this.state.showChangeCountryPopup ? "" : "none", marginLeft: -174, marginTop: -40}}>
                                            <div className="verticalStack" style={{width: 120}}>

                                                {this.state.permCountryArray.map(function (item) {
                                                    return (
                                                        <div className="horizontalStack" style={{marginTop: 3, marginBottom: 3}} onClick={() => this.changeCrntCntry(item)}>
                                                            <label for={"kkk"} className="fontPoppinsRegular13DarkGray clickable flex onHoverToOrange" style={{marginRight: 5}}>{item.name}</label>
                                                        </div>
                                                    )
                                                }.bind(this))}

                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="topMenuUserButtonDropdownRow">
                                    <span className="fontPoppinsRegular13DarkGray" onClick={this.logoutUser}>{"Log Out"}</span>
                                    <div className="flex"></div>
                                </div>

                            </div>
                        </div>
                </div>
                <div className="advancedTopMenu verticalStack" style={{display: this.props.topMenuExpanded ? "" : "none"}}>
                    <span className="fontPoppinsSemiBold15">Advanced Search:</span>

                    <div className="horizontalStack" style={{backgroundColor: "var(--lightest-gray)", padding: 24, marginTop: 15}}>
                        <div className="verticalStack flex">
                            <span className="fontPoppinsRegular13">Name or Email:</span>
                            <input className="fontPoppinsRegular13" type="text" id="nameEmail" name="nameEmail" autoComplete="off" placeholder="" value={this.state.adv_nm} onChange={e => {this.setState({ adv_nm: e.target.value });}} onKeyUp={this.onEnterSearch} style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}}/>
                            <div style={{height: 10}}></div>
                            <span className="fontPoppinsRegular13">Type of Contact:</span>
                            <select className="profileTileInputText topMenuSelectBox" name="typeOfContact" id="typeOfContact" style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}} value={this.state.adv_tp} onChange={e => {this.setState({ adv_tp: e.target.value });}}>
                                <option value=""></option>
                                {this.state.contactTypeArray.map(function (item) {
                                    return ( <option value={item.id} selected={item.id == this.state.adv_tp ? "selected" : ""}>{item.name}</option> )
                                }.bind(this))}
                            </select>
                            <div style={{height: 10}}></div>
                            <span className="fontPoppinsRegular13">Denomination:</span>
                            <select className="profileTileInputText topMenuSelectBox" name="denomination" id="denomination" style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}} value={this.state.adv_deno} onChange={e => {this.setState({ adv_deno: e.target.value });}}>
                                <option value=""></option>
                                {this.state.denominationArray.map(function (item) {
                                    return ( <option value={item.id} selected={item.id == this.state.adv_deno ? "selected" : ""}>{item.name}</option> )
                                }.bind(this))}
                            </select>
                        </div>

                        <div style={{width: 50}}></div>

                        <div className="verticalStack flex">
                            <span className="fontPoppinsRegular13">City:</span>
                            <input className="fontPoppinsRegular13" type="text" id="city" name="city" autoComplete="off" placeholder="" value={this.state.adv_city} onChange={e => {this.setState({ adv_city: e.target.value });}} onKeyUp={this.onEnterSearch} style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}}/>
                            <div style={{height: 10}}></div>
                            <span className="fontPoppinsRegular13">Country:</span>
                            <select className="profileTileInputText topMenuSelectBox" name="country" id="country" style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}} value={this.state.adv_cntry} onChange={e => {this.setState({ adv_cntry: e.target.value });this.loadRegionsForCountry(e.target.value);}}>
                                <option value=""></option>
                                {this.state.countryArray.map(function (item) {
                                    return ( <option value={item.id} selected={item.id == this.state.adv_cntry ? "selected" : ""}>{item.name}</option> )
                                }.bind(this))}
                            </select>
                            <div style={{height: 10}}></div>
                            <span className="fontPoppinsRegular13">JV Region:</span>
                            <select className="profileTileInputText topMenuSelectBox" name="region" id="region" style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}} value={this.state.adv_reg} onChange={e => {this.setState({ adv_reg: e.target.value });}}>
                                <option value=""></option>
                                {this.state.regionArray.map(function (item) {
                                    return ( <option value={item.id} selected={item.id == this.state.adv_reg ? "selected" : ""}>{item.name}</option> )
                                }.bind(this))}
                            </select>
                        </div>

                        <div style={{width: 50}}></div>

                        <div className="verticalStack flex">
                            <span className="fontPoppinsRegular13">Role:</span>
                            <select className="profileTileInputText topMenuSelectBox" name="role" id="role" style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}} value={this.state.adv_role} onChange={e => {this.setState({ adv_role: e.target.value });}}>
                                <option value=""></option>
                                {this.state.roleTypeArray.map(function (item) {
                                    return ( <option value={item.id} selected={item.id == this.state.adv_role ? "selected" : ""}>{item.name}</option> )
                                }.bind(this))}
                            </select>
                            <div style={{height: 10}}></div>
                            <span className="fontPoppinsRegular13">Team:</span>
                            <select className="profileTileInputText topMenuSelectBox" name="team" id="team" style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}} value={this.state.adv_team} onChange={e => {this.setState({ adv_team: e.target.value });}}>
                                <option value=""></option>
                                {this.state.teamTypeArray.map(function (item) {
                                    return ( <option value={item.id} selected={item.id == this.state.adv_team ? "selected" : ""}>{item.name}</option> )
                                }.bind(this))}
                            </select>
                            <div style={{height: 10}}></div>
                            <span className="fontPoppinsRegular13">Relationship type:</span>
                            <select className="profileTileInputText topMenuSelectBox" name="relationshipType" id="relationshipType" style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}} value={this.state.adv_rel} onChange={e => this.saveRelationshipSelection(e.target.value) }>
                                <option value=""></option>
                                {this.state.relationshipTypeArray.map(function (item) {
                                    return ( <option value={item.id+"."+item.type} selected={this.isRelationshipSelected(item.id, item.type)}>{item.name}</option> )
                                }.bind(this))}
                            </select>
                        </div>

                        <div style={{width: 50}}></div>

                        <div className="verticalStack flex">
                            <div className="horizontalStack">
                                <div className="verticalStackCenter" onChange={event => this.setState({ adv_op: event.target.value })}>
                                    <span className="fontPoppinsRegular13">{"And"}</span>
                                    <input type="radio" id="and" name="adv_op" value={"and"} checked={this.state.adv_op == "and" ? true : false} style={{width: 15, height: 15}}/>
                                </div>
                                <div style={{width: 10}}></div>
                                <div className="verticalStackCenter" onChange={event => this.setState({ adv_op: event.target.value })}>
                                    <span className="fontPoppinsRegular13" style={{textAlign: "center"}}>{"Or"}</span>
                                    <input type="radio" id="or" name="adv_op" value={"or"} checked={this.state.adv_op == "or" ? true : false} style={{width: 15, height: 15}}/>
                                </div>
                            </div>
                            <span className="fontPoppinsRegular13Gray" style={{marginTop: 20}}>{"Using the “And” search option will give you results that include all of the features you’ve chose from these filters. The “Or” search option will give you results that include only one feature from your filters."}</span>
                        </div>
                    </div>

                    {/*
                        DIAGNOSTIC LENS TOOLS SECTION
                    */}

                    <div className="horizontalStackCenter clickable" style={{backgroundColor: "var(--lightest-gray)", padding: 24, paddingTop: 0, marginTop: 0}} onClick={() => this.setState({ diagnosticToolsIsOpen: !this.state.diagnosticToolsIsOpen })}>
                        <span className="fontPoppinsSemiBold13" style={{}}>Diagnostic Lens Tools:</span>
                        <img className={this.state.diagnosticToolsIsOpen ? "expandButtonExpanded" : "expandButton"} style={{width: "10px", height: "10px", marginLeft: 10}} src={icon_expand}/>
                    </div>

                    <div className="horizontalStack" style={{display: this.state.diagnosticToolsIsOpen ? "" : "none", backgroundColor: "var(--lightest-gray)", padding: 24, paddingTop: 0, marginTop: 0}}>

                        <div className="verticalStack flex">
                            <span className="fontPoppinsRegular13">Five Challenges of Christ:</span>
                            <select className="profileTileInputText topMenuSelectBox" name="fiveChallengesType" id="fiveChallengesType" style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}} value={this.state.adv_dsc} onChange={e => {this.setState({ adv_dsc: e.target.value });}}>
                                <option value=""></option>
                                {this.state.fiveChallengesArray.map(function (item) {
                                    return ( <option value={item.id} selected={item.id == this.state.adv_dsc ? "selected" : ""}>{item.name}</option> )
                                }.bind(this))}
                            </select>
                        </div>

                        <div style={{width: 50}}></div>

                        <div className="verticalStack flex">
                            <span className="fontPoppinsRegular13">Leadership Type:</span>
                            <select className="profileTileInputText topMenuSelectBox" name="leadershipType" id="leadershipType" style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}} value={this.state.adv_ldr} onChange={e => {this.setState({ adv_ldr: e.target.value });}}>
                                <option value=""></option>
                                {this.state.leadershipTypeArray.map(function (item) {
                                    return ( <option value={item.id} selected={item.id == this.state.adv_ldr ? "selected" : ""}>{item.name}</option> )
                                }.bind(this))}
                            </select>
                        </div>

                        <div style={{width: 50}}></div>

                        <div className="verticalStack flex">
                            <span className="fontPoppinsRegular13">Ministry Phases:</span>
                            <select className="profileTileInputText topMenuSelectBox" name="ministryPhasesType" id="ministryPhasesType" style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}} value={this.state.adv_mph} onChange={e => {this.setState({ adv_mph: e.target.value });}}>
                                <option value=""></option>
                                {this.state.ministryPhasesArray.map(function (item) {
                                    return ( <option value={item.id} selected={item.id == this.state.adv_mph ? "selected" : ""}>{item.name}</option> )
                                }.bind(this))}
                            </select>
                        </div>

                        <div style={{width: 50}}></div>

                        <div className="verticalStack flex">
                            <span className="fontPoppinsRegular13">JV Engagement:</span>
                            <select className="profileTileInputText topMenuSelectBox" name="jvEngagementType" id="jvEngagementType" style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}} value={this.state.adv_jve} onChange={e => {this.setState({ adv_jve: e.target.value });}}>
                                <option value=""></option>
                                {this.state.jvEngagementArray.map(function (item) {
                                    return ( <option value={item.id} selected={item.id == this.state.adv_jve ? "selected" : ""}>{item.name}</option> )
                                }.bind(this))}
                            </select>
                        </div>
                    </div>

                    {/*
                        EVENTS SECTION
                    */}

                    <div className="horizontalStackCenter clickable" style={{backgroundColor: "var(--lightest-gray)", padding: 24, paddingTop: 0, marginTop: 0}} onClick={() => this.setState({ eventsIsOpen: !this.state.eventsIsOpen })}>
                        <span className="fontPoppinsSemiBold13" style={{}}>Events:</span>
                        <img className={this.state.eventsIsOpen ? "expandButtonExpanded" : "expandButton"} style={{width: "10px", height: "10px", marginLeft: 10}} src={icon_expand}/>
                    </div>

                    <div className="horizontalStack" style={{display: this.state.eventsIsOpen ? "" : "none", backgroundColor: "var(--lightest-gray)", padding: 24, paddingTop: 0, marginTop: 0}}>

                        <div className="verticalStack flex">
                            <span className="fontPoppinsRegular13">Event Name:</span>
                            <input className="fontPoppinsRegular13" type="text" id="eventName" name="eventName" autoComplete="off" placeholder="" value={this.state.adv_evnt_nm} onChange={e => {this.setState({ adv_evnt_nm: e.target.value });}} onKeyUp={this.onEnterSearch} style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}}/>

                            <div style={{height: 10}}></div>

                            <span className="fontPoppinsRegular13">Area:</span>
                            <select className="profileTileInputText topMenuSelectBox" name="eventArea" id="eventArea" style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}} value={this.state.adv_area} onChange={e => {this.setState({ adv_area: e.target.value });}}>
                                <option value=""></option>
                                {this.state.areaTypeArray.map(function (item) {
                                    return ( <option value={item.id} selected={item.id == this.state.adv_area ? "selected" : ""}>{item.name}</option> )
                                }.bind(this))}
                            </select>

                        </div>

                        <div style={{width: 50}}></div>

                        <div className="verticalStack flex">
                            <span className="fontPoppinsRegular13">National Organization:</span>
                            <select className="profileTileInputText topMenuSelectBox" name="eventOrg" id="eventOrg" style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}} value={this.state.adv_nt_org} onChange={e => {this.setState({ adv_nt_org: e.target.value });}}>
                                <option value=""></option>
                                {this.state.nationalOrganizationsArray.map(function (item) {
                                    return ( <option value={item.id} selected={item.id == this.state.adv_nt_org ? "selected" : ""}>{item.name}</option> )
                                }.bind(this))}
                            </select>

                            <div style={{height: 10}}></div>

                            <span className="fontPoppinsRegular13">Ministry:</span>
                            <select className="profileTileInputText topMenuSelectBox" name="eventMinistry" id="eventMinistry" style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}} value={this.state.adv_mnry} onChange={e => {this.setState({ adv_mnry: e.target.value });}}>
                                <option value=""></option>
                                {this.state.ministryTypeArray.map(function (item) {
                                    return ( <option value={item.id} selected={item.id == this.state.adv_mnry ? "selected" : ""}>{item.name}</option> )
                                }.bind(this))}
                            </select>
                        </div>

                        <div style={{width: 50}}></div>

                        <div className="verticalStack flex">
                            <span className="fontPoppinsRegular13">After Date:</span>
                            <input className="fontPoppinsRegular13" type="date" id="eventAFT" name="eventAFT" autoComplete="off" min="1900-01-01" max="9999-01-01" value={this.state.adv_evnt_aft} onChange={e => {this.setState({ adv_evnt_aft: e.target.value });}} style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}}/>

                            <div style={{height: 10}}></div>

                            <span className="fontPoppinsRegular13">Event Type:</span>
                            <select className="profileTileInputText topMenuSelectBox" name="eventType" id="eventType" style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}} value={this.state.adv_evnt_tp} onChange={e => {this.setState({ adv_evnt_tp: e.target.value });}}>
                                <option value=""></option>
                                {this.state.eventTypeArray.map(function (item) {
                                    return ( <option value={item.id} selected={item.id == this.state.adv_evnt_tp ? "selected" : ""}>{item.name}</option> )
                                }.bind(this))}
                            </select>
                        </div>

                        <div style={{width: 50}}></div>

                        <div className="verticalStack flex">
                            <span className="fontPoppinsRegular13">Before Date:</span>
                            <input className="fontPoppinsRegular13" type="date" id="eventBEF" name="eventBEF" autoComplete="off" min="1900-01-01" max="9999-01-01" value={this.state.adv_evnt_bef} onChange={e => {this.setState({ adv_evnt_bef: e.target.value });}} style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}}/>

                            <div style={{height: 10}}></div>

                            <span className="fontPoppinsRegular13">Event Sub-type:</span>
                            <select className="profileTileInputText topMenuSelectBox" name="eventSubType" id="eventSubType" style={{width: "100%", marginTop: 5, marginBottom: 5, border: "0px solid black"}} value={this.state.adv_evnt_subtp} onChange={e => {this.setState({ adv_evnt_subtp: e.target.value });}}>
                                <option value=""></option>
                                {this.state.eventSubTypeArray.map(function (item) {
                                    return ( <option value={item.id} selected={item.id == this.state.adv_evnt_subtp ? "selected" : ""}>{item.name}</option> )
                                }.bind(this))}
                            </select>
                        </div>
                    </div>

                    <div className="horizontalStack" style={{marginTop: "20px"}}>

                        <Link className="link" to={"/AdvancedSearch?adv_op="+this.state.adv_op+"&adv_nm="+(this.state.adv_nm == undefined ? "" : this.state.adv_nm)+"&adv_city="+(this.state.adv_city == undefined ? "" : this.state.adv_city)+"&adv_tp="+this.state.adv_tp+"&adv_deno="+this.state.adv_deno+"&adv_cntry="+this.state.adv_cntry+"&adv_reg="+this.state.adv_reg+"&adv_role="+this.state.adv_role+"&adv_team="+this.state.adv_team+"&adv_dsc="+this.state.adv_dsc+"&adv_ldr="+this.state.adv_ldr+"&adv_mph="+this.state.adv_mph+"&adv_jve="+this.state.adv_jve+"&adv_rel_a="+this.state.adv_rel_a+"&adv_rel_b="+this.state.adv_rel_b+"&way=1&diagnosticToolsIsOpen="+(this.state.diagnosticToolsIsOpen ? "1" : "0")+"&eventsIsOpen="+(this.state.eventsIsOpen ? "1" : "0")+"&adv_evnt_nm="+(this.state.adv_evnt_nm == undefined ? "" : this.state.adv_evnt_nm)+"&adv_area="+this.state.adv_area+"&adv_nt_org="+this.state.adv_nt_org+"&adv_mnry="+this.state.adv_mnry+"&adv_evnt_tp="+this.state.adv_evnt_tp+"&adv_evnt_subtp="+this.state.adv_evnt_subtp+"&adv_evnt_bef="+(this.state.adv_evnt_bef == undefined ? "" : this.state.adv_evnt_bef)+"&adv_evnt_aft="+(this.state.adv_evnt_aft == undefined ? "" : this.state.adv_evnt_aft)} >
                            <RoundButton title={"Search"} className={"roundButtonOrange"} style={{marginRight: "10px"}} whiteText={true} onClick={this.actionSearch} />
                        </Link>
                        <RoundButton title={"Clear All"} className={"roundButtonGray"} style={{marginRight: "10px"}} whiteText={true} onClick={this.actionClearAll} />
                        <RoundButton title={"Cancel"} className={"roundButtonGray"} style={{marginRight: "10px"}} whiteText={true} onClick={this.actionCancel} />
                        <div className="flex"></div>
                    </div>

                </div>

                {this.state.prepareToShowModalAddConversation ? <ConversationsModal showModal={this.state.showModalAddConversation} closeModal={this.closeModal} openModal={this.openModal} contactId={-1} contactName={""} actionType={"AddConversation"} conversationId={-1} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }
                {this.state.prepareToShowModalAddRole ? <RolesModal showModal={this.state.showModalAddRole} closeModal={this.closeModal} openModal={this.openModal} contactId={-1} contactName={""} actionType={"AddRole"} roleId={-1} openAlertMessage={this.props.openAlertMessage} /> : null }
                {this.state.prepareToShowModalAddRelationship ? <RelationshipsModal showModal={this.state.showModalAddRelationship} closeModal={this.closeModal} openModal={this.openModal} contactId={-1} contactName={""} actionType={"AddRelationship"} relationshipId={-1} openAlertMessage={this.props.openAlertMessage} /> : null }
                {this.state.prepareToShowModalAddObservation ? <ObservationsModal showModal={this.state.showModalAddObservation} closeModal={this.closeModal} openModal={this.openModal} contactId={-1} contactName={""} actionType={"AddObservation"} observationId={-1} openAlertMessage={this.props.openAlertMessage} /> : null }
                {this.state.prepareToShowModalAddRoleInTeam ? <RolesInTeamsModal showModal={this.state.showModalAddRoleInTeam} closeModal={this.closeModal} openModal={this.openModal} contactId={-1} contactName={""} actionType={"AddRoleInTeam"} roleId={-1} openAlertMessage={this.props.openAlertMessage} /> : null }
                {this.state.prepareToShowModalAddStatistics ? <MinistryStatisticsModal showModal={this.state.showModalAddStatistics} closeModal={this.closeModal} openModal={this.openModal} contactId={-1} contactName={""} actionType={"AddStatistics"} statisticsId={-1} openAlertMessage={this.props.openAlertMessage} /> : null }
                {this.state.prepareToShowModalChangePassword ? <ChangePasswordModal showModal={this.state.showModalChangePassword} closeModal={this.closeModal} openModal={this.openModal} actionType={"ChangePassword"} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} /> : null }

            </div>
        );
	}
}

export default TopMenu;