import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import './fonts/Poppins/Poppins-SemiBold.ttf';
import Axios from 'axios';
//Axios.defaults.baseURL = 'https://rdev.josiahventure.com/';
Axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
try {
    var token_object = JSON.parse(localStorage.getItem("token"));

    var crnt_cntry = localStorage.getItem("crnt_cntry");
    if(crnt_cntry && crnt_cntry != ""){
        Axios.defaults.headers.common = {
            'Authorization': `${token_object.token}`,
            'crnt_cntry': `${crnt_cntry}`
        }
    }
    else {
        Axios.defaults.headers.common = {
            'Authorization': `${token_object.token}`
        }
    }
}
catch(error){
    // I was not able to load token
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
