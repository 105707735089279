import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import { useLocation, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';
import Resizer from "react-image-file-resizer";
import Moment from 'react-moment';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';
import { getInitials } from '../utilities/OtherUtilities.js';

// import assets
import { Link } from 'react-router-dom';
import church_avatar from '../assets/church_avatar.png';
import background from '../assets/background.jpg';
import icon_expand from '../assets/icon_expand.png';
import icon_add_white from '../assets/icon_add_white.png';
import icon_done_white from '../assets/icon_done_white.png';
import icon_checkmark_orange from '../assets/icon_checkmark_orange.png';

// import components
import RoundButton from '../components/RoundButton.js';
import PopupButtonDescription from '../components/PopupButtonDescription.js';
import DeleteDialog from '../components/DeleteDialog.js';
import ExpandButton from '../components/ExpandButton.js';
import ProfileImage from '../components/ProfileImage.js';

class ProfileEventTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            mainContactId: 0,
            updateExisting: true, // if I am in create new contact mode it would be false
            isMyContact: false, // default should be false
            toolOpened: true, // on this page we want it to be opened by default
            showDeleteDialog: false,
            /*actualProfile: ([{id:1, area: "Evangelism", mnry: "Other", tp: "Camp", subtp: "Camp with JV Theme", nt_org: "Albania - JetaPlus", sch_yr: "2021-2022", evnt_nm: "Name of event", from_dt: "2022-08-01", till_dt: "2022-08-05", location: "XNA", lat: null, lng: null, coord_rcg_nm: "Milan Sturz", reg_url: "registration-link.com", reg_cnt: 86, attn_cnt: 69, blv_cnt: 18, unb_cnt: 43, pof_cnt: 30, src_id: "11717"}]),*/
            actualProfile: ([{id:1, area: "", mnry: "", tp: "", subtp: "", nt_org: "", sch_yr: "", evnt_nm: "", from_dt: "", till_dt: "", location: "", lat: null, lng: null, coord_rcg_nm: "", reg_url: "", reg_cnt: 0, attn_cnt: 0, blv_cnt: 0, unb_cnt: 0, pof_cnt: 0, src_id: ""}]),
            uploadedImageBase64: "",
        }

        this.hiddenFileInput = React.createRef();
    }

    async componentDidMount(){
        //const queryParams = new URLSearchParams(window.location.search);
        //const id = queryParams.get('id');
        this.loadData();
    }

    loadData = async () => {
        this.setState({ uploadedImageBase64: "" });

        const pathname = this.props.location.pathname;
        var id = pathname.substring(pathname.lastIndexOf("/")+1);
        if(id.includes("?")){ id = id.substring(0, id.indexOf("?")); }

        if(Number.isInteger(Number(id))){ // if there is number received from URL
            if(Number(id) == 0){
                if(window.history.length > 1) navigate(-1);
                else { navigate("/Events"); window.location.reload(); }
                return;
            }
            await Axios.get('/api/evnt/get/'+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.result.length == 0){
                    this.props.openAlertMessage("You don't have rights to this contact.","OK");
                    navigate(-1);
                }
                else {
                    this.setState({ actualProfile: response.data.result });
                    this.setState({ isMyContact: response.data.result[0].my_evnt == 0 ? false : true });
                }
            });
            this.setState({ updateExisting: true });
            this.setState({ mainContactId: id });

            // get avatar image of contact
            Axios.get('/api/file/load?file_prefix=evnt&cont_id='+id).then(response => {
                //if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                if(response.data.stat && response.data.stat == "ERR"){
                    // avatar image was not loaded
                }
                else this.setState({ uploadedImageBase64: response.data });
                //alert(JSON.stringify(response.data))
            });
        }
        else {
            // not editing but creating new contact
            this.setState({ updateExisting: false });
            this.setState({ toolOpened: true });
        }

    }

    openCloseTool = () => {
        if(this.state.toolOpened){
            this.setState({ toolOpened: false });
            this.props.onCloseOrOpen("");
        }
        else {
            this.setState({ toolOpened: true });
            this.props.onCloseOrOpen("profiledata");
        }
    }

    addToMyContacts = () => {
        if(this.state.isMyContact){
            // delete
            Axios.delete('/api/my_evnt/del/', {
                data: {EvntId: this.state.mainContactId},
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ isMyContact: false });
            });
        }
        else {
            // add
            Axios.post('/api/my_evnt/ins/', {
                EvntId: this.state.mainContactId,
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
                this.setState({ isMyContact: true });
            });
        }
    }

    /* START OF METHODS FOR AVATAR IMAGE */

    toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    uploadImageClick = () => { this.hiddenFileInput.current.click(); };

    deleteProfileImage = () => {
        Axios.post('/api/file/delete', {
            FilePrefix: "evnt",
            ContId: this.state.mainContactId,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            this.setState({ uploadedImageBase64: "" });
        });
    }

    imageIsSelected = async (event) => {
        const fileUploaded = event.target.files[0];
        if(fileUploaded.size > 5000000){
            this.props.openAlertMessage("Error! Maximum size of picture is 5 MB.","OK");
            return;
        }
        var base64 = await this.toBase64(fileUploaded);
        var prefix = fileUploaded.name.substring(fileUploaded.name.lastIndexOf(".")+1);
        //this.setState({ uploadedImageBase64: base64 });

        try {
            Resizer.imageFileResizer(fileUploaded, 300, 300, "JPEG", 100, 0,
                (uri) => {
                    //console.log(uri);
                    this.setState({ uploadedImageBase64: uri });
                    if(this.state.updateExisting){
                        this.uploadAvatarImage(this.state.mainContactId);
                    }
                },
                "base64", 200,  200
            );
        }
        catch (err) {
            //console.log(err);
            this.props.openAlertMessage("Error while uploading image.","OK");
        }
        //var improvedBase64 = base64.substring(base64.lastIndexOf(";base64,")+8);
    };

    uploadAvatarImage = (contactId) => {
        if(this.state.uploadedImageBase64 == "")return;
        Axios.post('/api/file/upload', {
            FilePrefix: "evnt",
            ContId: contactId,
            content: this.state.uploadedImageBase64,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessage) == 1){ return; }
            //alert("Avatar image was uploaded.");
        });
    }

    openLink = (link) => {
        if(link && link.length > 0){
            link = link.match(/^https?:/) ? link : '//' + link;
            window.open(link, "_blank", "noreferrer");
        }
    }

    openEditingLink = () => {
        // sys_id: 1 - CRM, 2 - OTM, 3 - RPM, 4 - ETM, 5 - FUM, 6 - ISM
        var sys_id = this.state.actualProfile[0].sys_id;
        if(sys_id == 2){
            //alert("OTM");
            this.openLink("https://www.emsreg.eu/otm/events/"+this.state.actualProfile[0].src_id+"/dashboard");
        }
        else if(sys_id == 3){
            //alert("RPM");
            this.openLink("https://www.emsreg.eu/rpm/events/"+this.state.actualProfile[0].src_id);
        }
        else if(sys_id == 4){
            //alert("ETM");
            this.openLink("https://www.emsreg.eu/etm/tour_stops/"+this.state.actualProfile[0].src_super_id+"/dashboard");
        }
        else {
            //alert("Error");
            this.openLink("https://www.emsreg.eu/otm/events/"+this.state.actualProfile[0].src_id+"/dashboard");
        }
    }

    openRegistrationLink = () => {
        // sys_id: 1 - CRM, 2 - OTM, 3 - RPM, 4 - ETM, 5 - FUM, 6 - ISM
        //alert("ahoj: "+this.state.actualProfile[0].reg_url);
        var sys_id = this.state.actualProfile[0].sys_id;
        if(sys_id == 2){
            //alert("OTM");
            this.openLink("https://www.emsreg.eu/public_otm/events/"+this.state.actualProfile[0].src_id+"/registrations/landing_page");
        }
        else {
            // for other types of events it is not possible to open registration page
            this.props.openAlertMessage("This event doesn't have registration page.","OK");
        }
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

        return (
            <div className="profileTile">
                <div className="flex" style={{flex: 1, height: 203, position: "relative"}}>

                    <div style={{width: "calc(100% + 40px)", backgroundColor: "var(--full-black)", height: "calc(50% + 20px)", position: "absolute", zIndex: 0, marginLeft: "-20px", marginTop: "-20px", borderRadius: "20px 20px 0px 0px"}}>
                        <img style={{width: "100%", height: "100%", borderRadius: "25px 25px 0px 0px", objectFit: "cover", opacity: 1}} src={background}/>
                    </div>

                    <div className="horizontalStack" style={{height: "100%"}}>

                        <div className="verticalStack" style={{zIndex: "1"}}>

                            { this.state.isMyContact == false ? <div className="horizontalStackCenter universalPopupContainer">
                                <div className="universalPopupVisible">
                                    <div className="circleButtonContainer circleButtonContainerGray clickable" onClick={this.addToMyContacts}>
                                        <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_add_white}/>
                                    </div>
                                </div>

                                <div className="universalPopupHidden">
                                    <PopupButtonDescription title={"Add to My Events & Trainings"} blackArrow={false} />
                                </div>
                            </div> :
                            <div className="horizontalStackCenter universalPopupContainer">
                                <div className="universalPopupVisible">
                                    <div className="circleButtonContainer circleButtonContainerGray clickable" onClick={this.addToMyContacts}>
                                        <img className="circleButton" style={{width: "16px", height: "16px", opacity: "0.9"}} src={icon_done_white}/>
                                    </div>
                                </div>

                                <div className="universalPopupHidden">
                                    <PopupButtonDescription title={"Remove from My Contacts"} blackArrow={false} />
                                </div>
                            </div> }

                            <div className="flex"></div>

                            <div className="horizontalStackCenter universalPopupContainer">
                                <div className="universalPopupVisible">
                                    <ExpandButton isOpen={this.state.toolOpened} style={{marginTop: 5}} onClick={this.openCloseTool} />
                                </div>

                                <div className="universalPopupHidden">
                                    <PopupButtonDescription title={"View More Event Details"} blackArrow={true} />
                                </div>
                            </div>

                        </div>

                        <div className="verticalStack" style={{zIndex: "0", marginLeft: "-10px", marginRight: "23px"}}>
                            <div className="flex"></div>

                            <ProfileImage
                                initials={getInitials(this.state.actualProfile[0].evnt_nm)}
                                uploadedImageBase64={this.state.uploadedImageBase64}
                                toolOpened={this.state.toolOpened}
                                uploadImageClick={this.uploadImageClick}
                                deleteImageClick={this.deleteProfileImage}
                                contactType={"church"}
                            />

                            <input type="file"
                                ref={this.hiddenFileInput}
                                style={{display:'none'}}
                                accept={"image/png, image/jpg, image/jpeg"}
                                onChange={(event) => this.imageIsSelected(event)}
                            />

                            <div className="flex"></div>
                        </div>

                        <div className="verticalStack flex" style={{zIndex: "0", height: "calc(100% + 20px)"}}>
                            <div className="verticalStackCenter flex" style={{height: "100%", marginTop: "-20px"}}>
                                <div className="horizontalStack" style={{height: "100%"}}>
                                    <div className="verticalStackCenter" style={{flex: 3, minWidth: 50, height: "100%"}}>
                                        <div className="flex"></div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsMedium40White responsiveThreeDots"><b>{this.state.actualProfile[0].evnt_nm}</b></span>
                                        </div>
                                        <div className="horizontalStackCenter" style={{display: this.state.actualProfile[0].has_ygrp == 1 ? "" : "none", position: "absolute", marginTop: 60}}>
                                            <img style={{width: 22, borderRadius: "25px 25px 0px 0px", objectFit: "cover", opacity: 1}} src={icon_checkmark_orange}/>
                                            <div style={{width: 5}}></div>
                                            <span className="fontPoppinsRegular13Orange responsiveThreeDots">{"Active Youth Ministry"}</span>
                                        </div>

                                        <div className="flex"></div>
                                    </div>
                                    <div style={{width: 20}}></div>
                                    <div className="verticalStackCenter" style={{flex: 2.5, minWidth: 50}}>

                                        {this.state.actualProfile[0].sys_id == 2 ? <span className="fontPoppinsSemiBold15Orange onHoverTextToWhite responsiveThreeDots clickable" onClick={() => this.openRegistrationLink()}>{"EMS Registration Link"}</span> : null }
                                        {/*<span className="fontPoppinsRegular13 responsiveThreeDots clickable" style={{color: "var(--white)"}} onClick={() => this.openLink("")}>{"facebook.com/event_name"}</span>*/}
                                        {/*<span className="fontPoppinsRegular13 responsiveThreeDots clickable" style={{color: "var(--white)"}} onClick={() => this.openLink("")}>{"instagram.com/event_name"}</span>*/}
                                        {/*<span className="fontPoppinsRegular13 responsiveThreeDots clickable" style={{color: "var(--white)"}} onClick={() => this.openLink("")}>{"emsreg.eu/fusiondary2022"}</span>*/}
                                        <span className="fontPoppinsRegular13 responsiveThreeDots clickable" style={{color: "var(--white)"}} onClick={() => this.openLink("https://calendar.google.com/calendar/r/eventedit?text="+this.state.actualProfile[0].evnt_nm+"&dates="+this.state.actualProfile[0].from_dt.replaceAll("-","").replaceAll(":","").replaceAll(".","")+"/"+this.state.actualProfile[0].till_dt.replaceAll("-","").replaceAll(":","").replaceAll(".","")+"&location="+this.state.actualProfile[0].location+"&sf=true&output=xml")}>{"Add Event to Google Calendar"}</span>

                                    </div>
                                </div>
                            </div>

                            <div className="verticalStackCenter flex">
                                <div className="horizontalStack" style={{height: "100%"}}>

                                    <div className="verticalStackCenter" style={{flex: 1, minWidth: 50}}>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].location == null || this.state.actualProfile[0].location == "" ? "Location" : "Location: "+this.state.actualProfile[0].location}</span>
                                        </div>
                                        <div style={{height:18}}></div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].from_dt == null || this.state.actualProfile[0].from_dt == "" ? "When" : "When: "}<Moment format="MMM D">{this.state.actualProfile[0].from_dt}</Moment>{"-"}<Moment format="MMM D, YYYY">{this.state.actualProfile[0].till_dt}</Moment></span>
                                        </div>
                                    </div>

                                    <div style={{width: 20}}></div>

                                    <div className="verticalStackCenter" style={{flex: 1, minWidth: 50}}>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].area == null || this.state.actualProfile[0].area == "" ? "Area" : "Area: "+this.state.actualProfile[0].area}</span>
                                        </div>
                                        <div style={{height:18}}></div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].id == null || this.state.actualProfile[0].id == "" ? "Source ID" : "Source ID: "+this.state.actualProfile[0].src_id}</span>
                                        </div>
                                    </div>

                                    <div style={{width: 20}}></div>

                                    <div className="verticalStackCenter" style={{flex: 1, minWidth: 50}}>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].attn_cnt == null || this.state.actualProfile[0].attn_cnt == "" ? "Attendees" : "Attendees: "+this.state.actualProfile[0].attn_cnt}</span>
                                        </div>
                                        <div style={{height:18}}></div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].mnry == null || this.state.actualProfile[0].mnry == "" ? "Ministry" : "Ministry: "+this.state.actualProfile[0].mnry}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* Editing data part */}

                <div className="flex" style={{flex: 1, position: "relative", display: this.state.toolOpened ? "inline" : "none"}}>

                    <div className="horizontalStack">

                        <div className="verticalStack" style={{zIndex: "0", marginTop: "55px", marginRight: "23px"}}>
                            <RoundButton title={"View/Edit All in EMS"} className={"roundButtonOrange"} style={{color: "white"}} onClick={() => this.openEditingLink()} whiteText={true} />
                        </div>

                        <div className="verticalStack" style={{width: 30, height: 100}}>
                        </div>

                        <div className="verticalStack flex" style={{zIndex: "0", height: "calc(100% + 20px)"}}>

                            <div style={{width: "100%", height: 1, backgroundColor: "var(--border-light-gray)", marginTop: 10, marginBottom: 25}}></div>

                            <div className="verticalStackCenter flex">
                                <div className="horizontalStack" style={{height: "100%"}}>

                                    <div className="verticalStackCenter" style={{flex: 1, minWidth: 50}}>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].tp == null || this.state.actualProfile[0].tp == "" ? "Event Type" : "Event Type: "+this.state.actualProfile[0].tp}</span>
                                        </div>
                                        <div style={{height:18}}></div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].subtp == null || this.state.actualProfile[0].subtp == "" ? "Event Type" : "Event Sub-Type: "+this.state.actualProfile[0].subtp}</span>
                                        </div>
                                    </div>

                                    <div style={{width: 20}}></div>

                                    <div className="verticalStackCenter" style={{flex: 1, minWidth: 50}}>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].blv_cnt == null || this.state.actualProfile[0].blv_cnt == "" ? "Believers" : "Believers: "+this.state.actualProfile[0].blv_cnt}</span>
                                        </div>
                                        <div style={{height:18}}></div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].unb_cnt == null || this.state.actualProfile[0].unb_cnt == "" ? "Unbelievers" : "Unbelievers: "+this.state.actualProfile[0].unb_cnt}</span>
                                        </div>
                                    </div>

                                    <div style={{width: 20}}></div>

                                    <div className="verticalStackCenter" style={{flex: 1, minWidth: 50}}>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].pof_cnt == null || this.state.actualProfile[0].pof_cnt == "" ? "Professions of Faith" : "Professions of Faith: "+this.state.actualProfile[0].pof_cnt}</span>
                                        </div>
                                        <div style={{height:18}}></div>
                                        <div className="responsiveThreeDotsContainer">
                                            <span className="fontPoppinsRegular13Gray responsiveThreeDots">{this.state.actualProfile[0].id == null || this.state.actualProfile[0].id == "" ? "Event ID Number" : "Event ID Number: "+this.state.actualProfile[0].id}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        );
    }
}

const withLocation = Component => props => {
    const location = useLocation();

    return <Component {...props} location={location} />;
};

export default withLocation(ProfileEventTile);
